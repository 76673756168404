import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Dialog,
  DialogTitle,
  DialogContent,
  Slide,
  TextField,
  DialogActions,
} from "@mui/material";
import firebase from "./../firebase/firebase";
import swal from "sweetalert";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SubscriptionPlan = () => {
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState(0);
  const [plans, setPlans] = useState([]);
  const [planId, setPlanId] = useState("");
  const [values, setValues] = useState({
    credits: 0,
    subscriptionTime: 0,
    price: 0,
    percentagePerLoad: 0,
    numberOfDrivers: 0,
  });
  const handleChange = (e) => {
    setValues((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value,
    }));
  };
  const getData = () => {
    const ref = firebase
      .firestore()
      .collection("subscriptionPlans")
      .orderBy("credits", "asc");
    ref.onSnapshot((snapshot) => {
      setPlans([]);
      snapshot.forEach((ele) => {
        setPlans((prevPlans) => [...prevPlans, ele.data()]);
      });
    });
  };

  const editPlan = (id, index) => {
    setOpen(true);
    setMode(1);
    setPlanId(id);
    setValues({
      credits: plans[index].credits,
      subscriptionTime: plans[index].subscriptionTime,
      price: plans[index].price,
      percentagePerLoad: plans[index].percentagePerLoad,
      numberOfDrivers: plans[index].numberOfDrivers,
    });
  };

  const deletePlan = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this subscription plan!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        firebase
          .firestore()
          .collection("subscriptionPlans")
          .doc(id)
          .delete()
          .then(() => {
            swal("Poof! Your subscription plan has been deleted!", {
              icon: "success",
            });
          })
          .catch((err) => {
            swal(err.message);
          });
      }
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const addPlan = () => {
    if (values.credits <= 0) {
      swal("Enter credits");
      return;
    }
    if (values.subscriptionTime <= 0) {
      swal("Enter subscription time");
      return;
    }
    if (values.price <= 0) {
      swal("Enter price");
      return;
    }
    if (values.percentagePerLoad <= 0) {
      swal("Enter percentage per load");
      return;
    }
    if (values.numberOfDrivers <= 0) {
      swal("Enter number of drivers");
      return;
    }

    const key = firebase.firestore().collection("subscriptionPlans").doc().id;
    firebase
      .firestore()
      .collection("subscriptionPlans")
      .doc(key)
      .set({
        id: key,
        credits: values.credits,
        subscriptionTime: values.subscriptionTime,
        price: values.price,
        percentagePerLoad: values.percentagePerLoad,
        numberOfDrivers: values.numberOfDrivers,
      })
      .then(() => {
        setOpen(false);
        swal("Subscription plan added successfully");
      })
      .catch((err) => {
        swal(err.message);
      });
  };

  const updatePlan = () => {
    if (values.credits <= 0) {
      swal("Enter credits");
      return;
    }

    if (values.subscriptionTime <= 0) {
      swal("Enter subscription time");
      return;
    }
    if (values.price <= 0) {
      swal("Enter price");
      return;
    }
    if (values.percentagePerLoad <= 0) {
      swal("Enter percentage per load");
      return;
    }
    if (values.numberOfDrivers <= 0) {
      swal("Enter number of drivers");
      return;
    }

    firebase
      .firestore()
      .collection("subscriptionPlans")
      .doc(planId)
      .update({
        credits: values.credits,
        subscriptionTime: values.subscriptionTime,
        price: values.price,
        percentagePerLoad: values.percentagePerLoad,
        numberOfDrivers: values.numberOfDrivers,
      })
      .then(() => {
        setOpen(false);
        swal("Subscription plan updated successfully");
      })
      .catch((err) => {
        swal(err.message);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Container>
      <Box sx={{ textAlign: "right" }}>
        <Button
          variant="contained"
          onClick={() => {
            setOpen(true);
            setMode(0);
          }}
        >
          Add Subscription Plan
        </Button>
        <TableContainer component={Paper} sx={{ marginTop: "20px" }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell align="right">Subscription Time</TableCell>
                <TableCell align="right">Percentage Per Load</TableCell>
                <TableCell align="right">Number Of Drivers</TableCell>
                <TableCell align="right">Credits</TableCell>

                <TableCell align="right">Price</TableCell>

                <TableCell align="right">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {plans.map((plan, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {index + 1}
                  </TableCell>
                  <TableCell align="right">
                    {plan.subscriptionTime} days
                  </TableCell>
                  <TableCell align="right">
                    {plan.percentagePerLoad} %
                  </TableCell>
                  <TableCell align="right">{plan.numberOfDrivers}</TableCell>
                  <TableCell align="right">{plan.credits}</TableCell>
                  <TableCell align="right">${plan.price}</TableCell>
                  <TableCell align="right">
                    <Button
                      variant="contained"
                      onClick={() => editPlan(`${plan.id}`, index)}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      sx={{ marginLeft: "10px" }}
                      onClick={() => deletePlan(`${plan.id}`)}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          {mode === 0 ? "Add" : "Update"} Subscription Plan
        </DialogTitle>
        <DialogContent>
          <TextField
            label="Subscription Time"
            variant="standard"
            type="number"
            name="subscriptionTime"
            sx={{ marginBottom: "10px" }}
            fullWidth
            value={values.subscriptionTime}
            onChange={handleChange}
          />
          <TextField
            label="Percentage Per Load"
            variant="standard"
            type="number"
            name="percentagePerLoad"
            sx={{ marginBottom: "10px" }}
            fullWidth
            value={values.percentagePerLoad}
            onChange={handleChange}
          />
          <TextField
            label="Number Of Drivers"
            variant="standard"
            type="number"
            name="numberOfDrivers"
            sx={{ marginBottom: "10px" }}
            fullWidth
            value={values.numberOfDrivers}
            onChange={handleChange}
          />
          <TextField
            label="Credits"
            variant="standard"
            type="number"
            name="credits"
            sx={{ marginBottom: "10px" }}
            fullWidth
            value={values.credits}
            onChange={handleChange}
          />

          <TextField
            label="Price"
            variant="standard"
            type="number"
            name="price"
            sx={{ marginBottom: "10px" }}
            fullWidth
            value={values.price}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="error">
            Close
          </Button>
          {mode === 0 ? (
            <Button variant="contained" onClick={addPlan}>
              Add
            </Button>
          ) : (
            <Button variant="contained" onClick={updatePlan}>
              Update
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default SubscriptionPlan;
