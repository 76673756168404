/* eslint-disable */
import axios from "axios";

const emailNotifier = async ( subject, text, to) => {

  const backendURL = process.env.REACT_APP_BACKEND_URL;

    try {
      if (to) {
        await axios.get(
          `${backendURL}send-email?subject=${subject}&text=${text}&to=${to}`
        );
      } else {
          await axios.get(
          `${backendURL}send-email?subject=${subject}&text=${text}`
        );
      }
    } catch (error) {
      console.log(error);
    }
};

export default emailNotifier;
