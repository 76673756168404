import firebase from "./../firebase/firebase";

export const logActivity = async (activity, type) => {
  let canWebLogs = false;
  await firebase
    .firestore()
    .collection("Settings")
    .doc("Settings")
    .get()
    .then((snap) => (canWebLogs = snap.data().webLogs));
  if (canWebLogs) {
    const docRef = firebase
      .firestore()
      .collection("ActivityLogs")
      .doc("WebLogs");
    const name = localStorage.getItem("isLogin");
    const id = localStorage.getItem("id");
    docRef
      .get()
      .then((doc) => {
        const activityArray = doc.data()?.logs || [];
        const updatedActivityArray = [
          {
            activity: activity,
            type: type,
            loggedAt: new Date(),
            userName: name,
            userId: id,
          },
          ...activityArray,
        ];
        docRef
          .set({ logs: updatedActivityArray }, { merge: true })
          .then(() => {})
          .catch((error) => {
            console.error("Error updating logs: ", error);
          });
      })
      .catch((error) => {
        console.error("Error updating logs: ", error);
      });
  }
};

export const name = localStorage.getItem("isLogin");
export const id = localStorage.getItem("id");
