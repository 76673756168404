/* eslint-disable */
import React, { Component } from "react";
import { Modal, ModalBody, ModalTitle } from "react-bootstrap";
import firebase from "./../firebase/firebase";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { logActivity, name } from "../helper/ActivityLogger";

class LoadDetailsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      pickup: "",
      dropoff: "",
      date: new Date(),
      time: "10:00",
      loadType: "",
      company: "",
      notes: "",
      length: "",
      weight: "",
      price: "",
    };
  }
  onSubmit = () => {
    const {
      pickup,
      dropoff,
      date,
      time,
      company,
      notes,
      loadType,
      length,
      weight,
      price,
    } = this.state;
    const { selectedItem } = this.props;
    if (pickup === "") {
    } else if (dropoff === "") {
    } else if (date === "") {
    } else if (time === "") {
    } else if (loadType === "") {
    } else if (length === "") {
    } else if (weight === "") {
    } else if (company === "") {
    } else if (price === "") {
    } else {
      let offer = {
        pickup: pickup,
        destination: dropoff,
        length: length,
        weight: weight,
        fullOrPartial: loadType,
        date: date,
        time: time,
        company: company,
        notes: notes,
        status: "offer",
        price: price,
      };
      console.log("Add OFFER=>", offer);
      firebase
        .firestore()
        .collection("searchRequests")
        .doc(selectedItem.pathId)
        .collection("offers")
        .add(offer)
        .then(() => {
          logActivity(
            `${name} created an offer for Load [id: ${selectedItem.pathId}]`,
            "Search Requests"
          );
          alert("New Offer Added!");
        });
      this.props.onHide();
    }
  };

  render() {
    const { selectedItem } = this.props;
    let status = "";
    let color = "#78f576";
    if (selectedItem?.status === "approve") {
      status = "Approved";
      color = "#78f576";
    } else if (selectedItem?.status === "readyForPickup") {
      status = "Ready for pickup";
      color = "#f9b1fa";
    }
    if (selectedItem?.status === "enrouteToLoad") {
      status = "Enroute to load";
      color = "#9c6cf5";
    }
    if (selectedItem?.status === "loaded") {
      status = "Loaded";
      color = "#9c2f08";
    }
    if (selectedItem?.status === "enrouteToDrop") {
      status = "Enroute to drop";
      color = "#92adf0";
    }
    if (selectedItem?.status === "dropped") {
      status = "Dropped";
      color = "#f5b14c";
    }
    if (selectedItem?.status === "delivered") {
      status = "Delivered";
      color = "#82fc2b";
    }
    return (
      <div>
        <Modal
          show={this.props.showModal}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
          fullscreen={true}
          onHide={() => {
            this.props.onHide();
          }}
        >
          <ModalHeader closeButton>
            <ModalTitle>
              <div>
                <h3>Load Details for {selectedItem?.driver?.name}</h3>
                <text
                  style={{
                    backgroundColor: color,
                    padding: 10,
                    borderRadius: 10,
                  }}
                >
                  {status}
                </text>
                <h5 style={{ marginTop: 10 }}>
                  Load Id: {selectedItem?.loadRefsID}
                </h5>
              </div>
            </ModalTitle>
          </ModalHeader>
          <ModalBody>
            <div className="offer-f">
              <div className="f-group">
                <label for="name">Pickup Location</label>
                <br />
                <text>
                  <h3>{selectedItem?.pickup}</h3>
                </text>
              </div>
              <div className="f-group">
                <label for="name">Drop-Off Location</label>
                <br />
                <text>
                  <h3>{selectedItem?.destination}</h3>
                </text>
              </div>
              <div className="f-group">
                <label for="name">Company Name</label>
                <br />
                <text>
                  <h3>{selectedItem?.driver?.companyName}</h3>
                </text>
              </div>
              <div className="f-group">
                <label for="name">Date</label>
                <br />
                <text>
                  <h3>{selectedItem?.loadDate?.toDateString()}</h3>
                </text>
                {/* <input type="text" /> */}
              </div>
              <div className="f-group">
                <label for="name">Time</label>
                <br />
                <text>
                  <h3>
                    {moment(
                      selectedItem?.searchstartDate?.seconds * 1000 +
                        selectedItem?.searchstartDate.nanoseconds / 1000000
                    ).format("hh:mm A")}
                  </h3>
                </text>
                {/* <input type="text" /> */}
              </div>
              <div className="f-group">
                <label for="name">Truck Length</label>
                <br />
                <text>
                  <h3>{selectedItem?.truckLength}</h3>
                </text>
              </div>
              <div className="f-group">
                <label for="name">Load Weight</label>
                <br />
                <text>
                  <h3>{selectedItem?.loadWeight}</h3>
                </text>
              </div>
              <div className="f-group">
                <label for="name">Full/Partial</label>
                <br />
                <text>
                  <h3>{selectedItem?.loadType}</h3>
                </text>
              </div>

              <div className="f-group">
                <label for="name">Notes</label>
                <br />
                <text>
                  <h3>{selectedItem?.comment}</h3>
                </text>
              </div>
              <div className="f-group">
                <label for="name">Actual Price</label>
                <br />
                <text>
                  <h3>${selectedItem?.cost.actualPrice}</h3>
                </text>
              </div>
              <div className="f-group">
                <label for="name">Driver Bid</label>
                <br />
                <text>
                  <h3>${selectedItem?.cost.driverBid}</h3>
                </text>
              </div>
              <div className="f-group">
                <label for="name">Truck Type</label>
                <br />
                <text>
                  <h3>{selectedItem?.truckType}</h3>
                </text>
              </div>
              <div className="f-group">
                <label for="name">Empty Truck Image</label>
                <br />
                <text>
                  <img
                    src={selectedItem?.emptyTrailorImage}
                    width="150"
                    height="150"
                    style={{
                      resize: "both",
                    }}
                  ></img>
                </text>
              </div>
              <div className="f-group">
                <label for="name">Loaded Truck Image</label>
                <br />
                <text>
                  <img
                    src={selectedItem?.loadedTrailorImage}
                    width="150"
                    height="150"
                    style={{
                      resize: "both",
                    }}
                  ></img>
                </text>
              </div>
              <div className="f-group">
                <label htmlFor="name">Rate Confirmation</label>
                <br />
                <a
                  href={selectedItem?.approvedDoc}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View Rate Confirmation
                </a>
              </div>
              {/* <br />
              <div className="f-group">
                <label htmlFor="name">BOL</label>
                <br />
                <a
                  href={selectedItem?.bolDoc}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View BOL
                </a>
              </div> */}
              <br />
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
export default LoadDetailsModal;
