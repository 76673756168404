import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalTitle,
} from "react-bootstrap";
import firebase from "../firebase/firebase";
import { DataGrid } from "@mui/x-data-grid";
import JSONView from "react-json-view";
import { CircularProgress } from "@mui/material";
export const ViewlogModel = ({ showBidModal, selectedItem, onCloseModal }) => {
  const [data, setdata] = useState([]);
  const [requestData, setRequestData] = useState([]);
  const [colapIndex, setcolapIndex] = useState(null);
 
  const [gettinglogdataloading, setgettinglogdataloading] = useState(true);
  useEffect(() => {
    setcolapIndex(null);
    const dataobjname = {
      0: "DirectFrightResponse",
      1: "LoadBoardResponse",
      2: "TruckersEdgeResponse",
      3: "VirtualFleetResponse",
    };
    setdata([]);
    setRequestData(null);

    if (selectedItem?.pathId && gettinglogdataloading) {
      console.log(selectedItem?.pathId, "id");
      setgettinglogdataloading(true);

      const datafetch = async () => {
        // const requestData = await firebase
        // .firestore()
        // .collection("searchRequests").doc(selectedItem?.pathId).get()
        // setRequestData(requestData.data())
        // request
        const requestData = await firebase
          .firestore()
          .collection("searchRequests")
          .doc(selectedItem?.pathId)
          .collection("ScrappersRequest")
          .get();
        // if (requestData) {
        // console.log(requestData,"requestData")
        // const allReq=requestData.docs.map((x)=>x.data())
        const allRequest = requestData.docs.map((x, i) => {
          return {
            name: dataobjname[i],
            data: x.data(),
          };
        });
        console.log(allRequest, "allR");
        setRequestData(allRequest);

        // }

        // response
        const response = await firebase
          .firestore()
          .collection("searchRequests")
          .doc(selectedItem?.pathId)
          .collection("ScrappersResponse")
          .get();
        // const allRes=response.docs.map((x)=>x.data())
        const allResponse = response.docs.map((x, i) => {
          return {
            name: dataobjname[i],
            data: x.data(),
          };
        });

        console.log(allResponse, "AllRes");
        setdata(allResponse);
      };
      datafetch();
    }
    return () => {
      setgettinglogdataloading(false);
    };
  }, [gettinglogdataloading, selectedItem]);

  const mycollapsed = (i) => {
    console.log(i);
    if (colapIndex === i) {
      setcolapIndex(null);
    } else {
      setcolapIndex(null);
      setcolapIndex(i);
    }
  };
  // console.log(requestData,"o")

  return (
    <>
      <Modal
        size="lg"
        // style={{ height: '100%' }}
        // scrollable={true}
        show={showBidModal}
        // dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        fullscreen={true}
        onHide={() => {
          setcolapIndex(null);
          onCloseModal();
        }}
      >
        <ModalHeader closeButton>
          <ModalTitle> Response View logs</ModalTitle>
        </ModalHeader>
        {/* <div style={{ display:"flex",marginLeft:"5px"}} > */}

        {/* <div style={{ display:"flex",marginLeft:"5px",flexDirection:"column"}} > */}
        <ModalBody style={{ display: "flex", flexDirection: "column" }}>
          {/* {(requestData!==null&&requestData!==undefined)&&requestData?( */}

          {/* <div style={{ display:"flex",marginLeft:"5px",flexDirection:"column",cursor:"pointer"}} >
            <h3 style={{marginLeft:"5px"}} onClick={()=>mycollapsed(Number(4))}>Request Data</h3>
            {(requestData!==null&&requestData!==undefined)&&requestData?(<JSONView collapseStringsAfterLength={3}  
            
            collapsed={colapIndex===null?true:Number(4)===Number(colapIndex)?false:true} 
           
             src={requestData}  />):("No data")}
            </div> */}

          <div style={{ display: "flex" }}>
            {data.length > 0 ? (
              data.map((x, index) => (
                <div
                  style={{
                    display: "flex",
                    marginLeft: "5px",
                    flexDirection: "column",
                    cursor: "pointer",
                  }}
                >
                  <h3
                    style={{ marginLeft: "5px" }}
                    onClick={() => mycollapsed(Number(index))}
                  >
                    {x.name}
                  </h3>
                  {x.data.data.length > 0 &&
                  Object.keys(x.data.data).length > 0 ? (
                    <JSONView
                      collapseStringsAfterLength={3}
                      collapsed={
                        colapIndex === null
                          ? true
                          : Number(index) === Number(colapIndex)
                          ? false
                          : true
                      }
                      src={x.data.data}
                    />
                  ) : (
                    "No data"
                  )}
                </div>
              ))
            ) : (
              <CircularProgress />
            )}
          </div>

          <ModalHeader>
            <ModalTitle>Request View logs</ModalTitle>
          </ModalHeader>
          <div style={{ display: "flex" }}>
            {requestData && requestData.length > 0 ? (
              // <div>hh</div>:"no"
              requestData.map((x, index) => (
                <div
                  style={{
                    display: "flex",
                    marginLeft: "5px",
                    flexDirection: "column",
                    cursor: "pointer",
                  }}
                >
                  <h3
                    style={{ marginLeft: "5px" }}
                    onClick={() => mycollapsed(Number(index) + 4)}
                  >
                    {x.name}
                  </h3>
                  {requestData.length > 0 && Object.keys(x.data).length > 0 ? (
                    <JSONView
                      collapseStringsAfterLength={3}
                      collapsed={
                        colapIndex === null
                          ? true
                          : Number(index) === Number(colapIndex)
                          ? false
                          : true
                      }
                      src={x.data}
                    />
                  ) : (
                    "No data"
                  )}
                </div>
              ))
            ) : (
              <CircularProgress />
            )}
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};
