const initialState = {
    eventlog: false,
   
  };
  
  export const UserResponseEventtab = (state = initialState, action) => {
    // eslint-disable-next-line
    const { payload } = action;
    switch (action.type) {
      case "UserResponse_tab":
        return {
          ...state,
          eventlog: action.payload,
        };
  
      default:
        return state;
    }
  };
  
  export default UserResponseEventtab;
  