import { combineReducers } from 'redux'
import tabs from './tabs.reducer'
import UserResponseEventtab from './EventReducer'


//insert another reducers here to be combined

const reducers = combineReducers({
  tabs,
  UserResponseEventtab
  
})

export default reducers
