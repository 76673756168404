/* eslint-disable */
import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { getFirestore, onSnapshot, doc } from "firebase/firestore";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import firebase, { firebaseConfig } from "./../firebase/firebase";
import Maps from "./Map";
import { connect } from "react-redux";
import { setActiveTab } from "../stores/actions/tabs.action";
import CircularProgress from "@material-ui/core/CircularProgress";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      statistics: null,
      activeLoadDrivers: [],
      allDrivers: [],
      searchResults: [],
      searchTerm: "",
      selectedItem: null,
      loading: true,
      TotalLoads: 0,
      TotalTeams: 0,
      ActiveLoads: 0,
      completedLoadHistory: 0,
      Activedispatchers: 0,
      InActivedispatchers: 0,
      PaidInvoice: 0,
      UnPaidInvoice: 0,
    };
  }

  async componentDidMount() {
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
    const db = getFirestore();
    let statistics = null;
    this.getactiveLoadDrivers();

    const teamsCollectionRef = firebase.firestore().collection("teams");
    const statisticsDocRef = firebase
      .firestore()
      .collection("statistics")
      .doc("totalDrivers");

    const updateTotalData = async () => {
      const allDriversCollectionRef = firebase
        .firestore()
        .collectionGroup("drivers");

      allDriversCollectionRef.onSnapshot(async (querySnapshot) => {
        let totalDriversCount = querySnapshot.size;

        const teamsSnapshot = await teamsCollectionRef.get();
        let totalTeamsCount = teamsSnapshot.size;

        // console.log("Total Drivers:", totalDriversCount);
        // console.log("Total Teams:", totalTeamsCount);

        // Calculate total of drivers and teams
        const totalDriversAndTeams = totalDriversCount + totalTeamsCount;

        // console.log("Total Drivers:", totalDriversAndTeams);

        // Update statistics document
        statisticsDocRef.set({
          drivers: totalDriversCount,
          teams: totalTeamsCount,
          total: totalDriversAndTeams,
        });
        this.setState({
          TotalTeamsAndDrivers: totalDriversAndTeams,
        });
      });
    };

    updateTotalData();
  }

  getactiveLoadDrivers = async () => {
    firebase
      .firestore()
      .collection("loads")
      .onSnapshot((snapshot) => {
        const activeLoadsCount = snapshot.size;

        this.setState({
          ActiveLoads: snapshot.docs.map((doc) => doc.data()),
        });

        firebase.firestore().collection("statistics").doc("Activeloads").set(
          {
            ActiveLoads: activeLoadsCount,
          },
          { merge: true }
        );
      });

    let data2 = firebase
      .firestore()
      .collection("loadsHistory")
      .onSnapshot(async (data) => {
        let allccompltedHistory = [];
        await Promise.all(
          data.docs.map(async (doc) => {
            const snapshot = await firebase
              .firestore()
              .collection("loadsHistory")
              .doc(doc.id)
              .collection("completed")
              .get();
            const activeLoads = snapshot.docs.map((doc) => doc.data());
            allccompltedHistory = [...activeLoads, ...allccompltedHistory];
          })
        );
        const PaidUnPiad = allccompltedHistory.reduce(
          (a, c) => {
            if (c.paidTheInvoice) {
              a["paid"].push(c);
            } else {
              a["unpaid"].push(c);
            }
            return a;
          },
          { paid: [], unpaid: [] }
        );

        this.setState({
          PaidInvoice: PaidUnPiad.paid.length,
          UnPaidInvoice: PaidUnPiad.unpaid.length,
          completedLoadHistory: allccompltedHistory.length,
        });
        // updating Invoice totals if present else creats
        const statisticsRef = firebase
          .firestore()
          .collection("statistics")
          .doc("Invoice");
        const CompletedLoadTotal = firebase
          .firestore()
          .collection("statistics")
          .doc("CompletedLoadTotal");
        statisticsRef.set(
          {
            paid: this.state.PaidInvoice,
            unpaid: this.state.UnPaidInvoice,
          },
          { merge: true }
        );
        CompletedLoadTotal.set(
          {
            CompletedLoad: this.state.completedLoadHistory,
          },
          { merge: true }
        );
      });

    // fun for dispatcher

    // Active and InActive dispatchers

    firebase
      .firestore()
      .collection("dispatchers")
      .onSnapshot((snap) => {
        let Activedispatchers = 0;
        let InActivedispatchers = 0;
        snap.docs.forEach(async (a) => {
          if (a.data()["Active"] === true) {
            Activedispatchers = Activedispatchers + 1;
          } else if (a.data()["Active"] === false) {
            InActivedispatchers = InActivedispatchers + 1;
          }
        });
        this.setState({
          Activedispatchers: Activedispatchers,
          InActivedispatchers: InActivedispatchers,
        });

        // updating Active and inActivedispatchers
        let dispatchersstatus = firebase
          .firestore()
          .collection("statistics")
          .doc("ActiveInActiveDispatchers");
        dispatchersstatus.set(
          {
            Activedispatchers: this.state.Activedispatchers,
            InActivedispatchers: this.state.InActivedispatchers,
          },
          { merge: true }
        );
      });

    // fun  for statics
    firebase
      .firestore()
      .collection("statistics")
      .onSnapshot((snap) => {
        let drivers = 0;
        let Totalloads = 0;
        snap.docs.forEach(async (a) => {
          if (a.data()["totalLoads"]) {
            Totalloads = a.data().totalLoads;
          } else if (a.data()["count"]) {
            drivers = a.data().count;
          }
        });

        this.setState({
          TotalLoads: Totalloads,
          TotalTeams: drivers,
        });
      });
    firebase
      .firestore()
      .collection("loads")
      .onSnapshot((snap) => {
        let temp = [];
        snap.docs.forEach(async (a) => {
          let da = a.data().currentLoad;
          if (da?.status === "completed") {
          } else {
            if (da?.location) {
              // alert(da.location.latitude);
              temp.push({
                ...da,
                coords: {
                  lat: da.location.latitude,
                  lng: da.location.longitude,
                },
              });
            }
          }
        });
        this.setState({
          activeLoadDrivers: temp,
          loading: false,
        });
      });

    firebase
      .firestore()
      .collection("driversLocation")
      .onSnapshot((snapshot) => {
        const data = snapshot.docs.map((doc) => doc.data());
        this.setState(
          {
            allDrivers: data.filter((driver) => {
              if (driver?.location?.latitude && driver?.location?.longitude) {
                return true;
              } else {
                return false;
              }
            }),
            loading: false,
          },
          () => {
            this.setState({
              loading: false,
            });
          }
        );
      });
  };

  handleSearchChange = (event) => {
    const value = event.target.value;
    if (value !== "" && value !== undefined) {
      const activeLoadDrivers = this.state.activeLoadDrivers;
      const allDrivers = this.state.allDrivers;
      var filteredResults = [];
      for (var i = 0; i < activeLoadDrivers.length; i++) {
        if (activeLoadDrivers[i].driver) {
          if (
            activeLoadDrivers[i]?.driver?.name
              ?.toLowerCase()
              .startsWith(value?.toLowerCase()) ||
            activeLoadDrivers[i]?.driver.mcNumber?.startsWith(value) ||
            activeLoadDrivers[i]?.driver?.companyName
              ?.toLowerCase()
              .startsWith(value?.toLowerCase())
          ) {
            filteredResults.push(activeLoadDrivers[i]);
          }
        }
      }

      for (var i = 0; i < allDrivers.length; i++) {
        if (
          allDrivers[i]?.name?.toLowerCase().startsWith(value?.toLowerCase()) ||
          allDrivers[i]?.companyName
            ?.toLowerCase()
            .startsWith(value.toLowerCase())
        ) {
          filteredResults.push(allDrivers[i]);
        }
      }
      this.setState({
        searchTerm: value,
        searchResults: filteredResults,
      });
    } else {
      this.setState({
        searchTerm: "",
        searchResults: [],
      });
    }
  };

  handleClick = (event) => {
    const item = event.target.getAttribute("value");
    const activeLoadDrivers = this.state.activeLoadDrivers;
    const allDrivers = this.state.allDrivers;
    var selectedDriver;
    for (var i = 0; i < activeLoadDrivers.length; i++) {
      if (activeLoadDrivers[i].driver) {
        if (activeLoadDrivers[i].driver.id === item) {
          selectedDriver = activeLoadDrivers[i];
          break;
        }
      }
    }
    for (var i = 0; i < allDrivers.length; i++) {
      if (allDrivers[i].id === item) {
        selectedDriver = allDrivers[i];
        break;
      }
    }
    this.setState({
      searchTerm: "",
      searchResults: [],
      selectedItem: selectedDriver
        ? { ...selectedDriver?.location, id: selectedDriver.id }
        : null,
    });
  };

  render() {
    const {
      searchTerm,
      searchResults,
      TotalLoads,
      TotalTeams,
      ActiveLoads,
      completedLoadHistory,
      PaidInvoice,
      UnPaidInvoice,
      TotalTeamsAndDrivers,
    } = this.state;
    // console.log(TotalLoads,TotalDriver,"alreday")
    return (
      <div className="dash-home">
        <div className="dash-map">
          {/* <div className="dash-noti">
                        <h2>CI/CD ReactLoads Ready Fo Pickups</h2>
                        <span>Pick-Up</span>
                        <div className="pickup-detial">
                            <div className="pic">
                                <img src={profile} />
                                <svg viewBox="0 0 150 603" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="75" cy="75" r="75" fill="#7A5CD1" />
                                    <path d="M77.5763 170.611V439.408" stroke="#818181" stroke-width="5" />
                                    <circle cx="75" cy="527.863" r="75" fill="#7A5CD1" />
                                </svg>

                            </div>
                            <div className="pickup-txt">
                                <div className='l-txt'>
                                    <span className="l-top-txt">Cleveland,OH</span>
                                    <span className="l-b-txt">Houston, TX</span>
                                </div>
                                <div className='r-txt'>
                                    <span className="r-top-txt">40 ft</span>
                                    <span className="r-w-txt">40,000 ibs</span>
                                    <span className="r-b-txt">$3,700</span>
                                </div>

                            </div>
                        </div>
                        <table>
                            <tr className='row-3'>
                                <th className='row-2'>Pick-up Date:</th>
                                <td className='row-1'>01-01-2021</td>
                            </tr>
                            <tr className='row-3'>
                                <th className='row-2'>Pick-up Hour:</th>
                                <td className='row-1'>1:00 PM</td>
                            </tr>
                            <tr className='row-3'>
                                <th className='row-2'>Truck type:</th>
                                <td className='row-1'>Delivery</td>
                            </tr>
                            <tr className='row-3'>
                                <th className='row-2'>Legnth:</th>
                                <td className='row-1'>40 ft</td>
                            </tr>
                            <tr className='row-3'>
                                <th className='row-2'>Weight:</th>
                                <td className='row-1'>40,000 lbs</td>
                            </tr>
                            <tr className='row-3'>
                                <th className='row-2'>Comment:</th>
                                <td className='row-1'>Nil</td>
                            </tr>
                            <tr className='row-3'>
                                <th className='row-2'>Commodity:</th>
                                <td className='row-1'>ABC</td>
                            </tr>
                        </table>
                    </div> */}
          <div className="dash-search">
            <div className="col-md-8">
              <div className="search">
                {" "}
                <FontAwesomeIcon icon={faSearch} />{" "}
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search Drivers...."
                  value={searchTerm}
                  onChange={this.handleSearchChange}
                />
                {searchResults.length > 0 && (
                  <ul className="dropdown-menu1">
                    {searchResults.map((result, index) => (
                      <li
                        onClick={this.handleClick}
                        key={result.driver ? result.driver.id : result.id}
                        value={result?.driver?.id || result.id}
                      >
                        {result.driver
                          ? result.driver.name + " (Active Load)"
                          : result.name + " (" + result.companyName + ")"}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          </div>
          <div className="maps">
            {!this.state.loading ? (
              <Maps
                selectedDriver={this.state.selectedItem}
                allDriver={this.state.allDrivers.concat(
                  this.state.activeLoadDrivers
                )}
              ></Maps>
            ) : (
              <CircularProgress size={80} style={{ alignSelf: "absolute" }} />
            )}
          </div>
        </div>
        <div className="dash-list">
          <div
            onClick={() => this.props.setActiveTab("Loads History")}
            className="list-item"
          >
            <h2>Total Loads Booked</h2>
            {/* <span>{statistics?.total_load_book}</span> */}
            <div
              style={{
                textAlign: "right",
                display: "flex",
                flexDirection: "row-reverse",
                alignItems: "center",
                fontSize: "25px",
                color: "#7a5cd1",
                fontWeight: "bold",
                height: "100%",
              }}
            >
              {TotalLoads}
            </div>
          </div>
          <div
            onClick={() => this.props.setActiveTab("completed Loads")}
            className="list-item"
          >
            <h2>Completed Loads</h2>
            {/* <span>{statistics?.total_load_book}</span> */}
            <div
              style={{
                textAlign: "right",
                display: "flex",
                flexDirection: "row-reverse",
                alignItems: "center",
                fontSize: "25px",
                color: "#7a5cd1",
                fontWeight: "bold",
                height: "100%",
              }}
            >
              {completedLoadHistory}
            </div>
          </div>
          <div
            onClick={() => this.props.setActiveTab("Loads")}
            className="list-item"
          >
            <h2>Active Loads</h2>
            <div
              style={{
                textAlign: "right",
                display: "flex",
                flexDirection: "row-reverse",
                alignItems: "center",
                fontSize: "25px",
                color: "#7a5cd1",
                fontWeight: "bold",
                height: "100%",
              }}
            >
              {ActiveLoads.length}
            </div>
          </div>
          <div
            onClick={() => this.props.setActiveTab("Dispatcher")}
            className="list-item"
          >
            <h2>Inactive Dispatchers</h2>
            <span
              style={{
                textAlign: "right",
                display: "flex",
                flexDirection: "row-reverse",
                alignItems: "center",
                fontSize: "25px",
                color: "#7a5cd1",
                fontWeight: "bold",
                height: "100%",
              }}
            >
              {this.state.InActivedispatchers}
            </span>
          </div>
          <div
            onClick={() => this.props.setActiveTab("Dispatcher")}
            className="list-item"
          >
            <h2>Active Dispatchers</h2>
            <span
              style={{
                textAlign: "right",
                display: "flex",
                flexDirection: "row-reverse",
                alignItems: "center",
                fontSize: "25px",
                color: "#7a5cd1",
                fontWeight: "bold",
                height: "100%",
              }}
            >
              {this.state.Activedispatchers}
            </span>
          </div>
          <div
            onClick={() => this.props.setActiveTab("Dashboard")}
            className="list-item"
          >
            <h2>Total Teams</h2>

            <div
              style={{
                textAlign: "right",
                display: "flex",
                flexDirection: "row-reverse",
                alignItems: "center",
                fontSize: "25px",
                color: "#7a5cd1",
                fontWeight: "bold",
                height: "100%",
              }}
            >
              {TotalTeams}
            </div>
          </div>
          <div
            onClick={() => this.props.setActiveTab("Dashboard")}
            className="list-item"
          >
            <h2>Total Drivers</h2>

            <div
              style={{
                textAlign: "right",
                display: "flex",
                flexDirection: "row-reverse",
                alignItems: "center",
                fontSize: "25px",
                color: "#7a5cd1",
                fontWeight: "bold",
                height: "100%",
              }}
            >
              {TotalTeamsAndDrivers}
            </div>
          </div>
          {/* <div
            onClick={() => this.props.setActiveTab("Dashboard")}
            className="list-item"
          >
            <h2>Active Drivers</h2>
            <span>{statistics?.total_active_drivers}</span>
          </div> */}
          <div
            onClick={() => this.props.setActiveTab("Payments")}
            className="list-item"
          >
            <h2>Total Paid Invioce</h2>
            <div
              style={{
                textAlign: "right",
                display: "flex",
                flexDirection: "row-reverse",
                alignItems: "center",
                fontSize: "25px",
                color: "#7a5cd1",
                fontWeight: "bold",
                height: "100%",
              }}
            >
              {PaidInvoice}
            </div>
          </div>
          <div
            onClick={() => this.props.setActiveTab("Payments")}
            className="list-item"
          >
            <h2>Total Unpaid Invioce</h2>
            <div
              style={{
                textAlign: "right",
                display: "flex",
                flexDirection: "row-reverse",
                alignItems: "center",
                fontSize: "25px",
                color: "#7a5cd1",
                fontWeight: "bold",
                height: "100%",
              }}
            >
              {UnPaidInvoice}
            </div>
          </div>
          {/* <div className="list-item">
                        <h2>Total Companies</h2>
                        <span>{statistics?.total_active_loads}</span>
                    </div> */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  setActiveTab,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
