/* eslint-disable */
import React, { Component } from "react";
import { Modal, ModalBody, ModalTitle } from "react-bootstrap";
import firebase from "./../firebase/firebase";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import SendPushNotification from "./../firebase/SendPushNotification";
import { logActivity, name } from "../helper/ActivityLogger";
import emailNotifier from "../helper/emailNotifier";
class BidsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      notes: "",
      action: "",
      selectedBid: null,
      percentagePerLoad: "",
    };
  }

  onSubmit = () => {
    const {
      pickup,
      dropoff,
      date,
      time,
      company,
      notes,
      loadType,
      length,
      weight,
      price,
    } = this.state;
    const { selectedItem } = this.props;
    if (pickup === "") {
    } else if (dropoff === "") {
    } else if (date === "") {
    } else if (time === "") {
    } else if (loadType === "") {
    } else if (length === "") {
    } else if (weight === "") {
    } else if (company === "") {
    } else if (price === "") {
    } else {
      let offer = {
        pickup: pickup,
        destination: dropoff,
        length: length,
        weight: weight,
        fullOrPartial: loadType,
        date: date,
        time: time,
        company: company,
        notes: notes,
        status: "offer",
        price: price,
      };
      console.log("Add OFFER=>", offer);
      firebase
        .firestore()
        .collection("searchRequests")
        .doc(selectedItem.pathId)
        .collection("offers")
        .add(offer)
        .then(() => {
          logActivity(
            `${name} created an offer for Load [id: ${selectedItem.pathId}]`,
            "Search Requests"
          );
          alert("New Offer Added!");
        });
      this.props.onHide();
    }
  };

  formatNumberWithPrefix(number, prefix, width) {
    // Convert the number to a string
    let numberString = number.toString();

    // Calculate the number of zeros needed to reach the desired width
    let zerosToAdd = width - numberString.length;

    // Add zeros to the beginning of the string
    for (let i = 0; i < zerosToAdd; i++) {
      numberString = "0" + numberString;
    }

    // Concatenate the prefix and the formatted number
    let result = prefix + numberString;

    return result;
  }

  acceptDeclineBid = async () => {
    // console.log("yahoo")
    const { notes, action, selectedBid } = this.state;
    const { selectedItem } = this.props;
    let offer = {
      status: action === "decline" ? "decline" : "approve",
      comment: notes ? notes : "",
    };
    if (selectedItem.dispatcher) {
      firebase
        .firestore()
        .collection("searchRequests")
        .doc(selectedItem.pathId)
        .collection("offers")
        .doc(selectedBid.bidId)
        .set(offer, { merge: true })
        .then(() => {
          // alert(action === "decline" ? "Bid decline successfully!" : "Bid accept successfully!")
        });
      if (action === "decline") {
        var title = "BID DECLINED";
        var body = "Your bid has been declined!";
        var notificationData = await firebase
          .firestore()
          .collection("templates")
          .doc("Bid Declined")
          .get();
        if (notificationData.exists) {
          notificationData = notificationData.data();
          body = notificationData.body;
          title = notificationData.title;
        }
        SendPushNotification(
          title,
          `Dear ${selectedItem.driver?.name}! ${body}`,
          selectedItem.driver?.id
        );
        if (selectedItem.driver.admin) {
          SendPushNotification(
            title,
            `Dear ${selectedItem.driver?.name}! ${body}`,
            selectedItem.driver?.admin
          );
        }
        logActivity(
          `${name} declined a bid for Load [id: ${selectedItem.pathId}] on offer [id: ${selectedBid.bidId}]`,
          "Search Requests"
        );
        emailNotifier(
          "BID DECLINED",
          `Hi, Unfortunatly your Bid has been declined! Please check GoHaul for more information!`,
          selectedItem.driver?.email
        );
        this.props.onHide();
        this.setState({ notes: "" });
      } else {
        var totalLoad;
        const stats = await firebase
          .firestore()
          .collection("statistics")
          .doc("statistic")
          .get();
        if (!selectedItem?.driver?.admin) {
          await firebase
            .firestore()
            .collection("teams")
            .doc(selectedItem?.driver?.id)
            .get()
            .then((snap) => {
              if (snap.exists) {
                this.setState({
                  percentagePerLoad:
                    snap.data()?.subscriptionPlan?.percentagePerLoad,
                });
              }
            });
        } else {
          await firebase
            .firestore()
            .collection("teams")
            .doc(selectedItem.admin)
            .get()
            .then((snap) => {
              if (snap.exists) {
                this.setState({
                  percentagePerLoad:
                    snap.data()?.subscriptionPlan?.percentagePerLoad,
                });
              }
            });
        }
        if (stats.exists) {
          totalLoad = stats.data().totalLoads + 1;
        } else {
          totalLoad = 1;
        }
        let loads = {
          status: "approve",
          comment: notes ? notes : "",
          driver: selectedItem.driver,
          loadId: selectedItem.pathId,
          loadRefsID: this.formatNumberWithPrefix(totalLoad, "GH", 4),
          pickup: selectedBid.pickup,
          destination: selectedBid.destination,
          searchstartDate: selectedItem.startDate,
          searchendDate: selectedItem.endDate,
          time: selectedBid.time,
          loadDate: selectedBid.date,
          loadType: selectedItem.fullOrPartial,
          destinationDH: selectedItem.destinationDH,
          pickupDH: selectedItem.pickupDH,
          truckLength: selectedItem.length,
          loadWeight: selectedItem.weight,
          truckType: selectedItem.truckType,
          locationToAvoid: selectedItem.locationToAvoid,
          cost: {
            driverBid: selectedBid.driverBid,
            actualPrice: selectedBid.price,
            amountToPay: parseInt(
              (
                Number(selectedBid.driverBid) *
                (Number(this.state.percentagePerLoad) / 100)
              ).toFixed(2)
            ),
          },
          companyName: selectedBid.company,
          notes: selectedBid.notes,
          pickupPlaceId: selectedBid.pickupPlaceId,
          // dropoffPlaceId: selectedBid.dropoffPlaceId,
          dispatcher: selectedItem.dispatcher,
        };
        firebase
          .firestore()
          .collection("loads")
          .doc(selectedItem.pathId)
          .collection("activeLoads")
          .add(loads)
          .then((docRef) => {
            firebase
              .firestore()
              .collection("loads")
              .doc(selectedItem.pathId)
              .set({ currentLoad: loads })
              .then(() => {
                firebase
                  .firestore()
                  .collection("loads")
                  .doc(selectedItem.pathId)
                  .collection("activeLoads")
                  .doc(docRef.id)
                  .delete()
                  .then(() => {
                    console.log("Bid deleted from activeLoads");
                  })
                  .catch((error) => {
                    console.error(
                      "Error deleting bid from activeLoads: ",
                      error
                    );
                  });
              });
          });
        firebase.firestore().collection("statistics").doc("statistic").set(
          {
            totalLoads: totalLoad,
          },
          { merge: true }
        );
        firebase
          .firestore()
          .collection("searchRequests")
          .doc(selectedItem.pathId)
          .set(
            {
              status: "Bid Approved",
            },
            { merge: true }
          );
        const offersRef = firebase
          .firestore()
          .collection("searchRequests")
          .doc(selectedItem.pathId)
          .collection("offers");
        const batch = firebase.firestore().batch();

        offersRef
          .get()
          .then((query) => {
            query.forEach((doc) => {
              if (doc.id !== selectedBid.bidId) {
                batch.delete(doc.ref);
              }
            });

            return batch.commit();
          })
          .catch((error) => {
            console.log("Error deleting offers: ", error);
          });
        alert(
          action === "decline"
            ? "Bid decline successfully!"
            : "Bid accept successfully!"
        );
        var title = "BID ACCEPTED";
        var body = "Your bid has been accepted!";
        var notificationData = await firebase
          .firestore()
          .collection("templates")
          .doc("Bid Accepted")
          .get();
        if (notificationData.exists) {
          notificationData = notificationData.data();
          body = notificationData.body;
          title = notificationData.title;
        }
        SendPushNotification(
          title,
          `Dear ${selectedItem.driver?.name}! ${body}`,
          selectedItem.driver?.id
        );
        if (selectedItem.driver.admin) {
          SendPushNotification(
            title,
            `Dear ${selectedItem.driver?.name}! ${body}`,
            selectedItem.driver?.admin
          );
        }
        logActivity(
          `${name} accepted a bid for Load [id: ${selectedItem.pathId}] on offer [id: ${selectedBid.bidId}]`,
          "Search Requests"
        );
        emailNotifier(
          "BID ACCEPTED",
          `Hi, Your Bid has been accepted! Please check GoHaul for more information!`,
          selectedItem.driver?.email
        );
        this.props.onHide(selectedItem.pathId);
        console.log(selectedItem);
      }
    } else {
      alert("Please assign Dispatcher first!");
    }
  };
  render() {
    const { showModal, notes, action } = this.state;
    const { selectedItem } = this.props;

    const cellStyle = {
      padding: "10px",
      textAlign: "center",
      border: "1px solid #ddd",
    };

    return (
      <div>
        <Modal
          show={this.props.showBidModal}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
          fullscreen={true}
          onHide={this.props.onHide}
        >
          <ModalHeader closeButton>
            <ModalTitle>Bid made by {selectedItem?.driver?.name}</ModalTitle>
          </ModalHeader>
          <ModalBody>
            <div style={{ overflowX: "auto" }}>
              <table style={{ width: "100%", borderCollapse: "collapse" }}>
                <thead>
                  <tr>
                    <th style={cellStyle}>Name</th>
                    <th style={cellStyle}>Pick-up</th>
                    <th style={cellStyle}>Drop-off</th>
                    <th style={cellStyle}>Truck size/length</th>
                    <th style={cellStyle}>Weight</th>
                    <th style={cellStyle}>Load type</th>
                    <th style={cellStyle}>Price</th>
                    <th style={cellStyle}>Driver bid</th>
                    <th style={cellStyle}>Truck</th>
                    <th style={cellStyle}>TRIP</th>
                    <th style={cellStyle}>Company</th>
                    <th style={cellStyle}>Contact</th>
                    <th style={cellStyle}>Notes</th>
                    <th style={cellStyle}>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedItem?.pending.map((list) => (
                    <tr key={list.bidId}>
                      <td style={cellStyle}>{selectedItem.driver.name}</td>
                      <td style={cellStyle}>{list.pickup}</td>
                      <td style={cellStyle}>{list.destination}</td>
                      <td style={cellStyle}>{list.length}</td>
                      <td style={cellStyle}>{list.weight}</td>
                      <td style={cellStyle}>{list.fullOrPartial}</td>
                      <td style={cellStyle}>{list.price}</td>
                      <td style={cellStyle}>{list.driverBid}</td>
                      <td style={cellStyle}>{list.truck}</td>
                      <td style={cellStyle}>{list.trip}</td>
                      <td style={cellStyle}>{list.company}</td>
                      <td style={cellStyle}>{list.contact}</td>
                      <td style={cellStyle}>{list.notes}</td>
                      <td style={cellStyle}>
                        <button
                          style={{
                            backgroundColor: "red",
                            color: "white",
                            padding: "8px",
                            borderRadius: "10px",
                            marginBottom: "5px",
                          }}
                          onClick={() =>
                            this.setState({
                              showModal: true,
                              action: "decline",
                              selectedBid: list,
                            })
                          }
                        >
                          Decline
                        </button>
                        <button
                          style={{
                            backgroundColor: "green",
                            color: "white",
                            padding: "8px",
                            borderRadius: "10px",
                          }}
                          onClick={() =>
                            this.setState({
                              showModal: true,
                              action: "approve",
                              selectedBid: list,
                            })
                          }
                        >
                          Accept
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Modal
                show={this.state.showModal}
                // size="lg"
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
                fullscreen={false}
                onHide={() => {
                  this.setState({
                    showModal: false,
                    action: "",
                  });
                }}
              >
                <ModalHeader closeButton>
                  <ModalTitle>
                    {action === "decline" ? "Decline" : "Accept"} bid made by{" "}
                    {selectedItem?.driver?.name}
                  </ModalTitle>
                </ModalHeader>
                <ModalBody>
                  <div>
                    {action === "decline" && (
                      <div>
                        <label style={{ marginBottom: "10px" }}>
                          Choose a Reason
                        </label>
                        <br />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginTop: 5,
                          }}
                        >
                          <label htmlFor="loadUnavailable">
                            Load no longer available
                          </label>
                          <input
                            type="radio"
                            id="loadUnavailable"
                            name="commentOption"
                            value="Load no longer available"
                            onChange={(e) => {
                              this.setState({ notes: e.target.value });
                            }}
                            checked={notes === "Load no longer available"}
                            style={{ width: 15, height: 15 }}
                          />
                        </div>
                        <br />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between", // Changed alignContent to justifyContent
                            alignItems: "center",
                          }}
                        >
                          <label htmlFor="bidTooHigh">
                            {" "}
                            {/* Changed marginLeft to marginRight */}
                            Bid is Too High
                          </label>
                          <input
                            type="radio"
                            id="bidTooHigh"
                            name="commentOption"
                            value="Bid is Too High"
                            onChange={(e) => {
                              this.setState({ notes: e.target.value });
                            }}
                            checked={notes === "Bid is Too High"}
                            style={{
                              width: 15,
                              height: 15,
                            }}
                          />
                        </div>

                        <br />
                      </div>
                    )}
                    <button
                      className={action === "decline" ? "btn-red" : "btn-blue"}
                      style={{ marginInline: "170px", marginTop: "30px" }}
                      onClick={() => {
                        this.setState({
                          showModal: false,
                        });
                        this.acceptDeclineBid();
                      }}
                    >
                      {action === "decline" ? "Decline" : "Accept"}
                    </button>
                  </div>
                </ModalBody>
              </Modal>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
export default BidsModal;
