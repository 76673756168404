/* eslint-disable */
import React, { useState, useEffect } from "react";
import firebase from "./../firebase/firebase";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Switch } from "@mui/material";
// CSS styles
const styles = {
  settingsScreen: {
    display: "flex",
    height: "100vh", // Full height of the screen
    overflow: "hidden", // To prevent vertical scrollbar due to height
  },
  settingsList: {
    flex: "0 0 25%", // One-fourth of the screen width
    backgroundColor: "#7A5CD1",
    padding: "20px",
    boxSizing: "border-box",
    borderRadius: "0 10px 10px 0",
  },
  settingsCard: {
    background: "#fff",
    padding: "10px",
    marginBottom: "10px",
    cursor: "pointer",
    borderRadius: "10px",
  },
  messageContainer: {
    flex: "0 0 75%",
    backgroundColor: "rgba(0,0,0,0.0)",
    padding: "20px",
    boxSizing: "border-box",
    borderRadius: "10px 0 0 10px",
    marginLeft: "10px",
    height: "95%",
  },
  sendButton: {
    padding: "10px 20px",
    backgroundColor: "#7A5CD1",
    color: "#fff",
    border: "none",
    cursor: "pointer",
    borderRadius: 10,
    marginTop: 10,
  },
  header: {
    padding: "20px",
    boxSizing: "border-box",
    borderRadius: "10px 0 0 10px",
    height: "10%",
    marginTop: -40,
  },
  Line: {
    height: "2px", // Adjust the height as per your requirement
    backgroundColor: "#7A5CD1",
    marginBottom: "10px", // Add some space below the black line if needed
  },
  ContainerWrapper: {
    flex: "0 0 75%",
    height: "95%", // Set a fixed height for the message container
    position: "relative", // Add position relative to the wrapper
    backgroundColor: "rgba(0,0,0,0.0)",
    padding: "20px",
    boxSizing: "border-box",
    borderRadius: "10px 0 0 10px",
    marginLeft: "10px",
    overflowY: "hidden", // Hide the vertical scrollbar of the wrapper
    flexDirection: "row",
  },
  input: {
    "border-width": "2px",
    "border-color": "#7A5CD1",
    borderRadius: "10px",
    fontSize: "15px",
    padding: "10px",
    marginRight: "10px",
  },
};

const Settings = () => {
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [loading, setLoading] = useState(false);
  const [locationTimer, setLocationTimer] = useState();
  const [tab, setTab] = useState("Location Timer");
  const [bidsNotification, setBidsNotification] = useState(false);
  const [mobileLogs, setMobileLogs] = useState(false);
  const [webLogs, setWebLogs] = useState(false);

  useEffect(() => {
    const docRef = firebase
      .firestore()
      .collection("driversLocation")
      .doc("trackingInterval");
    docRef.onSnapshot(
      (doc) => {
        setLoading(true);
        if (doc.exists) {
          const data = doc.data();
          const minutes = data.minute;
          setLocationTimer(minutes);
        } else {
          console.log("Document not found");
        }
        setLoading(false);
      },
      (error) => {
        console.log("Error fetching document:", error);
      }
    );

    firebase
      .firestore()
      .collection("Settings")
      .doc("Settings")
      .onSnapshot((snap) => {
        if (snap.exists) {
          setBidsNotification(snap.data().bidsNotification);
          setMobileLogs(snap.data().mobileLogs);
          setWebLogs(snap.data().webLogs);
        }
      });
  }, []);

  const handleUpdateClick = () => {
    firebase
      .firestore()
      .collection("driversLocation")
      .doc("trackingInterval")
      .set(
        {
          minute: locationTimer,
        },
        { merge: true }
      );

    alert("Location Timer Updated!");
  };

  return (
    <div style={styles.settingsScreen}>
      <div style={styles.settingsList}>
        <h2 style={{ marginInline: "20%", color: "white", marginBottom: 20 }}>
          Settings
        </h2>
        <div
          style={{
            ...styles.settingsCard,
            backgroundColor: tab === "Location Timer" ? "#440072" : "white",
            color: tab === "Location Timer" ? "white" : "#440072",
          }}
          onClick={() => setTab("Location Timer")}
        >
          <h5 style={{ cursor: "pointer" }}>{"Location Timer"}</h5>
        </div>
        <div
          style={{
            ...styles.settingsCard,
            backgroundColor: tab === "Notifications" ? "#440072" : "white",
            color: tab === "Notifications" ? "white" : "#440072",
          }}
          onClick={() => setTab("Notifications")}
        >
          <h5 style={{ cursor: "pointer" }}>{"Notifcations"}</h5>
        </div>
        <div
          style={{
            ...styles.settingsCard,
            backgroundColor: tab === "Activity Logs" ? "#440072" : "white",
            color: tab === "Activity Logs" ? "white" : "#440072",
          }}
          onClick={() => setTab("Activity Logs")}
        >
          <h5 style={{ cursor: "pointer" }}>{"Activity Logs"}</h5>
        </div>
      </div>
      <div style={styles.messageContainer}>
        <div style={{ ...styles.header, marginBottom: 0 }}>
          <h2 style={{ color: "#7A5CD1" }}>{tab}</h2>
          <div style={styles.Line} />
        </div>
        {loading ? (
          <CircularProgress
            size={80}
            style={{ alignSelf: "absolute", margin: "40%" }}
          />
        ) : tab === "Location Timer" ? (
          <div style={styles.ContainerWrapper}>
            <text
              style={{
                fontSize: 20,
                marginInlineEnd: 10,
                fontWeight: "bold",
                color: "#7A5CD1",
              }}
            >
              Tracking Interval:
            </text>
            <input
              type="text"
              value={locationTimer}
              onChange={(e) => setLocationTimer(e.target.value)}
              style={styles.input}
              placeholder="Enter Title..."
            />
            <button style={styles.sendButton} onClick={handleUpdateClick}>
              Update
            </button>
          </div>
        ) : tab === "Notifications" ? (
          <div style={styles.ContainerWrapper}>
            <div style={{ display: "flex", alignItems: "baseline" }}>
              <text
                style={{
                  fontSize: 20,
                  marginInlineEnd: 10,
                  fontWeight: "bold",
                  color: "#7A5CD1",
                }}
              >
                Bids Email Notifcation:
              </text>
              <Switch
                checked={bidsNotification}
                onChange={(e) => {
                  setBidsNotification(!bidsNotification);
                  firebase
                    .firestore()
                    .collection("Settings")
                    .doc("Settings")
                    .set(
                      {
                        bidsNotification: !bidsNotification,
                      },
                      {
                        merge: true,
                      }
                    );
                }}
              />
            </div>
          </div>
        ) : (
          <div style={styles.ContainerWrapper}>
            <div style={{ display: "flex", alignItems: "baseline" }}>
              <text
                style={{
                  fontSize: 20,
                  marginInlineEnd: 33,
                  fontWeight: "bold",
                  color: "#7A5CD1",
                }}
              >
                Web Logs:
              </text>
              <Switch
                checked={webLogs}
                onChange={(e) => {
                  setWebLogs(!webLogs);
                  firebase
                    .firestore()
                    .collection("Settings")
                    .doc("Settings")
                    .set(
                      {
                        webLogs: !webLogs,
                      },
                      {
                        merge: true,
                      }
                    );
                }}
              />
            </div>
            <div style={{ display: "flex", alignItems: "baseline" }}>
              <text
                style={{
                  fontSize: 20,
                  marginInlineEnd: 10,
                  fontWeight: "bold",
                  color: "#7A5CD1",
                }}
              >
                Mobile Logs:
              </text>
              <Switch
                checked={mobileLogs}
                onChange={(e) => {
                  setMobileLogs(!mobileLogs);
                  firebase
                    .firestore()
                    .collection("Settings")
                    .doc("Settings")
                    .set(
                      {
                        mobileLogs: !mobileLogs,
                      },
                      {
                        merge: true,
                      }
                    );
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Settings;
