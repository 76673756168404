import React, { Component } from "react";
import { Button, Modal, ModalBody, ModalTitle } from "react-bootstrap";
import firebase from "./../firebase/firebase";
import ModalHeader from "react-bootstrap/esm/ModalHeader";

import "react-datepicker/dist/react-datepicker.css";
import SendPushNotification from "./../firebase/SendPushNotification";
import { Dropdown } from "react-bootstrap";
import { logActivity, name } from "../helper/ActivityLogger";
class Assign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      selected: "",
      alreadyAssign: true,
    };
  }
  guidGenerator = () => {
    var S4 = function () {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };
    return (
      S4() +
      S4() +
      "-" +
      S4() +
      "-" +
      S4() +
      "-" +
      S4() +
      "-" +
      S4() +
      S4() +
      S4()
    );
  };
  onSubmit = () => {
    const { selected } = this.state;
    const { selectedItem } = this.props;
    let createNew = true;
    if (createNew) {
      firebase
        .firestore()
        .collection("searchRequests")
        .doc(selectedItem.pathId)
        .set(
          {
            dispatcher: {
              accountNumber: selected?.accountNumber,
              email: selected?.email,
              id: selected?.id,
              name: selected?.name,
              role: selected?.role,
            },
          },
          { merge: true }
        );
    }
    logActivity(
      `${name} assigned a dispatcher [${selected.name}] to Load [id: ${selectedItem.pathId}]`,
      "Search Requests"
    );
    alert("Dispatcher assigned!");
    this.props.onHide();
  };

  getAllDispatcher = async () => {
    let role = await localStorage.getItem("role");
    console.log("___", role);
    if (role === "Dispatcher") {
      let temp = [];
      let name = await localStorage.getItem("isLogin");
      let id = await localStorage.getItem("id");
      temp.push({
        id: id,
        name: name,
      });
      this.setState({
        list: temp,
        selected: temp[0],
      });
    } else {
      firebase
        .firestore()
        .collection("dispatchers")
        .where("role", "in", ["Dispatcher", "Admin"])
        .onSnapshot((snap) => {
          let temp = [];
          snap.docs.forEach((a) => {
            temp.push(a.data());
          });
          this.setState({
            list: temp,
            selected: temp[0],
          });
        });
    }
  };
  componentDidMount() {
    const { selectedItem } = this.props;
    this.getAllDispatcher();
    if (selectedItem?.dispatcher) {
      this.setState({
        alreadyAssign: true,
      });
    } else {
      this.setState({
        alreadyAssign: false,
      });
    }
  }
  render() {
    const { list, selected } = this.state;
    const { selectedItem } = this.props;
    return (
      <div>
        <Modal
          show={this.props.showModal}
          // size="lg"
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
          fullscreen={true}
          onHide={() => {
            this.setState({
              alreadyAssign: true,
            });
            this.props.onHide();
          }}
        >
          <ModalHeader closeButton>
            <ModalTitle>
              Assign Dispatcher to {selectedItem?.driver?.name}
            </ModalTitle>
          </ModalHeader>
          <ModalBody>
            <div>
              {selectedItem?.dispatcher ? (
                <div>
                  <h4
                    style={{
                      backgroundColor: "red",
                    }}
                  >
                    Dispatcher is already assign to {selectedItem.driver?.name}
                  </h4>
                  <h6>
                    Do you want to assign again? This will clean the messages
                    and can't be undo.
                  </h6>
                </div>
              ) : (
                <div />
              )}
              <>
                <h4>Select Dispatcher to assign:</h4>
                <Dropdown
                  style={{
                    height: "10%",
                    width: "40%",
                    backgroundColor: "rgba(0,0,0,0.04)",
                    borderRadius: 10,
                  }}
                >
                  <Dropdown.Toggle
                    style={{
                      height: "100%",
                      width: "100%",
                    }}
                    variant="light-Info"
                    id="dropdown"
                  >
                    {selected?.name}
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    style={
                      {
                        // height:'100%',
                        // width:'100%',
                      }
                    }
                  >
                    {list.map((user) => {
                      return (
                        <Dropdown.Item
                          style={{
                            height: "100%",
                            width: "100%",
                          }}
                          onClick={async () => {
                            this.setState({
                              selected: user,
                            });
                          }}
                        >
                          {user?.name}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>

                <div
                  style={{
                    margin: 50,
                  }}
                >
                  <Button
                    type="submit"
                    value="Confirm"
                    onClick={async () => {
                      if (list.length > 0) {
                        var title = "DISPATCHER ASSIGNED";
                        var body =
                          "Dispatcher has been assigned to your current load or search";
                        var notificationData = await firebase
                          .firestore()
                          .collection("templates")
                          .doc("Dispatcher")
                          .get();
                        if (notificationData.exists) {
                          notificationData = notificationData.data();
                          body = notificationData.body;
                          title = notificationData.title;
                        }
                        SendPushNotification(
                          title,
                          `Dear ${selectedItem.driver?.name}! ${body}`,
                          selectedItem.driver?.id
                        );
                        if (selectedItem.driver.admin) {
                          SendPushNotification(
                            title,
                            `${body}`,
                            selectedItem.driver?.admin
                          );
                        }
                        this.onSubmit();
                      } else {
                        alert("No Dispatcher in the list!");
                      }
                    }}
                    text="Assign"
                  >
                    Assign
                  </Button>
                </div>
              </>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
export default Assign;
