import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  Container,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Dialog,
  DialogTitle,
  DialogContent,
  Slide,
  TextField,
  DialogActions,
} from "@mui/material";

import firebase from "./../firebase/firebase";
import swal from "sweetalert";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Credits = () => {
  const [open, setOpen] = React.useState(false);
  const [mode, setMode] = React.useState(0);
  const [credits, setCredits] = useState([]);
  const [userId, setUserId] = useState("");
  const [values, setValues] = useState({
    credit: 0,
    amount: 0,
  });

  const getData = () => {
    const ref = firebase
      .firestore()
      .collection("plans")
      .orderBy("amount", "asc");

    ref.onSnapshot((snapshot) => {
      setCredits([]);

      snapshot.forEach((ele) => {
        setCredits((pre) => [...pre, ele.data()]);
      });
    });
  };

  const editCredit = (i, index) => {
    setOpen(true);
    setMode(1);

    setUserId(i);

    setValues((pre) => {
      return {
        ...pre,
        credit: credits[index].credits,
        amount: credits[index].amount,
      };
    });
  };

  const deleteCredit = (i) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this imaginary file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        firebase
          .firestore()
          .collection("plans")
          .doc(i)
          .delete()
          .then(() => {
            swal("Poof! Your data has been deleted!", {
              icon: "success",
            });
          })
          .catch((err) => {
            swal(err.message);
          });
      }
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const addCredit = () => {
    if (values.credit <= 0) {
      swal("Enter credits");
      return;
    }
    if (values.amount <= 0) {
      swal("Enter amount");
      return;
    }

    const key = firebase.firestore().collection("plans").doc().id;

    firebase
      .firestore()
      .collection("plans")
      .doc(key)
      .set({
        id: key,
        credits: values.credit,
        amount: values.amount,
      })
      .then(() => {
        setOpen(false);
        swal("Credit added Successfully");
      })
      .catch((err) => {
        swal(err.message);
      });
  };

  const handleChange = (e) => {
    setValues((pre) => {
      return {
        ...pre,
        [e.target.name]: e.target.value,
      };
    });
  };

  const updateCredits = () => {
    if (values.credit <= 0) {
      swal("Enter credits");
      return;
    }
    if (values.amount <= 0) {
      swal("Enter amount");
      return;
    }

    firebase
      .firestore()
      .collection("plans")
      .doc(userId)
      .update({
        credits: values.credit,
        amount: values.amount,
      })
      .then(() => {
        setOpen(false);
        swal("Credit Updated Successfully");
      })
      .catch((err) => {
        swal(err.message);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Container>
      <Box sx={{ textAlign: "right" }}>
        <Button
          variant="contained"
          onClick={() => {
            setOpen(true);
            setMode(0);
          }}
        >
          Add credits offers
        </Button>
        <TableContainer component={Paper} sx={{ marginTop: "20px" }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell align="right">Name</TableCell>
                <TableCell align="right">Credits</TableCell>
                <TableCell align="right">Amount</TableCell>
                <TableCell align="right">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {credits.map((val, ind) => (
                <TableRow
                  key={ind}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {ind + 1}
                  </TableCell>
                  <TableCell align="right">Plane {ind + 1}</TableCell>
                  <TableCell align="right">{val.credits}</TableCell>
                  <TableCell align="right">{val.amount}</TableCell>
                  <TableCell align="right">
                    <Button
                      variant="contained"
                      onClick={() => editCredit(`${val.id}`, ind)}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      sx={{ marginLeft: "10px" }}
                      onClick={() => deleteCredit(`${val.id}`)}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          {mode === 0 ? "Add" : "Update"} Custom Credits
        </DialogTitle>
        <DialogContent>
          <TextField
            label="Add Credits"
            variant="standard"
            type="number"
            name="credit"
            sx={{ marginBottom: "10px" }}
            fullWidth
            value={values.credit}
            onChange={handleChange}
          />
          <TextField
            label="Add Amount"
            variant="standard"
            type="number"
            sx={{ marginBottom: "10px" }}
            fullWidth
            value={values.amount}
            name="amount"
            onChange={handleChange}
          />

          {/* <Button fullWidth variant="contained">Add Credits</Button> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="error">
            Close
          </Button>
          {mode === 0 ? (
            <Button variant="contained" onClick={addCredit}>
              Add
            </Button>
          ) : (
            <Button variant="contained" onClick={updateCredits}>
              Update
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Credits;
