/* eslint-disable */
import { Switch } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalTitle } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import firebase from "./../firebase/firebase";
import { MultiSelect } from "react-multi-select-component";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import { States, allCountries } from "./States";
import DatePicker from "react-datepicker";
import axios from "axios";
import { name as userName, logActivity } from "../helper/ActivityLogger";
import emailNotifier from "../helper/emailNotifier";
import SendPushNotification from "../firebase/SendPushNotification";

export default function EditTeamModal({
  showModal = false,
  showEditModal = false,
  showAddModal = false,
  onHide = () => {},
  selectedItem = {},
}) {
  const items = [
    // name key is must. It is to show the text in front
    { id: 1, name: "Dryvan" },
    { id: 2, name: "Reefer" },
    { id: 3, name: "Power-Only" },
    { id: 4, name: "Box Truck" },
    { id: 5, name: "Hotshot" },
    { id: 6, name: "Cargo Van" },
    { id: 7, name: "Flatbed" },
  ];
  const [name, setName] = useState(selectedItem?.name);
  const [email, setEmail] = useState(selectedItem?.email);
  const [phone, setPhone] = useState(selectedItem?.phone);
  const [companyName, setCompanyName] = useState(selectedItem?.companyName);
  const [mcNumber, setMcNumber] = useState(
    selectedItem?.mcNumber ? selectedItem?.mcNumber : ""
  );
  const [dotNumber, setDotNumber] = useState(
    selectedItem?.dotNumber ? selectedItem?.dotNumber : ""
  );
  const [endorsements, setEndorsements] = useState(
    selectedItem?.endorsements ? selectedItem.endorsements : ""
  );
  const [insuranceInfo, setInsuranceInfo] = useState(
    selectedItem?.InsuranceInfo
  );
  const [factoryInfo, setFactoryInfo] = useState(selectedItem?.FactoryInfo);
  const [companyAddress, setCompanyAddress] = useState(
    selectedItem?.CompanyAdress
  );
  const [assets, setAssets] = useState(selectedItem?.Assets);
  const [selectedStates, setSelectedStates] = useState(
    selectedItem?.selectedStates
  );
  const [selectedOptionsState, setSelectedOptionsState] = useState([]);
  const [isVerified, setIsVerified] = useState(selectedItem?.isVerified);
  const [isAgreementFilled, setAgreementFilled] = useState(
    selectedItem?.isAgreementFilled ? selectedItem?.isAgreementFilled : false
  );
  const [adminBypass, setAdminBypass] = useState(
    selectedItem?.adminBypass ? selectedItem?.adminBypass : false
  );
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [companyNameError, setCompanyNameError] = useState("");
  const [mcNumberError, setMcNumberError] = useState("");
  const [dotNumberError, setDotNumberError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [editIndex, setEditIndex] = useState(-1);
  const [selectedTruckType, setSelectedTruckType] = useState({
    name: "Dryvan",
  });
  const [liftGate, setLiftGate] = useState("");
  const [startingDate, setStartingDate] = useState(selectedItem?.startingDate);
  const [showStreet, setShowStreet] = useState(false);
  const [teamId, setTeamId] = useState("");
  const getAddressLocation = async (item) => {
    try {
      geocodeByPlaceId(item)
        .then((results) => {
          const addressComponents = results[0].address_components;
          const city = addressComponents.find((component) =>
            component.types.includes("locality")
          )?.long_name;
          const zipCode = addressComponents.find((component) =>
            component.types.includes("postal_code")
          )?.long_name;
          const state = addressComponents.find((component) =>
            component.types.includes("administrative_area_level_1")
          )?.long_name;
          const country = addressComponents.find((component) =>
            component.types.includes("country")
          )?.long_name;

          const filteredComponents = addressComponents.filter(
            (component) =>
              !component.types.includes("locality") &&
              !component.types.includes("postal_code") &&
              !component.types.includes("administrative_area_level_1") &&
              !component.types.includes("country")
          );

          // Join remaining components to form the street address
          const street = filteredComponents
            .map((component) => component.long_name)
            .join(", ");

          setCompanyAddress((prevAddress) => ({
            ...prevAddress,
            streetAdress: street,
          }));

          if (city) {
            setCompanyAddress((prevAddress) => ({
              ...prevAddress,
              city: city,
            }));
          }
          if (zipCode) {
            setCompanyAddress((prevAddress) => ({
              ...prevAddress,
              zipCode: zipCode,
            }));
          }
          if (state) {
            setCompanyAddress((prevAddress) => ({
              ...prevAddress,
              state: state,
            }));
          }
          if (country) {
            setCompanyAddress((prevAddress) => ({
              ...prevAddress,
              country: country,
            }));
          }
        })
        .catch((error) => console.error(error));
    } catch (error) {
      console.error("Error fetching place details:", error);
    }
  };

  useEffect(() => {
    setName(selectedItem?.name);
    setEmail(selectedItem?.email);
    setPhone(selectedItem?.phone);
    setCompanyName(selectedItem?.companyName);
    setMcNumber(selectedItem?.mcNumber);
    setDotNumber(selectedItem?.dotNumber);
    setIsVerified(selectedItem?.isVerified);
    setEndorsements(selectedItem?.endorsements);
    setAssets(selectedItem?.Assets);
    setCompanyAddress(selectedItem?.CompanyAdress);
    setFactoryInfo(selectedItem?.FactoryInfo);
    setInsuranceInfo(selectedItem?.InsuranceInfo);
    setSelectedStates(selectedItem?.selectedStates);
    setStartingDate(selectedItem?.startingDate);
    setAgreementFilled(selectedItem?.isAgreementFilled);
    setAdminBypass(selectedItem?.adminBypass);
  }, [selectedItem]);

  useEffect(() => {
    if (selectedStates) {
      const selectedOptions = selectedStates.map((state) => {
        const option = States.find((option) => option.label === state);
        return option ? option : { label: state, value: state.toLowerCase() };
      });
      setSelectedOptionsState(selectedOptions);
    }
  }, [selectedStates]);

  useEffect(() => {
    if (companyAddress?.streetAdress) {
      setShowStreet(false);
    } else {
      setShowStreet(true);
    }
  }, [companyAddress?.streetAdress]);

  useEffect(() => {
    if (startingDate?.seconds) {
      const timestamp = startingDate;
      const date = new Date(timestamp.seconds * 1000);
      setStartingDate(date);
      console.log(startingDate);
    } else {
    }
  }, [startingDate]);

  const updateTeam = async () => {
    try {
      if (validate()) {
        const userRef = await firebase
          .firestore()
          .collection("teams")
          .doc(selectedItem.id);

        await userRef.update({
          ...selectedItem,
          name,
          phone,
          companyName,
          mcNumber,
          dotNumber,
          endorsements,
          CompanyAdress: companyAddress,
          Assets: assets,
          FactoryInfo: factoryInfo,
          InsuranceInfo: insuranceInfo,
          selectedStates,
          startingDate,
          isVerified,
          isAgreementFilled,
          adminBypass,
        });

        if (isVerified && !selectedItem.isVerified) {
          emailNotifier(
            "Verification completed! You're now ready to GoHaul!",
            "Your account has been successfully verified! You're now ready to GoHaul!%0D%0A%0D%0A" +
              "Company Name: " +
              companyName +
              "%0D%0A" +
              "Name: " +
              name +
              "%0D%0A%0D%0A" +
              "Subscription Details: %0D%0A" +
              "Price: $" +
              selectedItem?.subscriptionPlan?.price +
              "%0D%0A" +
              "Credits: " +
              selectedItem?.subscriptionPlan?.credits +
              "%0D%0A" +
              "Percentage Per Load: " +
              selectedItem?.subscriptionPlan?.percentagePerLoad +
              "%0D%0A" +
              "Number Of Drivers: " +
              selectedItem?.subscriptionPlan?.numberOfDrivers +
              "%0D%0A" +
              "Subscription Period: " +
              selectedItem?.subscriptionPlan?.subscriptionTime +
              " Days%0D%0A" +
              "%0D%0A" +
              "Expiration Date: " +
              selectedItem?.subscriptionPlan?.expirationDate
                ?.toDate()
                .toDateString(),
            email
          );
          SendPushNotification(
            "Verification completed!",
            "Your account has been successfully verified! You're now ready to GoHaul!",
            selectedItem.id
          );
        } else if (!isVerified && selectedItem.isVerified) {
          emailNotifier(
            "Your GoHaul account is no longer active!",
            "Your GoHaul account is no longer active!%0D%0A%0D%0A" +
              "Company Name: " +
              companyName +
              "%0D%0A" +
              "Name: " +
              name,
            email
          );
          SendPushNotification(
            "Verification Deactivated!",
            "Your GoHaul account is no longer active!",
            selectedItem.id
          );
          console.log("AAAAAAAAAAAAAAAAAAAAAa", selectedItem.id);
        }
        alert("Team Updated Successfull");
        logActivity(
          `${userName} updated details of team [name: ${companyName}]`,
          "Search Requests"
        );
        onHide();
      } else {
        alert("Fill all fields first!");
        if (assets?.trucks?.length <= 0) {
          alert("Add atleast one truck!");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const validate = () => {
    setNameError(name ? "" : "required");
    setEmailError(email ? "" : "required");
    setPhoneError(phone ? "" : "required");
    setCompanyNameError(companyName ? "" : "required");
    setMcNumberError(mcNumber ? "" : "required");
    setDotNumberError(dotNumber ? "" : "required");
    setPasswordError(password ? "" : "required");

    const values =
      name &&
      email &&
      phone &&
      companyName &&
      mcNumber &&
      dotNumber &&
      endorsements &&
      companyAddress?.streetAdress &&
      companyAddress?.city &&
      companyAddress?.state &&
      companyAddress?.country &&
      companyAddress?.zipCode &&
      assets?.NumberOfDrivers &&
      assets?.trucks.length > 0 &&
      selectedStates.length > 0 &&
      insuranceInfo?.companyName &&
      insuranceInfo?.email;
    if (!values) {
      if (!values) {
        console.log("Condition is false. Checking individual conditions...");

        if (!name) {
          console.log("name is false");
        }

        if (!email) {
          console.log("email is false");
        }

        if (!phone) {
          console.log("phone is false");
        }

        if (!companyName) {
          console.log("companyName is false");
        }

        if (!mcNumber) {
          console.log("mcNumber is false");
        }

        if (!dotNumber) {
          console.log("dotNumber is false");
        }

        if (!endorsements) {
          console.log("endorsements is false");
        }

        if (!companyAddress?.streetAdress) {
          console.log("companyAddress streetAdress is false");
        }

        if (!companyAddress?.city) {
          console.log("companyAddress city is false");
        }

        if (!companyAddress?.state) {
          console.log("companyAddress state is false");
        }

        if (!companyAddress?.country) {
          console.log("companyAddress country is false");
        }

        if (!companyAddress?.zipCode) {
          console.log("companyAddress zipCode is false");
        }

        if (!assets?.NumberOfDrivers) {
          console.log("assets NumberOfDrivers is false");
        }

        if (!(assets?.trucks?.length > 0)) {
          console.log("assets trucks length is false");
        }

        if (!(selectedStates?.length > 0)) {
          console.log("selectedStates length is false");
        }

        if (!insuranceInfo?.companyName) {
          console.log("insuranceInfo companyName is false");
        }

        // if (!insuranceInfo?.contactName) {
        //   console.log("insuranceInfo contactName is false");
        // }

        if (!insuranceInfo?.email) {
          console.log("insuranceInfo email is false");
        }

        // if (!insuranceInfo?.phoneNumber) {
        //   console.log("insuranceInfo phoneNumber is false");
        // }
      }
    }
    const valuesError =
      nameError &&
      emailError &&
      phoneError &&
      companyNameError &&
      mcNumberError &&
      dotNumberError;

    return Boolean(values && !valuesError);
  };

  const addTeam = async () => {
    const userRef = await firebase.firestore().collection("teams");
    if (validate()) {
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(async (response) => {
          await userRef.doc(response.user.uid).set({
            email: response.user.email,
            id: response.user.uid,
            name: name,
            wallet: 0,
            credit: 0,
            phone: phone,
            mcNumber: mcNumber,
            dotNumber: dotNumber,
            companyName: companyName,
            CompanyAdress: companyAddress,
            selectedStates: selectedStates,
            startingDate: startingDate,
            endorsements: endorsements,
            Assets: assets,
            FactoryInfo: factoryInfo,
            InsuranceInfo: insuranceInfo,
            role: "O/O",
            isAcceptBid: true,
            isAddCard: true,
            isMakePayment: true,
            isSearch: true,
            admin: null,
            isVerified: false,
            isAgreementFilled: false,
            adminBypass: false,
            // fcmToken: fcmToken1
          });
          alert("Team added Successfull");
          {
            emailNotifier(
              "You've successfully signed up with GoHaul!",
              `Hey ${companyName}! %0D%0A%0D%0AYour account in now registered.%0D%0A%0D%0ADetails: %0D%0AOwner Name: ${name}%0D%0ACompany Name: ${companyName}%0D%0AEmail: ${email}%0D%0APhone: ${phone}%0D%0AMC: ${mcNumber}%0D%0ADOT: ${dotNumber}`,
              email
            );
          }
          onHide();
        })
        .catch((error) => {
          alert(error.message);
          console.log(error);
        });
    }
  };

  const handleTruckDelete = (targetIndex) => {
    const updatedTrucks = assets.trucks.filter(
      (truck, index) => index !== targetIndex
    );

    // Update the state with the modified 'trucks' array
    setAssets({
      ...assets,
      trucks: updatedTrucks,
    });
  };

  const handleStatesChange = (selectedOptions) => {
    setSelectedOptionsState(selectedOptions);
    // Update the selectedStates array with the newly selected states
    const newSelectedStates = selectedOptions.map((option) => option.label);
    setSelectedStates(newSelectedStates);

    console.log(selectedStates);
  };

  function formatMaxHaulingCapacity(capacity) {
    if (capacity >= 1000) {
      return capacity.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return capacity.toString();
    }
  }

  return (
    <div>
      <Modal
        show={showModal}
        // size="lg"
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        fullscreen={true}
        onHide={() => {
          onHide();
        }}
      >
        <ModalHeader closeButton>
          <ModalTitle>
            {showEditModal ? "EDIT TEAM" : "ADD NEW TEAM"}
          </ModalTitle>
        </ModalHeader>
        <ModalBody>
          <div className="offer-f">
            <div className="f-group">
              <label>Team Name</label>
              <br />
              <input
                type="text"
                value={companyName}
                onChange={(e) => {
                  setCompanyName(e.target.value);
                }}
              />
              {companyNameError && (
                <p style={{ color: "red", textAlign: "right" }}>
                  {companyNameError}
                </p>
              )}
            </div>
            <div className="f-group">
              <label>Owner Name</label>
              <br />
              <input
                type="text"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
              {nameError && (
                <p style={{ color: "red", textAlign: "right" }}>{nameError}</p>
              )}
            </div>
            <div className="f-group">
              <label>Email</label>
              <br />
              <input
                type="email"
                value={email}
                // contentEditable={showAddModal && !showEditModal}
                disabled={!showAddModal}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              {emailError && (
                <p style={{ color: "red", textAlign: "right" }}>{emailError}</p>
              )}
            </div>
            <div className="f-group">
              <label>Phone</label>
              <br />
              <input
                type="text"
                value={phone}
                onChange={(e) => {
                  setPhone(e.target.value);
                }}
              />
              {phoneError && (
                <p style={{ color: "red", textAlign: "right" }}>{phoneError}</p>
              )}
            </div>
            {/* <div className="f-group">
                            <label >Company Name</label>
                            <br />
                            <input
                                type="text"
                                value={companyName}
                                onChange={(e) => {
                                    setCompanyName(e.target.value)
                                }}
                            />
                        </div> */}
            <div className="f-group">
              <label>MC Number</label>
              <br />
              <input
                type="text"
                value={mcNumber}
                onChange={(e) => {
                  setMcNumber(e.target.value);
                }}
              />
              {mcNumberError && (
                <p style={{ color: "red", textAlign: "right" }}>
                  {mcNumberError}
                </p>
              )}
            </div>
            <div className="f-group">
              <label>DOT Number</label>
              <br />
              <input
                type="text"
                value={dotNumber}
                onChange={(e) => {
                  setDotNumber(e.target.value);
                }}
              />
              {dotNumberError && (
                <p style={{ color: "red", textAlign: "right" }}>
                  {dotNumberError}
                </p>
              )}
            </div>
            {showAddModal && (
              <div className="f-group">
                <label>Password</label>
                <br />
                <input
                  type="password"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
                {passwordError && (
                  <p style={{ color: "red", textAlign: "right" }}>
                    {passwordError}
                  </p>
                )}
              </div>
            )}
          </div>
          <div style={{ display: "block" }} className="f-group">
            <h3>Company Address</h3>
          </div>
          <div className="offer-f">
            <div className="f-group">
              <label>Street Address</label>
              <br />
              {showStreet ? (
                <GooglePlacesAutocomplete
                  apiKey="AIzaSyCjysO3MF1vStj7BR3IRTAOOvLa3O6bark"
                  selectProps={{
                    // inputValue: (companyAddress?.streetAdress ? companyAddress?.streetAdress : ""),
                    defaultInputValue: companyAddress?.streetAdress
                      ? companyAddress?.streetAdress
                      : "",
                    defaultValue: companyAddress?.streetAdress
                      ? companyAddress?.streetAdress
                      : "",
                    defaultChecked: true,
                    // onInputChange: (text)=>{
                    //   if (text!==""){
                    //     setCompanyAddress(prevAddress => ({
                    //       ...prevAddress,
                    //       streetAdress: text,
                    //     }));
                    //   }
                    // },
                    onChange: async (text) => {
                      await getAddressLocation(text?.value.place_id);
                    },
                  }}
                  onLoadFailed={(err) => {
                    console.log(err);
                  }}
                />
              ) : (
                <>
                  <input
                    type="text"
                    value={companyAddress?.streetAdress}
                    disabled={true}
                  />
                  <button
                    style={{
                      backgroundColor: "green",
                      borderRadius: 10,
                      margin: 10,
                      marginLeft: "80%",
                      width: "20%",
                      color: "white",
                    }}
                    onClick={() => {
                      setShowStreet(true);
                    }}
                  >
                    Edit
                  </button>
                </>
              )}
            </div>
            <div className="f-group">
              <label>City</label>
              <br />
              <input
                type="text"
                value={companyAddress?.city ? companyAddress?.city : ""}
                onChange={(e) => {
                  setCompanyAddress((prevAddress) => ({
                    ...prevAddress,
                    city: e.target.value,
                  }));
                }}
              />
              {/* {dotNumberError && (
                <p style={{ color: "red", textAlign: "right" }}>
                  {dotNumberError}
                </p>
              )} */}
            </div>
            <div className="f-group">
              <label>State</label>
              <br />
              <input
                type="text"
                value={companyAddress?.state ? companyAddress?.state : ""}
                onChange={(e) => {
                  setCompanyAddress((prevAddress) => ({
                    ...prevAddress,
                    state: e.target.value,
                  }));
                }}
              />
              {/* {dotNumberError && (
                <p style={{ color: "red", textAlign: "right" }}>
                  {dotNumberError}
                </p>
              )} */}
            </div>
            <div className="f-group">
              <label>ZIP</label>
              <br />
              <input
                type="text"
                value={companyAddress?.zipCode ? companyAddress?.zipCode : ""}
                onChange={(e) => {
                  setCompanyAddress((prevAddress) => ({
                    ...prevAddress,
                    zipCode: e.target.value,
                  }));
                }}
              />
              {/* {dotNumberError && (
                <p style={{ color: "red", textAlign: "right" }}>
                  {dotNumberError}
                </p>
              )} */}
            </div>
            <div className="f-group">
              <label>Country</label>
              <br />
              <select
                style={{ marginTop: 10 }}
                value={companyAddress?.country ? companyAddress?.country : ""}
                onChange={(e) => {
                  setCompanyAddress((prevAddress) => ({
                    ...prevAddress,
                    country: e.target.value,
                  }));
                }}
              >
                {allCountries.map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
              </select>
              {/* {dotNumberError && (
                <p style={{ color: "red", textAlign: "right" }}>
                  {dotNumberError}
                </p>
              )} */}
            </div>
          </div>
          <div style={{ display: "block" }} className="f-group">
            <h3>Insurance Info</h3>
          </div>
          <div className="offer-f">
            <div className="f-group">
              <label>Insurance Company</label>
              <br />
              <input
                type="text"
                value={
                  insuranceInfo?.companyName ? insuranceInfo?.companyName : ""
                }
                onChange={(e) => {
                  setInsuranceInfo((prevInfo) => ({
                    ...prevInfo,
                    companyName: e.target.value,
                  }));
                }}
              />
              {/* {dotNumberError && (
                <p style={{ color: "red", textAlign: "right" }}>
                  {dotNumberError}
                </p>
              )} */}
            </div>
            <div className="f-group">
              <label>Contact Name</label>
              <br />
              <input
                type="text"
                value={
                  insuranceInfo?.contactName ? insuranceInfo?.contactName : ""
                }
                onChange={(e) => {
                  setInsuranceInfo((prevInfo) => ({
                    ...prevInfo,
                    contactName: e.target.value,
                  }));
                }}
              />
              {/* {dotNumberError && (
                <p style={{ color: "red", textAlign: "right" }}>
                  {dotNumberError}
                </p>
              )} */}
            </div>
            <div className="f-group">
              <label>Email</label>
              <br />
              <input
                type="text"
                value={insuranceInfo?.email ? insuranceInfo?.email : ""}
                onChange={(e) => {
                  setInsuranceInfo((prevInfo) => ({
                    ...prevInfo,
                    email: e.target.value,
                  }));
                }}
              />
              {/* {dotNumberError && (
                <p style={{ color: "red", textAlign: "right" }}>
                  {dotNumberError}
                </p>
              )} */}
            </div>
            <div className="f-group">
              <label>Phone</label>
              <br />
              <input
                type="text"
                value={
                  insuranceInfo?.phoneNumber ? insuranceInfo?.phoneNumber : ""
                }
                onChange={(e) => {
                  setInsuranceInfo((prevAddress) => ({
                    ...prevAddress,
                    phoneNumber: e.target.value,
                  }));
                }}
              />
              {/* {dotNumberError && (
                <p style={{ color: "red", textAlign: "right" }}>
                  {dotNumberError}
                </p>
              )} */}
            </div>
          </div>
          <div style={{ display: "block" }} className="f-group">
            <h3>Factory Info</h3>
          </div>
          <div className="offer-f">
            <div className="f-group">
              <label>Company Name</label>
              <br />
              <input
                type="text"
                value={factoryInfo?.companyName ? factoryInfo?.companyName : ""}
                onChange={(e) => {
                  setFactoryInfo((prevInfo) => ({
                    ...prevInfo,
                    companyName: e.target.value,
                  }));
                }}
              />
              {/* {dotNumberError && (
                <p style={{ color: "red", textAlign: "right" }}>
                  {dotNumberError}
                </p>
              )} */}
            </div>
            <div className="f-group">
              <label>Website</label>
              <br />
              <input
                type="text"
                value={factoryInfo?.website ? factoryInfo?.website : ""}
                onChange={(e) => {
                  setFactoryInfo((prevInfo) => ({
                    ...prevInfo,
                    website: e.target.value,
                  }));
                }}
              />
              {/* {dotNumberError && (
                <p style={{ color: "red", textAlign: "right" }}>
                  {dotNumberError}
                </p>
              )} */}
            </div>
            <div className="f-group">
              <label>Email</label>
              <br />
              <input
                type="text"
                value={factoryInfo?.email ? factoryInfo?.email : ""}
                onChange={(e) => {
                  setFactoryInfo((prevInfo) => ({
                    ...prevInfo,
                    email: e.target.value,
                  }));
                }}
              />
              {/* {dotNumberError && (
                <p style={{ color: "red", textAlign: "right" }}>
                  {dotNumberError}
                </p>
              )} */}
            </div>
            <div className="f-group">
              <label>Phone</label>
              <br />
              <input
                type="text"
                value={factoryInfo?.phoneNumber ? factoryInfo?.phoneNumber : ""}
                onChange={(e) => {
                  setFactoryInfo((prevAddress) => ({
                    ...prevAddress,
                    phoneNumber: e.target.value,
                  }));
                }}
              />
              {/* {dotNumberError && (
                <p style={{ color: "red", textAlign: "right" }}>
                  {dotNumberError}
                </p>
              )} */}
            </div>
          </div>
          <div style={{ display: "block" }} className="f-group">
            <h3>Assets</h3>
          </div>
          <div className="offer-f">
            <div className="f-group">
              <label>Number Of Drivers</label>
              <br />
              <input
                type="text"
                value={assets?.NumberOfDrivers ? assets?.NumberOfDrivers : ""}
                onChange={(e) => {
                  setAssets((prevAssets) => ({
                    ...prevAssets,
                    NumberOfDrivers: e.target.value,
                  }));
                }}
              />
              {/* {dotNumberError && (
                <p style={{ color: "red", textAlign: "right" }}>
                  {dotNumberError}
                </p>
              )} */}
            </div>
          </div>
          <div style={{ display: "block" }} className="f-group">
            <h4>Trucks</h4>
          </div>
          {assets !== undefined && assets?.trucks !== undefined && (
            <>
              <table
                style={{
                  borderCollapse: "collapse",
                  width: "100%",
                  marginLeft: "20px",
                }}
              >
                <thead>
                  <tr>
                    <th
                      style={{
                        border: "1px solid #dddddd",
                        textAlign: "left",
                        padding: "8px",
                        backgroundColor: "#f2f2f2",
                        verticalAlign: "middle",
                      }}
                    >
                      Name
                    </th>
                    <th
                      style={{
                        border: "1px solid #dddddd",
                        textAlign: "left",
                        padding: "8px",
                        verticalAlign: "middle",
                      }}
                    >
                      Trailer Length
                    </th>
                    <th
                      style={{
                        border: "1px solid #dddddd",
                        textAlign: "left",
                        padding: "8px",
                        verticalAlign: "middle",
                      }}
                    >
                      Max Hauling Capacity
                    </th>
                    <th
                      style={{
                        border: "1px solid #dddddd",
                        textAlign: "left",
                        padding: "8px",
                        verticalAlign: "middle",
                      }}
                    >
                      Door Dimensions (Width)
                    </th>
                    <th
                      style={{
                        border: "1px solid #dddddd",
                        textAlign: "left",
                        padding: "8px",
                        verticalAlign: "middle",
                      }}
                    >
                      Door Dimensions (Height)
                    </th>
                    <th
                      style={{
                        border: "1px solid #dddddd",
                        textAlign: "left",
                        padding: "8px",
                        verticalAlign: "middle",
                      }}
                    >
                      Liftgate
                    </th>
                    <th
                      style={{
                        border: "1px solid #dddddd",
                        textAlign: "left",
                        padding: "8px",
                        verticalAlign: "middle",
                      }}
                    ></th>
                    <th
                      style={{
                        border: "1px solid #dddddd",
                        textAlign: "left",
                        padding: "8px",
                        verticalAlign: "middle",
                      }}
                    ></th>
                  </tr>
                </thead>
                <tbody>
                  {assets.trucks.map((truck, index) => (
                    <tr key={index}>
                      <td
                        style={{
                          border: "1px solid #dddddd",
                          textAlign: "left",
                          padding: "8px",
                          verticalAlign: "middle",
                        }}
                      >
                        {index === editIndex ? (
                          <select
                            value={selectedTruckType}
                            onChange={(e) => {
                              setSelectedTruckType(e.target.value);
                              const updatedTrucks = [...assets.trucks];
                              updatedTrucks[index] = {
                                ...updatedTrucks[index],
                                name: e.target.value,
                              };
                              setAssets({
                                ...assets,
                                trucks: updatedTrucks,
                              });
                            }}
                          >
                            {items.map((item) => (
                              <option key={item.id} value={item.name}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                        ) : (
                          truck.name
                        )}
                      </td>
                      <td
                        style={{
                          border: "1px solid #dddddd",
                          textAlign: "left",
                          padding: "8px",
                          verticalAlign: "middle",
                        }}
                      >
                        {index === editIndex ? (
                          <input
                            style={{ width: "60%" }}
                            type="text"
                            value={truck.trailerLength}
                            onChange={(e) => {
                              const updatedTrucks = [...assets.trucks];
                              updatedTrucks[index] = {
                                ...updatedTrucks[index],
                                trailerLength: e.target.value,
                              };
                              setAssets({
                                ...assets,
                                trucks: updatedTrucks,
                              });
                            }}
                          />
                        ) : (
                          truck.trailerLength
                        )}
                      </td>
                      <td
                        style={{
                          border: "1px solid #dddddd",
                          textAlign: "left",
                          padding: "8px",
                          verticalAlign: "middle",
                        }}
                      >
                        {index === editIndex ? (
                          <input
                            style={{ width: "60%" }}
                            type="text"
                            value={truck.MaxHualingCapacity}
                            onChange={(e) => {
                              const updatedTrucks = [...assets.trucks];
                              updatedTrucks[index] = {
                                ...updatedTrucks[index],
                                MaxHualingCapacity: e.target.value,
                              };
                              setAssets({
                                ...assets,
                                trucks: updatedTrucks,
                              });
                            }}
                          />
                        ) : (
                          formatMaxHaulingCapacity(truck.MaxHualingCapacity)
                        )}
                      </td>
                      <td
                        style={{
                          border: "1px solid #dddddd",
                          textAlign: "left",
                          padding: "8px",
                          verticalAlign: "middle",
                        }}
                      >
                        {index === editIndex ? (
                          <input
                            style={{ width: "60%" }}
                            type="text"
                            value={truck.DoorDimensionsW}
                            onChange={(e) => {
                              const updatedTrucks = [...assets.trucks];
                              updatedTrucks[index] = {
                                ...updatedTrucks[index],
                                DoorDimensionsW: e.target.value,
                              };
                              setAssets({
                                ...assets,
                                trucks: updatedTrucks,
                              });
                            }}
                          />
                        ) : (
                          truck.DoorDimensionsW
                        )}
                      </td>
                      <td
                        style={{
                          border: "1px solid #dddddd",
                          textAlign: "left",
                          padding: "8px",
                          verticalAlign: "middle",
                        }}
                      >
                        {index === editIndex ? (
                          <input
                            style={{ width: "60%" }}
                            type="text"
                            value={truck.DoorDimensionsH}
                            onChange={(e) => {
                              const updatedTrucks = [...assets.trucks];
                              updatedTrucks[index] = {
                                ...updatedTrucks[index],
                                DoorDimensionsH: e.target.value,
                              };
                              setAssets({
                                ...assets,
                                trucks: updatedTrucks,
                              });
                            }}
                          />
                        ) : (
                          truck.DoorDimensionsH
                        )}
                      </td>
                      <td
                        style={{
                          border: "1px solid #dddddd",
                          textAlign: "left",
                          padding: "8px",
                          verticalAlign: "middle",
                        }}
                      >
                        {index === editIndex ? (
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <label htmlFor={`liftgateYes-${index}`}>
                              <input
                                type="radio"
                                id={`liftgateYes-${index}`}
                                name={`liftgate-${index}`}
                                value="Yes"
                                checked={truck.liftgate === "Yes"}
                                onChange={(e) => {
                                  const updatedTrucks = [...assets.trucks];
                                  updatedTrucks[index] = {
                                    ...updatedTrucks[index],
                                    liftgate: e.target.value,
                                  };
                                  setLiftGate(e.target.value);
                                  setAssets({
                                    ...assets,
                                    trucks: updatedTrucks,
                                  });
                                }}
                              />
                              Yes
                            </label>
                            <label htmlFor={`liftgateNo-${index}`}>
                              <input
                                type="radio"
                                id={`liftgateNo-${index}`}
                                name={`liftgate-${index}`}
                                value="No"
                                checked={truck.liftgate === "No"}
                                onChange={(e) => {
                                  console.log("====>", e.target.value);
                                  const updatedTrucks = [...assets.trucks];
                                  updatedTrucks[index] = {
                                    ...updatedTrucks[index],
                                    liftgate: e.target.value,
                                  };
                                  setLiftGate(e.target.value);
                                  setAssets({
                                    ...assets,
                                    trucks: updatedTrucks,
                                  });
                                }}
                              />
                              No
                            </label>
                            <label htmlFor={`liftgateOther-${index}`}>
                              <input
                                type="radio"
                                id={`liftgateOther-${index}`}
                                name={`liftgate-${index}`}
                                value="Other"
                                checked={liftGate === "Other"}
                                onChange={(e) => {
                                  const updatedTrucks = [...assets.trucks];
                                  updatedTrucks[index] = {
                                    ...updatedTrucks[index],
                                    liftgate: e.target.value,
                                  };
                                  setLiftGate(e.target.value);
                                  setAssets({
                                    ...assets,
                                    trucks: updatedTrucks,
                                  });
                                }}
                              />
                              Other
                            </label>
                            {liftGate === "Other" && (
                              <input
                                type="text"
                                value={truck.liftGate}
                                onChange={(e) => {
                                  console.log("======>", e.target.value);
                                  const updatedTrucks = [...assets.trucks];
                                  updatedTrucks[index] = {
                                    ...updatedTrucks[index],
                                    liftgate: e.target.value,
                                  };

                                  setAssets({
                                    ...assets,
                                    trucks: updatedTrucks,
                                  });
                                }}
                              />
                            )}
                          </div>
                        ) : (
                          truck.liftgate
                        )}
                      </td>

                      <td
                        style={{
                          border: "1px solid #dddddd",
                          textAlign: "left",
                          padding: "8px",
                          verticalAlign: "middle",
                        }}
                      >
                        <button
                          onClick={() => {
                            if (editIndex == -1) {
                              setEditIndex(index);
                              setSelectedTruckType(truck.name);
                            } else {
                              if (
                                truck.name === "" ||
                                truck.trailerLength === "" ||
                                truck.MaxHualingCapacity === "" ||
                                truck.DoorDimensionsH === "" ||
                                truck.DoorDimensionsW === "" ||
                                truck.liftgate === ""
                              ) {
                                if (!truck.name) {
                                  alert("wohoo");
                                }
                                alert("Please Fill out all the feilds first");
                              } else {
                                setEditIndex(-1);
                                setSelectedTruckType({ name: "Dryvan" });
                              }
                            }
                          }}
                          style={{ backgroundColor: "green", width: "100%" }}
                          className="btn btn-primary"
                        >
                          {editIndex !== index ? "Edit" : "Update"}
                        </button>
                      </td>
                      <td
                        style={{
                          border: "1px solid #dddddd",
                          textAlign: "left",
                          padding: "8px",
                          verticalAlign: "middle",
                        }}
                      >
                        <button
                          onClick={() => {
                            handleTruckDelete(index);
                          }}
                          style={{ backgroundColor: "red", width: "100%" }}
                          className="btn btn-primary"
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
          <button
            onClick={() => {
              var updatedTrucks;
              updatedTrucks = assets?.trucks;
              if (updatedTrucks?.length > 0) {
                if (
                  updatedTrucks[updatedTrucks.length - 1].name === "" ||
                  updatedTrucks[updatedTrucks.length - 1].trailerLength ===
                    "" ||
                  updatedTrucks[updatedTrucks.length - 1].MaxHualingCapacity ===
                    "" ||
                  updatedTrucks[updatedTrucks.length - 1].DoorDimensionsH ===
                    "" ||
                  updatedTrucks[updatedTrucks.length - 1].DoorDimensionsW ===
                    "" ||
                  updatedTrucks[updatedTrucks.length - 1].liftgate === ""
                ) {
                  alert("Please Complete the last truck feilds first!");
                } else {
                  updatedTrucks.push({
                    name: "Dryvan",
                    MaxHualingCapacity: "",
                    trailerLength: "",
                    DoorDimensionsW: "",
                    DoorDimensionsH: "",
                    liftgate: "",
                  });
                  setAssets({
                    ...assets,
                    trucks: updatedTrucks,
                  });
                  setEditIndex(updatedTrucks.length - 1);
                }
              } else {
                updatedTrucks = [];
                updatedTrucks.push({
                  name: "Dryvan",
                  MaxHualingCapacity: "",
                  trailerLength: "",
                  DoorDimensionsW: "",
                  DoorDimensionsH: "",
                  liftgate: "",
                });
                setAssets({
                  ...assets,
                  trucks: updatedTrucks,
                });
                setEditIndex(updatedTrucks.length - 1);
              }
            }}
            style={{ margin: 20 }}
            className="btn btn-primary"
          >
            Add Truck
          </button>
          <div style={{ display: "block" }} className="f-group">
            <h3>More Info</h3>
          </div>
          <div className="offer-f">
            <div className="f-group">
              <label>Selected States</label>
              <br />
              {selectedOptionsState && (
                <MultiSelect
                  options={States}
                  value={selectedOptionsState}
                  onChange={handleStatesChange}
                />
              )}
              {/* {dotNumberError && (
                <p style={{ color: "red", textAlign: "right" }}>
                  {dotNumberError}
                </p>
              )} */}
            </div>
            <div className="f-group">
              <label>Starting Date</label>
              <br />
              {!startingDate?.seconds && (
                <DatePicker
                  selected={startingDate}
                  onChange={(startDate) => {
                    setStartingDate(startDate);
                  }}
                />
              )}
              {/* {dotNumberError && (
                <p style={{ color: "red", textAlign: "right" }}>
                  {dotNumberError}
                </p>
              )} */}
            </div>
            <div className="f-group">
              <label>Endorsements</label>
              <br />
              <input
                type="text"
                value={endorsements}
                onChange={(e) => {
                  setEndorsements(e.target.value);
                }}
              />
              {/* {dotNumberError && (
                <p style={{ color: "red", textAlign: "right" }}>
                  {dotNumberError}
                </p>
              )} */}
            </div>
          </div>
          <div style={{ display: "flex", alignItems: "baseline" }}>
            <p>Verified: </p>
            <Switch
              checked={isVerified}
              onChange={(e) => {
                setIsVerified(!isVerified);
              }}
            />
          </div>
          <div style={{ display: "flex", alignItems: "baseline" }}>
            <p>Agreement Filled: </p>
            <Switch
              checked={isAgreementFilled}
              onChange={(e) => {
                setAgreementFilled(!isAgreementFilled);
              }}
            />
          </div>
          <div style={{ display: "flex", alignItems: "baseline" }}>
            <p>Admin Bypass: </p>
            <Switch
              checked={adminBypass}
              onChange={(e) => {
                setAdminBypass(!adminBypass);
              }}
            />
          </div>
          <Button
            type="submit"
            value="Confirm"
            onClick={() => {
              if (showEditModal) {
                updateTeam();
              } else {
                addTeam();
              }
            }}
            text="Add Offer"
            className="text-center"
          >
            {showEditModal ? "Update Team" : "Add Team"}
          </Button>
        </ModalBody>
      </Modal>
    </div>
  );
}
