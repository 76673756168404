/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalTitle } from "react-bootstrap";
import firebase from "./../firebase/firebase";

export default function AgreementModal({
  showModal = false,
  onCloseModal = () => {},
  selectedItem = {},
}) {
  const [companyName, setCompanyName] = useState(
    selectedItem?.Agreement?.companyName
  );
  const [carrierInitials, setCarrierInitials] = useState(
    selectedItem?.Agreement?.carrierInitials
  );
  const [email, setEmail] = useState(selectedItem?.Agreement?.email);
  const [dotNumber, setDotNumber] = useState(
    selectedItem?.Agreement?.dotNumber
  );
  const [mcNumber, setMcNumber] = useState(selectedItem?.Agreement?.mcNumber);
  const [licensePlate, setLicensePlate] = useState(
    selectedItem?.Agreement?.licensePlate
  );
  const [phone, setPhone] = useState(selectedItem?.Agreement?.phone);
  const [stateOF, setStateOF] = useState(selectedItem?.Agreement?.stateOF);
  const [truck, setTruck] = useState(selectedItem?.Agreement?.truck);
  const [trailer, setTrailer] = useState(selectedItem?.Agreement?.trailer);
  const [date, setDate] = useState(selectedItem?.Agreement?.date);

  useEffect(() => {
    setCompanyName(selectedItem?.Agreement?.companyName || "");
    setCarrierInitials(selectedItem?.Agreement?.carrierInitials || "");
    setEmail(selectedItem?.Agreement?.email || "");
    setDotNumber(selectedItem?.Agreement?.dotNumber || "");
    setMcNumber(selectedItem?.Agreement?.mcNumber || "");
    setLicensePlate(selectedItem?.Agreement?.licensePlate || "");
    setPhone(selectedItem?.Agreement?.phone || "");
    setStateOF(selectedItem?.Agreement?.stateOF || "");
    setTruck(selectedItem?.Agreement?.truck || "");
    setTrailer(selectedItem?.Agreement?.trailer || "");
    setDate(selectedItem?.Agreement?.date || "");
  }, [selectedItem]);

  function getDocumentURL(documentsArray, documentType) {
    const documentObject = documentsArray?.find((doc) =>
      doc.hasOwnProperty(documentType)
    );
    if (documentObject) {
      return documentObject[documentType];
    } else {
      return `${documentType} document not found.`;
    }
  }

  const w9URL = getDocumentURL(selectedItem?.documents, "W-9");
  const mcAuthorityURL = getDocumentURL(
    selectedItem?.documents,
    "MC Authority Letter"
  );
  const noaURL = getDocumentURL(
    selectedItem?.documents,
    "Notice of Assignment (NOA)"
  );
  const coiURL = getDocumentURL(
    selectedItem?.documents,
    "Certificate of Insurance (COI)"
  );

  const handleSave = async () => {
    try {
      const userRef = firebase
        .firestore()
        .collection("teams")
        .doc(selectedItem.id);

      await userRef.update({
        Agreement: {
          companyName,
          carrierInitials,
          email,
          dotNumber,
          mcNumber,
          licensePlate,
          phone,
          stateOF,
          truck,
          trailer,
          date,
        },
      });

      alert("Agreement updated successfully");

      onHide();
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  };

  return (
    <div>
      <Modal
        show={showModal}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        fullscreen={true}
        onHide={() => {
          onCloseModal();
        }}
      >
        <ModalHeader closeButton>
          <ModalTitle>Agreement</ModalTitle>
        </ModalHeader>

        <Modal.Body>
          <div style={modalBodyStyle}>
            <div className="f-group">
              <label>Company Name</label>
              <input
                type="text"
                value={companyName}
                onChange={(e) => {
                  setCompanyName(e.target.value);
                }}
                style={inputStyle}
              />
            </div>

            <div className="f-group">
              <label>Carrier Initials</label>
              <input
                type="text"
                value={carrierInitials}
                onChange={(e) => {
                  setCarrierInitials(e.target.value);
                }}
                style={inputStyle}
              />
            </div>

            <div className="f-group">
              <label>Email</label>
              <input
                type="text"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                style={inputStyle}
              />
            </div>

            <div className="f-group">
              <label>DOT Number</label>
              <input
                type="text"
                value={dotNumber}
                onChange={(e) => {
                  setDotNumber(e.target.value);
                }}
                style={inputStyle}
              />
            </div>

            <div className="f-group">
              <label>MC Number</label>
              <input
                type="text"
                value={mcNumber}
                onChange={(e) => {
                  setMcNumber(e.target.value);
                }}
                style={inputStyle}
              />
            </div>

            <div className="f-group">
              <label>License Plate</label>
              <input
                type="text"
                value={licensePlate}
                onChange={(e) => {
                  setLicensePlate(e.target.value);
                }}
                style={inputStyle}
              />
            </div>

            <div className="f-group">
              <label>Phone Number</label>
              <input
                type="text"
                value={phone}
                onChange={(e) => {
                  setPhone(e.target.value);
                }}
                style={inputStyle}
              />
            </div>

            <div className="f-group">
              <label>State OF</label>
              <input
                type="text"
                value={stateOF}
                onChange={(e) => {
                  setStateOF(e.target.value);
                }}
                style={inputStyle}
              />
            </div>

            <div className="f-group">
              <label>Truck </label>
              <input
                type="text"
                value={truck}
                onChange={(e) => {
                  setTruck(e.target.value);
                }}
                style={inputStyle}
              />
            </div>

            <div className="f-group">
              <label>Trailer</label>
              <input
                type="text"
                value={trailer}
                onChange={(e) => {
                  setTrailer(e.target.value);
                }}
                style={inputStyle}
              />
            </div>

            <div className="f-group">
              <label>Date</label>
              <input
                type="text"
                value={date}
                onChange={(e) => {
                  setDate(e.target.value);
                }}
                style={inputStyle}
              />
            </div>
            <div className="f-group">
              <label htmlFor="name">Signature</label>
              <br />
              <a
                href={selectedItem?.Agreement?.signatureImage}
                target="_blank"
                rel="noopener noreferrer"
              >
                Signature URL
              </a>
            </div>
            <div className="f-group">
              <label htmlFor="name">Agreement</label>
              <br />
              <a
                href={selectedItem?.agreementPDF}
                target="_blank"
                rel="noopener noreferrer"
              >
                Agreement URL
              </a>
            </div>
            <div className="f-group">
              <label htmlFor="name">W9 Document</label>
              <br />
              <a href={w9URL} target="_blank" rel="noopener noreferrer">
                W9 Document URL
              </a>
            </div>
            <div className="f-group">
              <label htmlFor="name">MC Authority Letter</label>
              <br />
              <a
                href={mcAuthorityURL}
                target="_blank"
                rel="noopener noreferrer"
              >
                MC Authority Letter URL
              </a>
            </div>
            <div className="f-group">
              <label htmlFor="name">Notice of Assignment (NOA)</label>
              <br />
              <a href={noaURL} target="_blank" rel="noopener noreferrer">
                Notice of Assignment (NOA) URL
              </a>
            </div>
            <div className="f-group">
              <label htmlFor="name">Certificate of Insurance (COI)"</label>
              <br />
              <a href={coiURL} target="_blank" rel="noopener noreferrer">
                Certificate of Insurance (COI) URL
              </a>
            </div>
          </div>
          <button
            onClick={handleSave}
            className="btn btn-primary m-2 "
            style={{
              height: "7%",
              width: "10%",
            }}
          >
            Save
          </button>
        </Modal.Body>
      </Modal>
    </div>
  );
}

const modalBodyStyle = {
  display: "grid",
  gridTemplateColumns: "repeat(3, 1fr)",
};
const inputStyle = {
  width: "100%",
  padding: "10px",
  marginBottom: "15px",
  border: "1px solid #ccc",
  borderRadius: "4px",
};
