/* eslint-disable */
import React, { Component } from "react";
import SendPushNotification from "./../firebase/SendPushNotification";
import firebase from "./../firebase/firebase";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";

class Payments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      list: [],
      showModal: false,
      selectedItem: null,
      showBidModal: false,
      pageSize: 50,
    };
  }

  getAllSearches = async () => {
    this.setState({ loading: true });
    let ref = await firebase.firestore().collection("loadsHistory");
    ref.onSnapshot((snap) => {
      if (snap.empty) {
        this.setState({ loading: false });
      }
      let temp = [];
      snap.docs.forEach(async (a) => {
        let getOffers = await firebase
          .firestore()
          .collection("loadsHistory")
          .doc(a.id)
          .collection("completed")
          .get();
        let off = await getOffers.size;
        const tempPending = [];
        getOffers.docs.forEach((docs) => {
          console.log("====>", docs.data());
          // temp.push({
          //     ...docs.data(),
          // })
          temp = [...temp, { id: docs.id, ...docs.data() }];
        });
        console.log("====>", tempPending);
        this.setState({
          list: temp,
          loading: false,
        });
      });
    });
  };
  async componentDidMount() {
    this.getAllSearches();
  }

  render() {
    const { loading, list, pageSize } = this.state;

    return (
      <div className="table-t">
        <div className="table-load">
          <h1>Delivered</h1>

          <div
            style={{
              height: "70vh",
              width: "95%",
              margin: "0 auto",
              marginTop: 30,
            }}
          >
            <DataGrid
              loading={loading}
              rows={list}
              columns={[
                {
                  headerName: "Driver Name",
                  field: "driver",
                  flex: 1,
                  minWidth: 200,
                  valueGetter: ({ row }) => row.driver?.name,
                  renderCell: ({ row }) => row.driver?.name,
                },
                {
                  headerName: "Pickup From",
                  field: "pickup",
                  flex: 1,
                  minWidth: 200,
                },
                {
                  headerName: "Drop-Off",
                  field: "destination",
                  flex: 1,
                  minWidth: 200,
                },
                {
                  headerName: "Date",
                  field: "loadDate",
                  flex: 1,
                  minWidth: 200,
                  valueGetter: ({ row }) =>
                    row?.loadDate?.toDate()?.toDateString(),
                },
                {
                  headerName: "Time",
                  field: "tripDateTime",
                  flex: 1,
                  minWidth: 200,
                  valueGetter: ({ row }) => row.tripDateTime?.delivered,
                  renderCell: ({ row }) =>
                    moment(
                      new Date(row.tripDateTime?.delivered?.seconds * 1000)
                    ).format("hh:mm a"),
                },
                {
                  headerName: "Dispatcher",
                  field: "dispatcher",
                  flex: 1,
                  minWidth: 200,
                  valueGetter: ({ row }) => row.dispatcher?.name,
                  renderCell: ({ row }) => row.dispatcher?.name,
                },
                {
                  headerName: "Company Name",
                  field: "comapnyName",
                  flex: 1,
                  minWidth: 200,
                  valueGetter: ({ row }) => row.driver?.companyName,
                  renderCell: ({ row }) => row.driver?.companyName,
                },
                {
                  headerName: "Invoice",
                  field: "cost",
                  flex: 1,
                  minWidth: 200,
                  type: "number",
                  valueGetter: ({ row }) => row.cost?.driverBid,
                  renderCell: ({ row }) => (
                    <div>
                      ${row.cost?.driverBid}/
                      {row.paidTheInvoice ? "PAID" : "UN-PAID"}
                    </div>
                  ),
                },
                {
                  headerName: "Notify",
                  flex: 1,
                  minWidth: 400,
                  sortable: false,
                  renderCell: ({ row }) => {
                    const isInvoicePaid = row.paidTheInvoice;

                    return (
                      <div>
                        {!isInvoicePaid && (
                          <button
                            onClick={() => {
                              const cost = row.cost?.driverBid || 0;
                              const percentage =
                                row.driver?.subscriptionPlan
                                  ?.percentagePerLoad || 0;
                              const totalCost = cost * (percentage / 100);

                              SendPushNotification(
                                "Pay Invoice",
                                `Dear! ${
                                  row.driver?.name
                                }, please pay the invoice of $${totalCost.toFixed(
                                  2
                                )} of your last trip.`,
                                row.driver?.id
                              );
                              alert("Reminder sent!");
                            }}
                          >
                            Send Reminder
                          </button>
                        )}
                      </div>
                    );
                  },
                },
              ]}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) =>
                this.setState({ pageSize: newPageSize })
              }
              rowsPerPageOptions={[5, 10, 20, 50]}
              pagination
            />
          </div>
          {/* <table className="cc-t">
                        <Deliverd
                            list={this.state.list}
                            onClick={(user) => {
                                SendPushNotification(
                                    "Pay Invoice",
                                    "Dear! "+user.driver?.name+" please pay the invoice of $"+user.cost?.driverBid+" of your last trip.",
                                    user.driver?.id
                                )
                                alert("Reminder send!")
                            }}
                        ></Deliverd>
                    </table> */}
        </div>
      </div>
    );
  }
}
export default Payments;
