import React, { useEffect, useState } from "react";

import {
  Button,
  Container,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Dialog,
  DialogTitle,
  DialogContent,
  Slide,
  TextField,
  DialogActions,
} from "@mui/material";

import firebase from "./../firebase/firebase";
import swal from "sweetalert";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SearchCost = () => {
  const [open, setOpen] = React.useState(false);
  const [cost, setCost] = useState([]);
  const [userId, setUserId] = useState("");
  const [values, setValues] = useState(0);

  const getData = () => {
    const ref = firebase.firestore().collection("searchCost");

    ref.onSnapshot((snapshot) => {
      setCost([]);

      snapshot.forEach((ele) => {
        setCost((pre) => [...pre, ele.data()]);
      });
    });
  };

  const editCredit = (i, index) => {
    setOpen(true);
    setUserId(i);

    setValues(cost[index].cost);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    setValues(e.target.value);
  };

  const updateCost = () => {
    if (values <= 0) {
      swal("Enter Search Cost");
      return;
    }

    firebase
      .firestore()
      .collection("searchCost")
      .doc(userId)
      .update({
        cost: values,
      })
      .then(() => {
        setOpen(false);
        swal("Search Cost Updated Successfully");
      })
      .catch((err) => {
        swal(err.message);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Container>
      <TableContainer component={Paper} sx={{ marginTop: "20px" }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell align="right">Name</TableCell>
              <TableCell align="right">Search Cost</TableCell>
              <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cost.map((val, ind) => (
              <TableRow
                key={ind}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {ind + 1}
                </TableCell>
                <TableCell align="right">Plane {ind + 1}</TableCell>
                <TableCell align="right">{val.cost}</TableCell>
                <TableCell align="right">
                  <Button
                    variant="contained"
                    onClick={() => editCredit(`${val.id}`, ind)}
                  >
                    Edit
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Update Search Cost</DialogTitle>
        <DialogContent>
          <TextField
            label="Search Cost"
            variant="standard"
            type="number"
            name="credit"
            sx={{ marginBottom: "10px" }}
            fullWidth
            value={values}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="error">
            Close
          </Button>
          <Button variant="contained" onClick={updateCost}>
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default SearchCost;
