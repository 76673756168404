/* eslint-disable */
import React, { useState, useEffect } from "react";
import Tabs from "./components/Tabs";
import Dashboard from "./components/Dashboard";
import Dispatcher from "./components/Dispatcher";
import Load_request from "./components/Load_request";
import Trips from "./components/Trips";
import Payments from "./components/Payments";
import Messagee from "./components/Message";
import LoadHistory from "./components/LoadHistory";
import NotificationTemplate from "./components/NotificationTemplate";
import AuthKey from "./components/AuthKey";
import "./App.css";
import profile from "./img/profile.jpeg";
import { Dropdown } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Login from "./auth/Login";
import firebase, { firebaseConfig } from "./firebase/firebase";
import { initializeApp } from "firebase/app";
import CustomChatBot from "./components/CustomChatBot";
import { Provider, useSelector } from "react-redux";
import { store } from "./stores";
import Team from "./components/Team";
import Credits from "./components/Credits";
import SearchCost from "./components/SearchCost";
import SubscriptionPlan from "./components/SubscriptionPlan";
import Settings from "./components/Settings";
import TrackingScreen from "./components/trackingScreen";
import ActivityLogs from "./components/ActivityLogs";
import ScrappersAuth from "./components/ScrappersAuth";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import NewBid from "./components/Alerts/newBid";

function App() {
  const [isLogin, setisLogin] = useState(false);
  const [isLoading, setisLoading] = useState(true);
  const [role, setRole] = useState("Admin");
  const [user, setUser] = useState("Admin");
  const { eventlog } = useSelector((state) => state.UserResponseEventtab);
  const checkLogin = async () => {
    let login = await localStorage.getItem("isLogin", "");
    let userRole = await localStorage.getItem("role", null);

    if (login === "" || login == null) {
      setisLogin(false);
      setisLoading(false);
      setUser("");
      setRole("");
    } else {
      setUser(login);
      setRole(userRole);
      setisLoading(false);
      setisLogin(true);
    }
  };
  useEffect(() => {
    const messaging = firebase.messaging();

    if ("Notification" in window) {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          // console.log("Notification permission granted.");

          messaging
            .getToken()
            .then((currentToken) => {
              if (currentToken) {
                // console.log("FCM Token:", currentToken);
                saveTokenToFirestore(currentToken);
              } else {
                console.log("No FCM token available.");
              }
            })
            .catch((err) => {
              console.error("Error getting FCM token:", err);
            });
        }
      });
    } else {
      console.log("Browser does not support notifications.");
    }

    const app = initializeApp(firebaseConfig);
    checkLogin();
  }, []);

  const saveTokenToFirestore = async (token) => {
    const firestore = firebase.firestore();
    const adminUserRef = firestore.collection("NotificationFCM").doc("admin");

    try {
      const doc = await adminUserRef.get();
      const firestoreTokens = doc.exists ? doc.data().tokens || [] : [];

      if (!firestoreTokens.includes(token)) {
        firestoreTokens.push(token);

        await adminUserRef.set({ tokens: firestoreTokens });
        // console.log("FCM token added to Firestore:", token);
      } else {
        // console.log("FCM token already exists in Firestore:", token);
      }
    } catch (error) {
      console.error("Error saving/retrieving FCM tokens:", error);
    }
  };

  useEffect(() => {
    if (eventlog) {
    }
  });
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <Provider store={store}>
              <div>
                {isLoading ? (
                  <div></div>
                ) : (
                  <>
                    <NewBid />
                    {isLogin ? (
                      <>
                        <div className="profile">
                          <Dropdown>
                            <Dropdown.Toggle variant="light-Info" id="dropdown">
                              <span>{user}</span>
                              <img src={profile} alt="profile" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                onClick={async () => {
                                  let role = await localStorage.getItem("role");
                                  if (role === "Admin") {
                                    await localStorage.clear();
                                    setisLogin(false);
                                  } else {
                                    await localStorage.clear();
                                    firebase
                                      .firestore()
                                      .collection("statistics")
                                      .doc("admin")
                                      .get()
                                      .then((res) => {
                                        let dat =
                                          res.data().total_active_dispatcher;
                                        let dat1 =
                                          res.data().total_inactive_dispatcher;
                                        dat = dat - 1;
                                        dat1 = dat1 + 1;
                                        firebase
                                          .firestore()
                                          .collection("statistics")
                                          .doc("admin")
                                          .set(
                                            {
                                              total_active_dispatcher: dat,
                                              total_inactive_dispatcher: dat1,
                                            },
                                            { merge: true }
                                          );
                                      });
                                    setisLogin(false);
                                  }
                                }}
                              >
                                Signout
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>

                        {role === "Admin" ? (
                          <Tabs>
                            <div label="Dashboard">
                              <Dashboard></Dashboard>
                            </div>
                            <div label="Dispatcher">
                              <Dispatcher></Dispatcher>
                            </div>
                            <div label="Team">
                              <Team></Team>
                            </div>
                            <div label="Load requests">
                              <Load_request></Load_request>
                            </div>
                            <div label="Loads">
                              <Trips></Trips>
                            </div>
                            <div label="Payments">
                              <Payments></Payments>
                            </div>
                            <div label="Loads History">
                              <LoadHistory></LoadHistory>
                            </div>
                            <div label="Chat Bot">
                              <CustomChatBot />
                            </div>
                            <div label="Credits">
                              <Credits />
                            </div>
                            <div label="Search Cost">
                              <SearchCost />
                            </div>
                            <div label="Subscription Plan ">
                              <SubscriptionPlan />
                            </div>
                            <div label="Messages">
                              <Messagee></Messagee>
                            </div>
                            <div label="Templates">
                              <NotificationTemplate></NotificationTemplate>
                            </div>
                            <div label="Settings">
                              <Settings></Settings>
                            </div>
                            <div label="Auth Key">
                              <AuthKey></AuthKey>
                            </div>
                            <div label="Logs">
                              <ActivityLogs></ActivityLogs>
                            </div>
                            <div label="Scrapers Auth">
                              <ScrappersAuth></ScrappersAuth>
                            </div>
                            {/* <div label="Scrapers Log">
                              <ScrappersLog></ScrappersLog>
                            </div> */}
                            {/* <div label="" onClick={()=>{handlechnage}}>
                            <ScrappersResponse />
                            </div> */}
                          </Tabs>
                        ) : (
                          <Tabs>
                            <div label="Dashboard">
                              <Dashboard></Dashboard>
                            </div>
                            <div label="Load requests">
                              <Load_request></Load_request>
                            </div>
                            <div label="Loads">
                              <Trips></Trips>
                            </div>

                            <div label="Messages">
                              <Messagee></Messagee>
                            </div>
                          </Tabs>
                        )}
                      </>
                    ) : (
                      <div label="Dashboard">
                        <Login
                          onLogin={() => {
                            checkLogin();
                          }}
                        ></Login>
                      </div>
                    )}
                  </>
                )}
              </div>
            </Provider>
          }
        />
        <Route
          path="/tracking/:trackingId/:loadRefId?"
          element={<TrackingScreen />}
        />
        {/* <Route path="/ScrappersResponses/:id" element={role=="Admin"?
           <ScrappersResponse/>:<Login/>} />  */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
