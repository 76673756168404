/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import Tab from "./Tab";
import { connect } from "react-redux";
class Tabs extends Component {
  static propTypes = {
    children: PropTypes.instanceOf(Array).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      activeTab: this.props?.children[0].props?.label,
      info: this.props?.children[0].props?.info,
    };
  }
  
  // onClickTabItem = (tab) => {
    //   console.log("clikc",tab)
    //   this.setState({ activeTab: tab });
    // };
    render() {
      // console.log(this.props?.children[0].props?.label,"oo")
  
    const {
      onClickTabItem,
      props: { children, activeTab,info },
      state: {
        // activeTab,
      },
    } = this;
    console.log(activeTab,info)
    return (
      <div className="tabs">
        <ol className="tab-list">
          {children?.map((child) => {
            const { label } = child?.props;

            return (
              
              <Tab
                // activeTab={activeTab}
                key={label}
                label={label}
                onClick={onClickTabItem}
              />
            );
          })}
        </ol>
        <div className="tab-content">
          {children.map((child) => {
            if (child.props.label !== activeTab) return undefined;
            return child.props.children;
          })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  activeTab: state.tabs.label,
  info: state.tabs.info,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Tabs);
