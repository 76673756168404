import React, { useEffect } from "react";
import firebase from "../../firebase/firebase";
import swal from "sweetalert";

function NewBid() {
  useEffect(() => {
    const db = firebase.firestore();

    const searchRequestsRef = db.collection("searchRequests");
    const unsubscribeSearchRequests = searchRequestsRef.onSnapshot(
      (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            // New searchRequest document added, set up a listener for its offers
            const searchRequestId = change.doc.id;
            const offersRef = searchRequestsRef
              .doc(searchRequestId)
              .collection("offers");

            const unsubscribeOffers = offersRef.onSnapshot((offersSnapshot) => {
              offersSnapshot.docChanges().forEach((offerChange) => {
                if (offerChange.type === "modified") {
                  const offerData = offerChange.doc.data();
                  if (offerData.status === "pending") {
                    swal({
                      title: "New Bid!",
                      text: offerData.driver?.name + " has placed a new bid!",
                      icon: "info",
                    });
                  }
                }
              });
            });
          }
        });
      }
    );

    return () => {
      unsubscribeSearchRequests();
    };
  }, []);
  return <div></div>;
}

export default NewBid;
