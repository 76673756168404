/* eslint-disable */
import React, { useCallback, useEffect, useState } from "react";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import mark from "./semi.png";
import { useParams } from "react-router-dom";
import firebase from "./../firebase/firebase";
import MarkerWithInfo from "./markers/MarkerWithInfo";
import profile from "./../img/profile.jpeg";
import axios from "axios";
import completeLoadGif from "./../img/icons8-delivered-box.gif";
import ErrorGif from "./../img/icons8-error.gif";
import TruckGif from "./../img/truck.gif";
import Geocode from "react-geocode";

const mapStyle = {
  width: "65rem",
  height: "55rem",
};

const TrackingScreen = () => {
  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh", // 100% of the viewport height
  };

  const textStyle = {
    fontSize: "24px",
    color: "#333", // Adjust the color as needed
    textAlign: "center",
    marginBottom: "20px",
    fontWeight: "bold",
    // Add more styles as needed
  };

  const gifStyle = {
    width: "60px", // Adjust the width as needed
    height: "60px", // Adjust the height as needed
    marginBottom: "20px",
    // Add more styles as needed
  };

  const { trackingId, loadRefId } = useParams();
  const [load, setLoad] = useState(null);
  const [currentTime, setCurrentTime] = useState(Date.now());
  const [isAlreadyCompleted, setAlreadyCompleted] = useState(false);
  const [isFound, setFound] = useState(false);
  const [loading, setLoading] = useState(true);
  const [zip, setZip] = useState();
  const [city, setCity] = useState();
  const [state, setState] = useState();
  const [country, setCountry] = useState();
  const [center, setCenter] = useState({
    lat: load?.location?.latitude,
    lng: load?.location?.longitude,
  });

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(Date.now());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const convertTimestamp = (timeStamp) => {
    if (timeStamp) {
      const date = new Date(timeStamp?.seconds * 1000);
      return date?.toDateString();
    }
  };

  const getTime = (timeStamp) => {
    if (timeStamp) {
      const date = new Date(timeStamp?.seconds * 1000);
      const time = date?.toTimeString()?.split(" ")[0];
      return time;
    }
  };

  const updateTimeAgo = (timestamp) => {
    const now = new Date();
    const timestampDate = new Date(timestamp?.seconds * 1000); // Convert timestamp to milliseconds

    const timeDifference = now - timestampDate;
    const minutes = Math.floor(timeDifference / 60000); // 1 minute = 60000 milliseconds
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    const days = Math.floor(hours / 24);

    let timeAgo;

    if (minutes < 1) {
      timeAgo = "Just now";
    } else if (minutes < 60) {
      timeAgo = `${minutes} ${minutes === 1 ? "minute" : "minutes"} ago`;
    } else if (hours < 24) {
      if (hours === 1 && remainingMinutes === 0) {
        timeAgo = "1 hour ago";
      } else if (hours === 1 && remainingMinutes > 0) {
        timeAgo = `1 hour ${remainingMinutes} minutes ago`;
      } else {
        timeAgo = `${hours} hours ago`;
      }
    } else {
      if (days === 1) {
        timeAgo = "1 day ago";
      } else {
        timeAgo = `${days} days ago`;
      }
    }

    return timeAgo;
  };

  const checkStatus = (item) => {
    if (item?.status === "enrouteToLoad") {
      return item?.tripDateTime?.enrouteToLoad;
    } else if (item?.status === "loaded") {
      return item?.tripDateTime?.loaded;
    } else if (item?.status === "enrouteToDrop") {
      return item?.tripDateTime?.enrouteToDrop;
    } else if (item?.status === "dropped") {
      return item?.tripDateTime?.dropped;
    } else if (item?.status === "delivered") {
      return item?.tripDateTime?.delivered;
    }
  };

  const previousStatus = (item) => {
    if (item?.status === "loaded") {
      return item?.tripDateTime?.enrouteToLoad;
    } else if (item?.status === "enrouteToDrop") {
      return item?.tripDateTime?.loaded;
    } else if (item?.status === "dropped") {
      return item?.tripDateTime?.enrouteToDrop;
    } else if (item?.status === "delivered") {
      return item?.tripDateTime?.dropped;
    }
  };

  const totalTime = (timeStamp) => {
    const startTime = timeStamp?.seconds * 1000;
    const timeDifference = currentTime - startTime;
    var seconds = Math.floor(timeDifference / 1000);
    var minutes = Math.floor(seconds / 60);
    var hours = Math.floor(minutes / 60);
    minutes = minutes % 60;
    seconds = seconds % 60;
    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    return hours + ":" + minutes + ":" + seconds;
  };

  const finalTime = (timeStamp1, timeStamp2) => {
    const startTime = timeStamp1?.seconds * 1000;
    const currentTime = timeStamp2?.seconds * 1000;
    const timeDifference = currentTime - startTime;
    var seconds = Math.floor(timeDifference / 1000);
    var minutes = Math.floor(seconds / 60);
    var hours = Math.floor(minutes / 60);
    minutes = minutes % 60;
    seconds = seconds % 60;
    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    return hours + ":" + minutes + ":" + seconds;
  };

  const convertString = (inputString) => {
    // Split the string by uppercase letters
    const words = inputString?.split(/(?=[A-Z])/);

    // Capitalize the first word and join the words with spaces
    const convertedString = words
      ?.map((word, index) => {
        if (index === 0) {
          return word.charAt(0).toUpperCase() + word.slice(1);
        } else {
          return word;
        }
      })
      .join(" ");

    return convertedString;
  };

  const { isLoaded, loadError } = useJsApiLoader({
    id: "tracking-screen-map-script",
    googleMapsApiKey: "AIzaSyCjysO3MF1vStj7BR3IRTAOOvLa3O6bark",
  });

  const [map, setMap] = React.useState(null);
  const [distance, setDistance] = useState(null);
  const [estimatedTime, setEstimatedTime] = useState(null);

  const backendURL = process.env.REACT_APP_BACKEND_URL;

  const getDistance = async (originLat, originLng, destination) => {
    try {
      const { data } = await axios.get(
        `${backendURL}calculate-distance?originLat=${originLat}&originLng=${originLng}&destination=${destination}`
      );
      // alert(data);
      setDistance(data.distance);
      setEstimatedTime(data.estimatedTime);
    } catch (error) {
      // alert(error);
      console.log(error);
    }
  };

  const getLocationData = (data) => {
    Geocode.setApiKey("AIzaSyCjysO3MF1vStj7BR3IRTAOOvLa3O6bark");
    Geocode.setLocationType("ROOFTOP");
    Geocode.fromLatLng(
      data?.location?.latitude,
      data?.location?.longitude
    ).then(
      (response) => {
        let zip, city, state, country;
        for (
          let i = 0;
          i < response.results[0].address_components.length;
          i++
        ) {
          for (
            let j = 0;
            j < response.results[0].address_components[i].types.length;
            j++
          ) {
            switch (response.results[0].address_components[i].types[j]) {
              case "locality":
                city = response.results[0].address_components[i].long_name;
                break;
              case "administrative_area_level_1":
                state = response.results[0].address_components[i].long_name;
                break;
              case "country":
                country = response.results[0].address_components[i].long_name;
                break;
              case "postal_code":
                zip = response.results[0].address_components[i].long_name;
                break;
            }
          }
        }
        setCity(city);
        setZip(zip);
        setCountry(country);
        setState(state);
        return <></>;
      },
      (error) => {
        console.error(error);
      }
    );
  };

  useEffect(() => {
    if (load?.location) {
      getLocationData(load);
      const location = load?.location;
      getDistance(location?.latitude, location?.longitude, load?.destination);
    }
  }, [load?.location]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const snap = await firebase
          .firestore()
          .collection("loads")
          .doc(trackingId)
          .onSnapshot(async (snap) => {
            if (snap.exists) {
              const data = snap.data().currentLoad;
              if (data.loadRefsID == loadRefId) {
                setLoad(data);
                setCenter({
                  lat: data?.location?.latitude,
                  lng: data?.location?.longitude,
                });
                setFound(true);
                setAlreadyCompleted(false);
                setLoading(false);
              } else {
                const historySnap = await firebase
                  .firestore()
                  .collection("loadsHistory")
                  .doc(trackingId)
                  .collection("completed")
                  .get();
                let found = false;
                historySnap.forEach((doc) => {
                  // Access the data of the document
                  const data = doc.data();

                  // Check if the document's loadRefId matches your criteria
                  if (data.loadRefsID == loadRefId) {
                    setAlreadyCompleted(true);
                    setFound(true);
                    setLoading(false);
                    found = true;
                  }
                });
                if (!found) {
                  setFound(false);
                  setAlreadyCompleted(false);
                  setLoading(false);
                }
              }
            } else {
              setLoad(null);
              setLoading(true);
              const historySnap = await firebase
                .firestore()
                .collection("loadsHistory")
                .doc(trackingId)
                .collection("completed")
                .get();
              let found = false;
              historySnap.forEach((doc) => {
                // Access the data of the document
                const data = doc.data();
                // Check if the document's loadRefId matches your criteria
                if (data.loadRefsID == loadRefId) {
                  setAlreadyCompleted(true);
                  setFound(true);
                  setLoading(false);
                  found = true;
                }
              });
              if (!found) {
                setFound(false);
                setAlreadyCompleted(false);
                setLoading(false);
              }
            }
          });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [trackingId]);

  if (loadError) {
    return (
      <div style={containerStyle}>
        <img src={ErrorGif} alt="Error Gif" style={gifStyle} />
        <text style={textStyle}>{"Error Loading Maps!!!"}</text>
      </div>
    );
  }

  if (!isLoaded) {
    return (
      <div style={containerStyle}>
        <img src={TruckGif} alt="Truck Gif" style={gifStyle} />
        <text style={textStyle}>{"Loading Maps..."}</text>
      </div>
    );
  }

  if (loading) {
    return (
      <div style={containerStyle}>
        <img src={TruckGif} alt="Truckk Gif" style={gifStyle} />
        <text style={textStyle}>{"Loading..."}</text>
      </div>
    );
  }

  if (isLoaded && load?.location) {
    const iconMarker = new google.maps.MarkerImage(
      mark,
      new google.maps.Size(280, 260),
      null,
      new google.maps.Point(0, 30)
    );

    return (
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div
          style={{
            margin: 20,
            padding: 10,
            backgroundColor: "rgba(255, 255, 255, 1)", // Change to your desired background color
            boxShadow: "8px 8px 12px 5px rgba(0, 0, 0, 0.4)", // Add elevation with boxShadow
            borderRadius: 10,
            width: "100%",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <GoogleMap mapContainerStyle={mapStyle} zoom={10} center={center}>
            <MarkerWithInfo list={load} iconMarker={iconMarker} />
          </GoogleMap>
        </div>
        <div
          style={{
            margin: 20,
            padding: 10,
            backgroundColor: "rgba(255, 255, 255, 1)",
            boxShadow: "8px 8px 12px 5px rgba(0, 0, 0, 0.4)",
            borderRadius: 10,
            width: "60%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            className="picTracking"
            style={{ width: "55px", height: "55px", borderRadius: "50%" }}
          >
            {load?.driver?.image ? (
              <img src={load?.driver?.image} />
            ) : (
              <img src={profile} />
            )}
          </div>
          <br />
          {/* <div style={{marginTop: 50, padding: 10}}>  */}
          <table style={{ marginTop: 50 }}>
            <tr className="trackingRow-3">
              <th className="row-2">Driver: </th>
              <td className="row-1">
                {load?.driver?.name ? load?.driver?.name : load?.name}
              </td>
            </tr>
            <tr>
              <td colSpan="2" style={{ textAlign: "center" }}>
                <div
                  style={{
                    width: "100%",
                    marginTop: 10,
                    marginBottom: 10,
                    borderBottom: "2px solid black",
                  }}
                ></div>
              </td>
            </tr>
            <tr>
              <td colSpan="2" style={{ textAlign: "center" }}>
                <view
                  style={{
                    textAlign: "center",
                    alignContent: "center",
                    alignItems: "center",
                  }}
                >
                  <text style={{ fontWeight: "bold", fontSize: 20 }}>
                    Current Location
                  </text>
                </view>
              </td>
            </tr>
            <tr className="trackingRow-3">
              <th className="row-2">ZIP: </th>
              <td className="row-1">{zip}</td>
            </tr>
            <tr className="trackingRow-3">
              <th className="row-2">City: </th>
              <td className="row-1">{city}</td>
            </tr>
            <tr className="trackingRow-3">
              <th className="row-2">State: </th>
              <td className="row-1">{state}</td>
            </tr>
            <tr className="trackingRow-3">
              <th className="row-2">Country: </th>
              <td className="row-1">{country}</td>
            </tr>
            <tr>
              <td colSpan="2" style={{ textAlign: "center" }}>
                <div
                  style={{
                    width: "100%",
                    marginTop: 10,
                    marginBottom: 10,
                    borderBottom: "2px solid black",
                  }}
                ></div>
              </td>
            </tr>
            <tr className="trackingRow-3">
              <th className="row-2">Pickup: </th>
              <td className="row-1">{load?.pickup}</td>
            </tr>
            <tr className="trackingRow-3">
              <th className="row-2">Destination: </th>
              <td className="row-1">{load?.destination}</td>
            </tr>
            <tr className="trackingRow-3">
              <th className="row-2">Status: </th>
              <td className="row-1">{convertString(load?.status)}</td>
            </tr>
            <tr className="trackingRow-3">
              <th className="row-2">Time Elapsed:</th>
              <td className="row-1">{updateTimeAgo(load?.loadDate)}</td>
            </tr>

            <tr className="trackingRow-3">
              <th className="row-2">Pick-up Date:</th>
              <td className="row-1">{convertTimestamp(load?.loadDate)}</td>
            </tr>
            <tr className="trackingRow-3">
              <th className="row-2">Pick-up Hour:</th>
              <td className="row-1">{getTime(load?.loadDate)}</td>
            </tr>
            <tr className="trackingRow-3">
              <th className="row-2">Speed:</th>
              <td className="row-1">
                {Math.floor(Number(load.location?.speed) * 0.6213711922)} mph
              </td>
            </tr>
            <tr className="trackingRow-3">
              <th className="row-2">Distance:</th>
              <td className="row-1">{distance}</td>
            </tr>
            <tr className="trackingRow-3">
              <th className="row-2">Estimated Time:</th>
              <td className="row-1">{estimatedTime}</td>
            </tr>
            {load.comment && (
              <tr className="trackingRow-3">
                <th className="row-2">Comment:</th>
                <td className="row-1">{load.comment}</td>
              </tr>
            )}
            {/* <tr className="trackingRow-3">
              <th className="row-2">Commodity:</th>
              <td className="row-1">ABC</td>
            </tr> */}
            {load.tripDateTime && (
              <>
                {load.status !== "enrouteToLoad" && (
                  <>
                    <tr className="trackingRow-3">
                      <th className="row-2">Previous Status Time: </th>
                      <td className="row-1">
                        {finalTime(previousStatus(load), checkStatus(load))}
                      </td>
                    </tr>
                  </>
                )}
                <tr className="trackingRow-3">
                  <th className="row-2">Total Time Elapsed: </th>
                  <td className="row-1">
                    {load.status !== "delivered"
                      ? totalTime(load.tripDateTime.enrouteToLoad)
                      : finalTime(
                          load.tripDateTime.enrouteToLoad,
                          load.tripDateTime.delivered
                        )}
                  </td>
                </tr>
                {load.status !== "delivered" && (
                  <>
                    <tr className="trackingRow-3">
                      <th className="row-2">
                        {convertString(load.status)} Time:{" "}
                      </th>
                      <td className="row-1">{totalTime(checkStatus(load))}</td>
                    </tr>
                  </>
                )}
              </>
            )}
          </table>
          {load?.approvedDoc && (
            <div>
              <br />
              <text>
                <a href={load?.approvedDoc} target="_blank">
                  Rate Confirmation
                </a>
              </text>
            </div>
          )}
          {load?.bolDoc && (
            <div>
              <br />
              <text>
                <a href={load?.bolDoc} target="_blank">
                  BOL
                </a>
              </text>
            </div>
          )}
          {load?.emptyTrailorImage && (
            <div>
              <br />
              <text>
                <a href={load?.emptyTrailorImage} target="_blank">
                  Empty Trailer Image
                </a>
              </text>
            </div>
          )}
          {load?.loadedTrailorImage && (
            <div>
              <br />
              <text>
                <a href={load?.loadedTrailorImage} target="_blank">
                  Loaded Trailer Image
                </a>
              </text>
            </div>
          )}
          {/* </div> */}
        </div>
      </div>
    );
  } else {
    if (isAlreadyCompleted) {
      return (
        <div style={containerStyle}>
          <img
            src={completeLoadGif}
            alt="Completed Load Gif"
            style={gifStyle}
          />
          <text style={textStyle}>{"Thank you for choosing GoHaul."}</text>
          <text style={textStyle}>
            {"Carrier successfully delivered your load"}
          </text>
        </div>
      );
    } else if (isFound) {
      if (!load?.location) {
        return (
          <div style={containerStyle}>
            <img src={ErrorGif} alt="Error Gif" style={gifStyle} />
            <text style={textStyle}>
              {"Driver hasn't uploaded his location yet!"}
            </text>
          </div>
        );
      }
    } else if (!isFound) {
      return (
        <div style={containerStyle}>
          <img src={ErrorGif} alt="Error Gif" style={gifStyle} />
          <text style={textStyle}>{"Error 404. Load not found!"}</text>
        </div>
      );
    }
  }
};

export default TrackingScreen;
