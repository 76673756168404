import React, { useState, useRef, useEffect } from "react";
import firebase from "./../firebase/firebase";
import CircularProgress from "@material-ui/core/CircularProgress";
import SendPushNotification from "../firebase/SendPushNotification";
import { logActivity, name } from "../helper/ActivityLogger";
import emailNotifier from "../helper/emailNotifier";
import { useSelector } from "react-redux";
// CSS styles
const styles = {
  messageScreen: {
    display: "flex",
    height: "100vh", // Full height of the screen
    overflow: "hidden", // To prevent vertical scrollbar due to height
  },
  whiteBox: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "#fff",
    marginBottom: "10px",
    borderRadius: "10px",
    padding: "20px",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column-reverse",
    alignItems: "flex-end",
    overflowY: "auto", // Enable vertical scrolling for the whiteBox
    marginTop: 20,
  },
  teamList: {
    flex: "0 0 25%", // One-fourth of the screen width
    backgroundColor: "#7A5CD1",
    padding: "20px",
    boxSizing: "border-box",
    borderRadius: "0 10px 10px 0",
    overflowY: "scroll",
  },
  teamCard: {
    background: "#fff",
    padding: "10px",
    marginBottom: "10px",
    cursor: "pointer",
    borderRadius: "10px",
  },
  teamCardActive: {
    background: "#e0e0e0",
  },
  driverList: {
    listStyleType: "none",
    padding: 0,
    marginTop: "10px",
    cursor: "pointer",
  },
  messageContainer: {
    flex: "0 0 75%",
    backgroundColor: "rgba(0,0,0,0.0)",
    padding: "20px",
    boxSizing: "border-box",
    borderRadius: "10px 0 0 10px",
    marginLeft: "10px",
    height: "95%",
  },
  bottomBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
  },
  messageInput: {
    flex: 1,
    width: "100%",
    padding: "10px",
    marginBottom: "10px",
    borderRadius: "10px",
    margin: 10,
  },
  sendButton: {
    padding: "10px 20px",
    backgroundColor: "#7A5CD1",
    color: "#fff",
    border: "none",
    cursor: "pointer",
    marginBottom: 12,
    borderRadius: 10,
  },
  header: {
    padding: "20px",
    boxSizing: "border-box",
    borderRadius: "10px 0 0 10px",
    height: "10%",
    marginTop: -40,
  },
  messageItem: {
    color: "white",
    marginTop: "10px",
    padding: "10px",
    backgroundColor: "#7A5CD1",
    borderRadius: "5px",
    maxWidth: "300px",
    wordWrap: "break-word",
    alignSelf: "flex-start", // Align self to the left
    textAlign: "left", // Text alignment to the left
  },
  selfMessageItem: {
    color: "white",
    marginTop: "10px",
    padding: "10px",
    backgroundColor: "#7A5CD1",
    borderRadius: "5px",
    maxWidth: "300px",
    wordWrap: "break-word",
    alignSelf: "flex-end", // Align self to the right
    textAlign: "right", // Text alignment to the right
  },
  Line: {
    height: "2px", // Adjust the height as per your requirement
    backgroundColor: "#7A5CD1",
    marginBottom: "10px", // Add some space below the black line if needed
  },
  messageContainerWrapper: {
    flex: "0 0 75%",
    height: "95%", // Set a fixed height for the message container
    position: "relative", // Add position relative to the wrapper
    backgroundColor: "rgba(0,0,0,0.0)",
    padding: "20px",
    boxSizing: "border-box",
    borderRadius: "10px 0 0 10px",
    marginLeft: "10px",
  },
  dateBarrier: {
    textAlign: "center",
    fontWeight: "bold",
    marginTop: "10px",
  },
};

const Message = () => {
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [teams, setTeams] = useState([]);
  const [user] = useState(localStorage.getItem("isLogin"));
  const [loading, setLoading] = useState(false);
  const [newMessages, setNewMessages] = useState(true);
  const [unreadTeams, setUnreadTeams] = useState([]);
  const { info } = useSelector((state) => state.tabs);
  console.log(info, "all ifo");

  // useEffect(()=>{
  //   console.log("yes 158")
  //   if (info.id) {
  //     console.log("yes 158 inside")
  //     const docRef = firebase.firestore().collection("messages").doc(info.id);
  //     try {
  //       const unsubscribe = docRef.onSnapshot(async (doc) => {
  //           console.log("yes 158 inside try")
  //           if (doc.exists) {
  //             const message = doc.data();
  //             const existingMessages = (await docRef.get()).data()?.messages || [];

  //             // Update the readByDispatcher property for all messages
  //             const updatedMessages = existingMessages.map((msg) => ({
  //               ...msg,
  //               readByDispatcher: true,
  //             }));

  //             // Update the entire messages array in Firestore
  //             await docRef.update({
  //               messages: updatedMessages,
  //             });
  //             setMessages(message.messages.reverse());
  //           } else {
  //             setMessages([]);
  //           }
  //           setLoading(false);
  //         });

  //         subscribeRef.current = unsubscribe;
  //       } catch (error) {
  //         console.error("Error updating readByDispatcher property:", error);
  //         setLoading(false);
  //       }

  //   }
  // },[info.id])

  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection("messages")
      .onSnapshot((snap) => {
        setNewMessages(true);
      });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    setLoading(true);
    firebase
      .firestore()
      .collection("teams")
      .onSnapshot(async (snapshot) => {
        const teamsData = [];
        const teamDocs = snapshot.docs;

        for (const teamDoc of teamDocs) {
          const teamData = teamDoc.data();
          const teamId = teamDoc.id;

          const driversSnapshot = await firebase
            .firestore()
            .collection("teams")
            .doc(teamId)
            .collection("drivers")
            .get();

          const driversData = driversSnapshot.docs.map((driverDoc) =>
            driverDoc.data()
          );
          teamData.drivers = driversData;

          teamsData.push(teamData);
        }

        setTeams(teamsData);
        setNewMessages(false);
        setLoading(false);
      });

    return () => {
      // Cleanup function to unsubscribe when component is unmounted
      if (subscribeRef.current) {
        subscribeRef.current(); // Invoke the unsubscribe function
        subscribeRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    const promises = teams.map(async (team) => {
      const snap = await firebase
        .firestore()
        .collection("messages")
        .doc(team.id)
        .get();
      if (snap.exists) {
        if (
          snap.data().messages.some((msg) => msg.readByDispatcher === false)
        ) {
          team.hasUnreadMessages = true;
        } else {
          team.hasUnreadMessages = false;
        }
      }

      let flag = false;
      const driverPromise = Promise.all(
        team.drivers.map(async (driver) => {
          const snap = await firebase
            .firestore()
            .collection("messages")
            // .doc(team.id)
            // .collection("drivers")
            .doc(driver.id)
            .get();

          console.log("------", snap.data());
          if (snap.exists) {
            if (
              snap.data().messages.some((msg) => msg.readByDispatcher === false)
            ) {
              driver.hasUnreadMessages = true;
              flag = true;
            } else {
              driver.hasUnreadMessages = false;
            }
          }

          return driver;
        })
      );

      const updatedDrivers = await driverPromise;
      if (flag) {
        team.hasUnreadMessages = true;
      }
      console.log(updatedDrivers);
      team.drivers = updatedDrivers;

      return team;
    });

    Promise.all(promises)
      .then((updatedTeams) => {
        // Now, updatedTeams contains the array with updated team objects
        console.log(updatedTeams);
        setUnreadTeams(updatedTeams);
      })
      .catch((error) => {
        // Handle any errors that occurred during the asynchronous operations
        console.error(error);
      });

    setNewMessages(false);
  }, [newMessages, teams]);

  const messageRef = useRef(null);
  const subscribeRef = useRef();

  const handleTeamClick = async (team) => {
    if (selectedDriver) {
      setSelectedDriver(null);
      setSelectedTeam(team);
    } else {
      // If no driver is selected, toggle the selected team
      console.log("273", team);
      setSelectedTeam((prevTeam) => (prevTeam === team ? null : team));
    }
    setLoading(true);
    if (subscribeRef.current) {
      subscribeRef.current(); // Invoke the unsubscribe function
      subscribeRef.current = null;
    }
    team.hasUnreadMessages = false;
    const docRef = firebase.firestore().collection("messages").doc(team.id);
    try {
      const unsubscribe = docRef.onSnapshot(async (doc) => {
        if (doc.exists) {
          const message = doc.data();
          const existingMessages = (await docRef.get()).data()?.messages || [];

          // Update the readByDispatcher property for all messages
          const updatedMessages = existingMessages.map((msg) => ({
            ...msg,
            readByDispatcher: true,
          }));

          // Update the entire messages array in Firestore
          await docRef.update({
            messages: updatedMessages,
          });
          setMessages(message.messages.reverse());
        } else {
          setMessages([]);
        }
        setLoading(false);
      });

      subscribeRef.current = unsubscribe;
    } catch (error) {
      console.error("Error updating readByDispatcher property:", error);
      setLoading(false);
    }
  };

  const handleDriverClicked = async (event, driver) => {
    event.stopPropagation();
    setSelectedDriver(driver);
    setLoading(true);

    if (subscribeRef.current) {
      subscribeRef.current(); // Invoke the unsubscribe function
      subscribeRef.current = null;
    }

    const docRef = firebase
      .firestore()
      .collection("messages")
      // .doc(selectedTeam.id)
      // .collection("drivers")
      .doc(driver.id);

    const existingMessages = (await docRef.get()).data()?.messages || [];

    // Update the readByDispatcher property for all messages
    const updatedMessages = existingMessages.map((msg) => ({
      ...msg,
      readByDispatcher: true,
    }));

    // Update the entire messages array in Firestore
    if ((await docRef.get()).exists) {
      await docRef.update({
        messages: updatedMessages,
      });
    }

    const unsubscribe = docRef.onSnapshot((doc) => {
      if (doc.exists) {
        const message = doc.data();
        setMessages(message.messages.reverse());
      } else {
        setMessages([]);
      }
      setLoading(false);
    });

    subscribeRef.current = unsubscribe;
  };
  // const handleDriverClicked = async (event, driver) => {
  //   event.stopPropagation();
  //   if (selectedDriver === driver) {
  //     // If the same driver is clicked again, deselect the driver
  //     setSelectedDriver(null);
  //     setLoading(true);

  //     if (subscribeRef.current) {
  //       subscribeRef.current(); // Invoke the unsubscribe function
  //       subscribeRef.current = null;
  //     }

  //     const docRef = firebase
  //       .firestore()
  //       .collection("messages")
  //       .doc(selectedTeam.id);

  //     const existingMessages = (await docRef.get()).data()?.messages || [];

  //     // Update the readByDispatcher property for all messages
  //     const updatedMessages = existingMessages.map((msg) => ({
  //       ...msg,
  //       readByDispatcher: true,
  //     }));

  //     // Update the entire messages array in Firestore
  //     if ((await docRef.get()).exists) {
  //       await docRef.update({
  //         messages: updatedMessages,
  //       });
  //     }
  //     const unsubscribe = docRef.onSnapshot(async (doc) => {
  //       if (doc.exists) {
  //         const message = doc.data();
  //         setMessages(message.messages.reverse());
  //       } else {
  //         setMessages([]);
  //       }
  //       setLoading(false);
  //     });
  //     subscribeRef.current = unsubscribe;
  //   } else {
  //     setSelectedDriver(driver);
  //     setLoading(true);
  //     if (subscribeRef.current) {
  //       subscribeRef.current(); // Invoke the unsubscribe function
  //       subscribeRef.current = null;
  //     }
  //     const docRef = firebase.firestore().collection("messages").doc(driver.id);
  //     const existingMessages = (await docRef.get()).data()?.messages || [];

  //     // Update the readByDispatcher property for all messages
  //     const updatedMessages = existingMessages.map((msg) => ({
  //       ...msg,
  //       readByDispatcher: true,
  //     }));

  //     // Update the entire messages array in Firestore
  //     if ((await docRef.get()).exists) {
  //       await docRef.update({
  //         messages: updatedMessages,
  //       });
  //     }
  //     const unsubscribe = docRef.onSnapshot((doc) => {
  //       if (doc.exists) {
  //         const message = doc.data();
  //         setMessages(message.messages.reverse());
  //       } else {
  //         setMessages([]);
  //       }
  //       setLoading(false);
  //     });
  //     subscribeRef.current = unsubscribe;
  //   }
  // };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent form submission
      handleSendClick();
    }
  };

  const convertTimeStamp = (timestamp) => {
    if (timestamp?.seconds) {
      const milliseconds =
        timestamp?.seconds * 1000 + timestamp?.nanoseconds / 1e6;
      const date = new Date(milliseconds);
      const options = { hour: "numeric", minute: "numeric" };
      const time = date.toLocaleTimeString([], options); // Convert the timestamp to local time string
      return time;
    } else {
      let hours = timestamp?.getHours();
      const minutes = timestamp?.getMinutes();

      // Determine whether it is AM or PM
      const meridiem = hours >= 12 ? "PM" : "AM";

      // Convert hours to 12-hour format
      hours = hours % 12 || 12;

      // Format the time as a string with leading zeros if necessary
      const time = `${String(hours).padStart(2, "0")}:${String(
        minutes
      ).padStart(2, "0")} ${meridiem}`;
      return time;
    }
  };

  const handleSendClick = () => {
    const id = selectedDriver ? selectedDriver.id : selectedTeam.id;
    const Name = selectedDriver ? selectedDriver.name : selectedTeam.name;

    if (selectedDriver) {
      // Save message to driver's subcollection under the team document
      firebase
        .firestore()
        .collection("messages")
        .doc(selectedDriver.id)
        .set(
          {
            id: selectedDriver.id,
            messages: firebase.firestore.FieldValue.arrayUnion({
              text: message,
              createdAt: new Date(),
              sentBy: user,
              readByDispatcher: true,
              readByOwner: false,
            }),
          },
          { merge: true }
        );
    } else {
      // Save message to the team document
      firebase
        .firestore()
        .collection("messages")
        .doc(id)
        .set(
          {
            id: id,
            messages: firebase.firestore.FieldValue.arrayUnion({
              text: message,
              createdAt: new Date(),
              sentBy: user,
              readByDispatcher: true,
              readByOwner: false,
            }),
          },
          { merge: true }
        );
    }

    logActivity(`${name} sent a message ["${message}"] to ${Name}`, "Users");
    emailNotifier(
      "NEW MESSAGE",
      `${name} sent a message ["${message}"] to ${Name}`
    );
    SendPushNotification("NEW MESSAGE", message, id);

    // Add the new message to the beginning of the messages array
    setMessages((prevMessages) => [
      { text: message, createdAt: new Date(), sentBy: user },
      ...prevMessages,
    ]);

    // Reset message input field
    setMessage("");
  };

  const dotStyle = {
    backgroundColor: "#7A5CD1",
    color: "white",
    borderRadius: "50%",
    width: "35px",
    height: "25px",
    display: "inline-block",
    marginRight: 0, // Align to the rightmost
    marginLeft: "8px", // Add your desired left margin
    marginTop: 1,
    fontSize: 12,
    textAlign: "center",
    padding: "5px",
  };
  useEffect(() => {
    console.log(teams, "ye team h");

    const driver = teams?.find((x) =>
      x?.drivers?.some((x) => x.id === info.id)
    );

    if (driver) {
      setSelectedTeam(driver);
      return;
    }

    if (Object.keys(info).length > 0 && teams.length > 0) {
      const exist = teams.find((x) => x.id === info?.driver?.admin);
      if (exist) {
        setSelectedTeam(exist);
        return;
      } else {
        const exist = teams.find((x) => x.id === info?.driver?.id);
        if (exist) {
          setSelectedTeam(exist);
          return;
        }
        return setSelectedTeam(null);
      }
    }
  }, [info, teams]);

  return (
    <div style={styles.messageScreen}>
      <div className="teamList" style={styles.teamList}>
        <h2 style={{ marginInline: "35%", color: "white" }}>Teams</h2>
        {teams.map((team, index) => (
          <>
            <div
              key={index}
              style={{
                ...styles.teamCard,
                ...(selectedTeam === team && styles.teamCardActive),
                backgroundColor:
                  team.drivers.length > 0
                    ? selectedTeam === team && selectedDriver == null
                      ? "#440072"
                      : "white"
                    : selectedTeam === team
                    ? "#440072"
                    : "white",
                color:
                  team.drivers.length > 0
                    ? selectedTeam === team && selectedDriver == null
                      ? "white"
                      : "black"
                    : selectedTeam === team
                    ? "white"
                    : "black",
              }}
              onClick={() => handleTeamClick(team)}
            >
              <h5 style={{ cursor: "pointer" }}>
                {team.name}{" "}
                {team.hasUnreadMessages && <div style={dotStyle}>NEW</div>}
              </h5>
              {selectedTeam === team && (
                <ul style={styles.driverList}>
                  {team.drivers.map((driver, driverIndex) => (
                    <li
                      onClick={(event) => handleDriverClicked(event, driver)} // Pass driver ID to the function
                      style={{
                        ...styles.teamCard,
                        backgroundColor:
                          selectedDriver === driver ? "#440072" : "white",
                        color: selectedDriver === driver ? "white" : "black",
                      }}
                      key={driverIndex}
                      value={driver.id}
                    >
                      {driver.name}
                      {driver.hasUnreadMessages && (
                        <div style={dotStyle}>NEW</div>
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </>
        ))}
      </div>
      <div style={styles.messageContainer}>
        <div style={{ ...styles.header, marginBottom: 0 }}>
          {selectedDriver !== null && (
            <>
              <h2 style={{ color: "#7A5CD1" }}>
                {selectedDriver.name}
                <span style={{ fontSize: "14px", marginLeft: "8px" }}>
                  ({selectedTeam.companyName})
                </span>
              </h2>
              <div style={styles.Line} />
            </>
          )}
          {selectedDriver === null && selectedTeam !== null && (
            <>
              <h2 style={{ color: "#7A5CD1" }}>{selectedTeam.name}</h2>
              <div style={styles.Line} />
            </>
          )}
        </div>
        {loading ? (
          <CircularProgress
            size={80}
            style={{ alignSelf: "absolute", margin: "40%" }}
          />
        ) : (
          <div style={styles.messageContainerWrapper}>
            <div className="teamList" style={styles.whiteBox} ref={messageRef}>
              {selectedDriver === null && selectedTeam === null ? (
                <></>
              ) : (
                messages.map((msg, index) => (
                  <div
                    key={index}
                    style={
                      msg.sentBy !== "Admin"
                        ? styles.messageItem
                        : styles.selfMessageItem
                    }
                  >
                    <p style={{ fontSize: 10, marginTop: -2 }}>
                      {convertTimeStamp(msg.createdAt)}
                    </p>
                    <p
                      style={{ fontSize: 14, marginTop: -15, marginBottom: 0 }}
                    >
                      {msg.text}
                    </p>
                    {msg.sentBy === "Admin" ? (
                      <p style={{ fontSize: 10, marginBottom: -5 }}>
                        Sent By: {msg.sentBy}
                      </p>
                    ) : null}
                  </div>
                ))
              )}
            </div>
          </div>
        )}
        <div style={styles.bottomBox}>
          <input
            type="text"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            style={styles.messageInput}
            placeholder="Type your message..."
            onKeyDown={handleKeyPress}
            disabled={selectedDriver === null && selectedTeam === null}
          />
          <button
            disabled={selectedDriver === null && selectedTeam === null}
            style={styles.sendButton}
            onClick={handleSendClick}
          >
            Send
          </button>
        </div>
      </div>
    </div>
  );
};

export default Message;
