/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalTitle } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import firebase from "./../firebase/firebase";
import { DataGrid } from "@mui/x-data-grid";
import AndroidIcon from '../img/android.png';
import AppleIcon from '../img/ios.png';
const teamRef = firebase.firestore().collection("teams");

export default function TeamDriversModal({
  showModal = false,
  onHide = () => {},
  selectedItem = {},
}) {
  const [teamMembers, setTeamMembers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(50);
  console.log(teamMembers,"uu")
  useEffect(() => {
    if (selectedItem?.id) {
      getTeamMembers();
    } else {
      setTeamMembers([]);
    }
  }, [selectedItem]);
  const getPlatformIcon = (platform) => {
    if (platform !== undefined) {
      switch (platform?.toLowerCase()) {
        case 'ios':
          return <img src={AppleIcon} alt="Apple Icon" />;
        case 'android':
          return <img src={AndroidIcon} alt="Android Icon" />;
        default:
          return null;
      }
    }
  };
  const getTeamMembers = () => {
    setLoading(true);
    teamRef
      .doc(selectedItem?.id)
      .collection("drivers")
      .get()
      .then((res) => {
        if (!res.empty) {
          const members = res.docs.map((doc) => doc.data());
          setTeamMembers(members);
        } else {
          setTeamMembers([]);
        }
        setLoading(false);
      });
  };
  const addDriver = (item) => {
    teamRef
      .where("ownerId", "==", selectedItem?.id.toString())
      .where("id", "==", item?.id?.toString())
      .get()
      .then((res) => {
        if (!res.empty) {
          alert("Driver already added to team");
        } else {
          item["ownerId"] = selectedItem?.id;
          teamRef?.add(item).then((res) => {
            getTeamMembers();
          });
        }
      });
  };

  return (
    <div>
      <Modal
        show={showModal}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        fullscreen={true}
        onHide={() => {
          onHide();
        }}
      >
        <ModalHeader closeButton>
          <ModalTitle>Team Drivers</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <div
            style={{
              height: "70vh",
              width: "95%",
              margin: "0 auto",
              marginTop: 30,
            }}
          >
            <DataGrid
              loading={loading}
              rows={teamMembers}
              columns={[
                {
                  headerName: "Name",
                  field: "name",
                  flex: 1,
                  minWidth: 200,
                },
                {
                  headerName: 'Platform',
                  field: 'platform',
                  flex: 1,
                  minWidth: 100,
                  renderCell: ({ value }) => {
                    const platformIcon = getPlatformIcon(value);
                    return <div>{platformIcon}</div>;
                  },
                },
                {
                  headerName: "Email",
                  field: "email",
                  flex: 1,
                  minWidth: 150,
                },
                {
                  headerName: "Phone Number",
                  field: "phone",
                  flex: 1,
                  minWidth: 100,
                  type: "number",
                },
                {
                  headerName: "credits",
                  field: "credit",
                  flex: 1,
                  minWidth: 150,
                },
                {
                  headerName: "Role",
                  field: "role",
                  flex: 1,
                  minWidth: 150,
                },
              ]}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[5, 10, 20, 50]}
              pagination
              autoPageSize
            />
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}
