/* eslint-disable */
import React, { useEffect, useState } from "react";
import firebase from "./../firebase/firebase";
import { DataGrid } from "@mui/x-data-grid";
import { Button } from "@mui/material";
import EditTeamModal from "../customViews/EditTeamModal";
import TeamDriversModal from "../customViews/TeamDriversModal";
import AddDriverModal from "../customViews/AddDriverModal";
import AndroidIcon from "../img/android.png";
import AppleIcon from "../img/ios.png";
import { TeamLogmodel } from "../customViews/TeamLogmodel";
import AgreementModal from "../customViews/AgreementModal";
import { useSelector } from "react-redux";

export default function Team() {
  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(50);
  const [editModalShow, setEditModalShow] = useState(false);
  const [addModalShow, setAddModalShow] = useState(false);
  const [driverModalShow, setDriverModalShow] = useState(false);
  const [teamModelshow, setteamModelshow] = useState(false);
  const [agreementModalshow, setAgreementModalShow] = useState(false);
  const [addDriverModalShow, setAddDriverModalShow] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const {info}=useSelector((state)=>state.tabs)
  // MENU STATES
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (Object.keys(info).length>0) {
      getAllTeams(true);
      
    }else{
      
      getAllTeams(false);
    }
  }, []);

  const getPlatformIcon = (platform) => {
    if (platform !== undefined) {
      switch (platform?.toLowerCase()) {
        case "ios":
          return <img src={AppleIcon} alt="Apple Icon" />;
        case "android":
          return <img src={AndroidIcon} alt="Android Icon" />;
        default:
          return null;
      }
    }
  };

  const isSubscriptionExpired = (subscriptionPlan) => {
    if (subscriptionPlan) {
      const today = new Date();
      const expiry = subscriptionPlan.expirationDate.toDate();

      return expiry < today;
    }
    return true;
  };

  const getAllTeams = async (status) => {
    setLoading(true);
    const userRef = await firebase.firestore().collection("teams");
    userRef.where("role", "==", "O/O").onSnapshot((snapshot) => {
      let temp = [];
      snapshot.docs.forEach((a) => {
        console.log(a.id);
        temp.push(a.data());
      });
      setLoading(false);
      let exist;
      if (temp?.length>0) {
        console.log(info,"jj",temp)
        if (status) {
          if (info?.driver) {
            if (info.driver.admin) {
              exist =temp.filter((x) => x?.id === info?.driver?.admin);
              
            }else{
              
              exist =temp.filter((x) => x?.id === info?.driver?.id);
            }
            
          }else{
            if (info.admin) {
              exist =temp.filter((x) => x?.id === info?.admin);
              
            }else{
              
              exist =temp.filter((x) => x?.id === info?.id);
            }
            
  
          }
          
        if (exist) {
       setTeams(exist)
      if(info){
        
        setSelectedTeam(exist[0]);
        setDriverModalShow(true);
        
      }
      return
    }
    // else{
    //   console.log("else")
    //   const exist=temp.filter((x)=>x.id===info?.id)
    //   if (exist) {
    //     setTeams(exist)
    //     // console.log("yes else")
    //     if(info?.driversview){
    //       setSelectedTeam(exist[0]);
    //       setDriverModalShow(true);
        
    //     }
    //     return
    //   }
    // }
                 
    return setTeams([])
    
  
  
          
        }else{
          setTeams(temp);
  
        }
        
      }
      // console.log(temp,"ye ha team")
    });
  };

  return (
    <div className="table-t">
      <div className="table-load">
        <div className="row">
          <h1 className="col-md-10">Team</h1>
          <Button
            className="btn-blue col-md-2"
            onClick={() => setAddModalShow(true)}
            style={{
              height: 60,
              backgroundColor: "#7A5CD1",
              color: "#fff",
            }}
          >
            Add New Team
          </Button>
        </div>

        <div
          style={{
            height: "70vh",
            width: "95%",
            margin: "0 auto",
            marginTop: 30,
          }}
        >
          <DataGrid
            loading={loading}
            rows={teams}
            columns={[
              {
                headerName: "TEAM NAME",
                field: "companyName",
                flex: 1,
                minWidth: 200,
              },
              {
                headerName: "OWNER NAME",
                field: "name",
                flex: 1,
                minWidth: 200,
              },
              {
                headerName: "Platform",
                field: "platform",
                flex: 1,
                minWidth: 100,
                renderCell: ({ value }) => {
                  const platformIcon = getPlatformIcon(value);
                  return <div>{platformIcon}</div>;
                },
              },
              {
                headerName: "Email",
                field: "email",
                flex: 1,
                minWidth: 150,
              },
              {
                headerName: "Phone Number",
                field: "phone",
                flex: 1,
                minWidth: 100,
                type: "number",
              },
              {
                headerName: "Role",
                field: "role",
                flex: 1,
                minWidth: 150,
              },
              {
                headerName: "MC#",
                field: "mcNumber",
                flex: 1,
                minWidth: 120,
              },
              {
                headerName: "DOT#",
                field: "dotNumber",
                flex: 1,
                minWidth: 150,
              },
              {
                headerName: "credit",
                field: "credit",
                flex: 1,
                minWidth: 150,
              },
              {
                headerName: "Approved Status",
                field: "isVerified",
                flex: 1,
                minWidth: 150,
                renderCell: ({ row }) => {
                  return (
                    <p
                      className={
                        row.isVerified
                          ? "btn btn-success mt-3"
                          : "btn btn-danger mt-3"
                      }
                    >
                      {row.isVerified ? "verfied" : "not verified"}
                    </p>
                  );
                },
              },
              {
                headerName: "Carrier Profile",
                field: "carrierProfileComplete",
                flex: 1,
                minWidth: 150,
                renderCell: ({ row }) => {
                  return (
                    <p
                      className={
                        row.carrierProfileComplete
                          ? "btn btn-success mt-3"
                          : "btn btn-danger mt-3"
                      }
                    >
                      {row.carrierProfileComplete ? "Complete" : "Incomplete"}
                    </p>
                  );
                },
              },
              {
                headerName: "Subscription",
                field: "subscription",
                flex: 1,
                minWidth: 200,
                renderCell: ({ row }) => {
                  return (
                    <p
                      className={
                        !row?.subscriptionPlan
                          ? "btn btn-danger mt-3"
                          : isSubscriptionExpired(row.subscriptionPlan)
                          ? "btn btn-danger mt-3"
                          : "btn btn-success mt-3"
                      }
                    >
                      {!row?.subscriptionPlan
                        ? "Not Purchased Yet"
                        : isSubscriptionExpired(row.subscriptionPlan)
                        ? "Expired"
                        : "Active"}
                    </p>
                  );
                },
              },
              {
                headerName: "Actions",
                field: "",
                flex: 1,
                minWidth: 650,
                sortable: false,
                renderCell: ({ row }) => {
                  return (
                    <div>
                      <button
                        className="btn btn-primary"
                        style={{
                          backgroundColor: "#7A5CD1",
                        }}
                        onClick={() => {
                          setSelectedTeam(row);
                          setAgreementModalShow(true);
                        }}
                      >
                        Agreement
                      </button>
                      <button
                        className="btn btn-primary m-2"
                        style={
                          {
                            // backgroundColor: "#7A5CD1"
                          }
                        }
                        onClick={() => {
                          setSelectedTeam(row);
                          setEditModalShow(true);
                        }}
                      >
                        Edit Team
                      </button>
                      <button
                        className="btn btn-primary m-2"
                        onClick={() => {
                          setSelectedTeam(row);
                          setDriverModalShow(true);
                        }}
                      >
                        View Drivers
                      </button>
                      <button
                        className="btn btn-primary m-2"
                        onClick={() => {
                          setSelectedTeam(row);
                          setteamModelshow(true);
                        }}
                      >
                        View Logs
                      </button>
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          setSelectedTeam(row);
                          setAddDriverModalShow(true);
                        }}
                      >
                        Add or Edit Driver
                      </button>
                    </div>
                  );
                },
              },
            ]}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[5, 10, 20, 50]}
            pagination
            autoPageSize
          />
        </div>
      </div>
      <AgreementModal
        showModal={agreementModalshow}
        selectedItem={selectedTeam}
        onCloseModal={() => {
          setAgreementModalShow(false);
          setSelectedTeam(null);
          // getAllTeams();
        }}
      />
      <EditTeamModal
        showModal={editModalShow || addModalShow}
        showEditModal={editModalShow}
        showAddModal={addModalShow}
        selectedItem={selectedTeam}
        onHide={() => {
          setEditModalShow(false);
          setAddModalShow(false);
          setSelectedTeam(null);
          getAllTeams();
        }}
      />
      <TeamDriversModal
        showModal={driverModalShow}
        selectedItem={selectedTeam}
        onHide={() => {
          setDriverModalShow(false);
          setSelectedTeam(null);
        }}
      />
      <TeamLogmodel
        showBidModal={teamModelshow}
        selectedItem={selectedTeam}
        onCloseModal={() => {
          setteamModelshow(false);
          setSelectedTeam(null);
        }}
      />
      <AddDriverModal
        showModal={addDriverModalShow}
        selectedItem={selectedTeam}
        onHide={() => {
          setAddDriverModalShow(false);
          setSelectedTeam(null);
        }}
      />
    </div>
  );
}
