import React, { useState, useEffect } from "react";
import { TextField, Button, Box } from "@mui/material";
import firebase from "./../firebase/firebase";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const ScrappersAuth = () => {
  const [credentials, setCredentials] = useState({
    virtualFleetCookie: "",
    truckersEdgeCookie: "",
    loadboardCookie: "",
  });

  useEffect(() => {
    const authorization = firebase.firestore().collection("authorization");
    const scrappersCredentialsCollection = authorization.doc(
      "scrappers_credentials"
    );

    scrappersCredentialsCollection
      .get()
      .then((doc) => {
        if (doc.exists) {
          const data = doc.data().code;
          setCredentials({
            virtualFleetCookie: data.virtual_fleet_cookie || "",
            truckersEdgeCookie: data.truckers_edge_auth || "",
            loadboardCookie: data.loadboard_cookie || "",
          });
        } else {
          console.log("No such document!");
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCredentials((prevCredentials) => ({
      ...prevCredentials,
      [name]: value,
    }));
  };

  const handleUpdateClick = () => {
    const authorization = firebase.firestore().collection("authorization");
    const scrappersCredentialsCollection = authorization.doc(
      "scrappers_credentials"
    );

    scrappersCredentialsCollection
      .update({
        code: {
          virtual_fleet_cookie: credentials.virtualFleetCookie,
          truckers_edge_auth: credentials.truckersEdgeCookie,
          loadboard_cookie: credentials.loadboardCookie,
        },
      })
      .then(() => {
        console.log("Cookies updated successfully!");
        toast.success("Cookies have been updated!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      })
      .catch((error) => {
        console.error("Error updating cookies: ", error);
        toast.error("Error updating cookies!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      });
  };

  return (
    <Box sx={{ maxWidth: 400, margin: "auto", padding: 2 }}>
      <ToastContainer />
      <TextField
        fullWidth
        label="Virtual Fleet Cookie"
        variant="outlined"
        name="virtualFleetCookie"
        value={credentials.virtualFleetCookie}
        onChange={handleInputChange}
        placeholder="Enter Virtual Fleet Cookie"
        margin="normal"
        sx={{ marginBottom: 2 }}
      />
      <TextField
        fullWidth
        label="Truckers Edge Cookie"
        variant="outlined"
        name="truckersEdgeCookie"
        value={credentials.truckersEdgeCookie}
        onChange={handleInputChange}
        placeholder="Enter Truckers Edge Cookie"
        margin="normal"
        sx={{ marginBottom: 2 }}
      />
      <TextField
        fullWidth
        label="Loadboard Cookie"
        variant="outlined"
        name="loadboardCookie"
        value={credentials.loadboardCookie}
        onChange={handleInputChange}
        placeholder="Enter Loadboard Cookie"
        margin="normal"
        sx={{ marginBottom: 2 }}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleUpdateClick}
        fullWidth
      >
        Update Cookies
      </Button>
    </Box>
  );
};

export default ScrappersAuth;
