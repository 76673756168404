import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/auth";
import "firebase/compat/messaging";
// OUR FIREBASE CONFIG
// export const firebaseConfig = {
//     apiKey: "AIzaSyBD_J6-ovEQtF6B2Oco5HG5t22glfb29ms",
//     authDomain: "gohaul-57af5.firebaseapp.com",
//     projectId: "gohaul-57af5",
//     storageBucket: "gohaul-57af5.appspot.com",
//     messagingSenderId: "677557987365",
//     appId: "1:677557987365:web:6e9ab9f72086f19da877cc"
// };

// CLIENT FIREBASE CONFIG
export const firebaseConfig = {
  apiKey: "AIzaSyCJW3h82EnqFUsaQBzNi-yRD2zZtf-02lQ",
  authDomain: "gohaul-development-db.firebaseapp.com",
  projectId: "gohaul-development-db",
  storageBucket: "gohaul-development-db.appspot.com",
  messagingSenderId: "333963226332",
  appId: "1:333963226332:web:7260df7da120472bb85819",
  measurementId: "G-Z815E9J9E4",
};

if (!firebase.apps.length) {
  // firebase.initializeApp({});
  firebase.initializeApp(firebaseConfig);
} else {
  firebase.app(); // if already initialized, use that one
}

export default firebase;
