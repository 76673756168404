/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Marker, InfoWindow } from "@react-google-maps/api";
import gohaullogo from "../../img/gohaul-logo.png";
import "./../../App.css";
import moment from "moment";
import useDistance from "../../hooks/useDistance";
import Geocode from "react-geocode";
import SendPushNotification from "../../firebase/SendPushNotification";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { setActiveTab } from "../../stores/actions/tabs.action";
import { useDispatch } from "react-redux";
import mark from "../desPin.png";
import mark2 from "../pickupPin.png";
import {
  Button,
  Card,
  CardContent,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";

function MarkerWithInfo(props) {
  const [showInfo, setshowInfo] = useState(false);
  const [city, setCity] = useState();
  const [zip, setZip] = useState();
  const [country, setCountry] = useState();
  const [state, setState] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [timeout, setTime] = useState();
  const trackingURL = process.env.REACT_APP_TRACKING_URL;
  const dispatch = useDispatch();

  const { distance, estimatedTime } = useDistance({
    originLat: props.list?.location?.latitude,
    originLng: props.list?.location?.longitude,
    destination: props.list?.destination,
    isShow: showInfo,
  });

  const showRoute = () => {
    getLocationDate(props.list);
    if (props.setRoute) {
      props?.setRoute(props?.list);
      setshowInfo(!showInfo);
    }
  };

  useEffect(() => {
    // console.log("aaaaaa", props.show);
    setshowInfo(props.show);
  }, [props.show]);

  // useEffect(() => {
  //   if (props.list.location) {
  //     getLocationDate(props.list);
  //   }
  // }, [props.list.driver ? props.list.coords : props.list.location]);

  const sendPing = () => {
    setIsLoading(true);

    SendPushNotification(
      "Ping",
      "Ping",
      props?.list?.driver?.id ? props?.list?.driver?.id : props?.list?.id
    );
    setTime(
      setTimeout(() => {
        alert("User is offline!");
        setIsLoading(false);
      }, 5000)
    );
  };

  useEffect(() => {
    setIsLoading(false);
    clearTimeout(timeout);
  }, [props?.list?.location?.lastPing]);

  const getLocationDate = (data) => {
    Geocode.setApiKey("AIzaSyCjysO3MF1vStj7BR3IRTAOOvLa3O6bark");
    Geocode.setLocationType("ROOFTOP");
    Geocode.fromLatLng(
      data?.location?.latitude,
      data?.location?.longitude
    ).then(
      (response) => {
        let zip, city, state, country;
        for (
          let i = 0;
          i < response.results[0].address_components.length;
          i++
        ) {
          for (
            let j = 0;
            j < response.results[0].address_components[i].types.length;
            j++
          ) {
            switch (response.results[0].address_components[i].types[j]) {
              case "locality":
                city = response.results[0].address_components[i].long_name;
                break;
              case "administrative_area_level_1":
                state = response.results[0].address_components[i].long_name;
                break;
              case "country":
                country = response.results[0].address_components[i].long_name;
                break;
              case "postal_code":
                zip = response.results[0].address_components[i].long_name;
                break;
            }
          }
        }
        setCity(city);
        setZip(zip);
        setCountry(country);
        setState(state);
        return <></>;
      },
      (error) => {
        console.error(error);
      }
    );
  };

  const convertTimestamp = (timeStamp) => {
    if (timeStamp) {
      const inputDate = new Date(timeStamp.seconds * 1000);
      // const time = date.toTimeString().split(" ")[0];
      // return date.toDateString() + " " + time;
      const day = inputDate.getDate().toString().padStart(2, "0");
      const month = (inputDate.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-indexed, so add 1
      const year = inputDate.getFullYear();
      const hours = inputDate.getHours().toString().padStart(2, "0");
      const minutes = inputDate.getMinutes().toString().padStart(2, "0");
      const period = hours >= 12 ? "pm" : "am";

      const formattedDate = `${day}-${month}-${year} ${hours}:${minutes} ${period}`;
      return formattedDate;
    }
  };

  const updateTimeAgo = (timestamp) => {
    const now = new Date();
    const timestampDate = new Date(timestamp.seconds * 1000); // Convert timestamp to milliseconds

    const timeDifference = now - timestampDate;
    const minutes = Math.floor(timeDifference / 60000); // 1 minute = 60000 milliseconds
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    const days = Math.floor(hours / 24);

    let timeAgo;

    if (minutes < 1) {
      timeAgo = "Just now";
    } else if (minutes < 60) {
      timeAgo = `${minutes} ${minutes === 1 ? "minute" : "minutes"} ago`;
    } else if (hours < 24) {
      if (hours === 1 && remainingMinutes === 0) {
        timeAgo = "1 hour ago";
      } else if (hours === 1 && remainingMinutes > 0) {
        timeAgo = `1 hour ${remainingMinutes} minutes ago`;
      } else {
        timeAgo = `${hours} hours ago`;
      }
    } else {
      if (days === 1) {
        timeAgo = "1 day ago";
      } else {
        timeAgo = `${days} days ago`;
      }
    }

    return timeAgo;
  };

  const DriverTomessage = (x) => {
    dispatch(setActiveTab("Messages", x));
  };
  const DriverToTeam = (x) => {
    dispatch(setActiveTab("Team", x));
  };
  const DriverToHistory = (x) => {
    dispatch(setActiveTab("Loads History", x));
  };
  const DriverToTeamAlldrivers = (x) => {
    dispatch(setActiveTab("Team", { ...x, driversview: true }));
  };

  const myicon = () => {
    let data = props?.list;
    if (data.role === "O/O") {
      return props.iconMarker3;
    }
    if (data.role === "Driver") {
      return props.iconMarker2;
    }
    return props.iconMarker;
  };

  console.log(props.list);

  const searchStartDate = props.list?.searchstartDate;
  const date = new Date(
    searchStartDate?.seconds * 1000 + searchStartDate?.nanoseconds / 1000000
  );
  const formattedDate = moment(date).format("MM-DD-YYYY");
  const formattedTime = moment(date).format("h:mm A");

  console.log(formattedDate, formattedTime);

  return (
    <>
      <Marker
        options={{ marginTop: "-20px" }}
        position={
          props.list.coords
            ? props.list.coords
            : {
                lng: props?.list?.location?.longitude,
                lat: props?.list?.location?.latitude,
              }
        }
        icon={myicon()}
        onClick={() => {
          showRoute();
        }}
      />
      {!props.iconMarker2 && (
        <>
          <Marker
            options={{ marginTop: "-20px" }}
            position={{
              lng: props?.list?.pickUpCord?.longitude,
              lat: props?.list?.pickUpCord?.latitude,
            }}
            icon={
              new google.maps.MarkerImage(
                mark,
                new google.maps.Size(280, 260),
                null,
                new google.maps.Point(0, 30)
              )
            }
            onClick={() => {
              showRoute();
            }}
          />
          <Marker
            options={{ marginTop: "-20px" }}
            position={{
              lng: props?.list?.dropOffCord?.longitude,
              lat: props?.list?.dropOffCord?.latitude,
            }}
            icon={
              new google.maps.MarkerImage(
                mark2,
                new google.maps.Size(280, 260),
                null,
                new google.maps.Point(0, 30)
              )
            }
            onClick={() => {
              showRoute();
            }}
          />
        </>
      )}

      {showInfo ? (
        props.list?.driver ? (
          <InfoWindow
            position={
              props.list.coords
                ? props.list.coords
                : {
                    lng: props?.list?.location?.longitude,
                    lat: props?.list?.location?.latitude,
                  }
            }
          >
            <Card>
              <CardContent>
                <Typography variant="h5" component="div" sx={{ mb: 2 }}>
                  {props?.list?.status ? props?.list?.status : props?.list.role}
                </Typography>
                <Box display="flex" justifyContent="center" mb={2}>
                  <img
                    src={gohaullogo}
                    alt="Logo"
                    style={{ width: "100px", height: "auto" }}
                  />
                </Box>
                <Box display="flex" justifyContent="center" mb={2}>
                  <Button
                    variant="contained"
                    color="success"
                    sx={{ mx: 1 }}
                    onClick={() => DriverToTeam(props?.list)}
                  >
                    Team
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ mx: 1 }}
                    onClick={() => DriverTomessage(props?.list)}
                  >
                    Message
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{ mx: 1 }}
                    onClick={() => DriverToHistory(props?.list)}
                  >
                    Load History
                  </Button>
                </Box>
                <Box textAlign="center" mb={2}>
                  <CopyToClipboard
                    text={trackingURL + props?.list?.loadId}
                    onCopy={() => alert("Link Copied!")}
                  >
                    <Button variant="outlined" color="primary">
                      Copy Link
                    </Button>
                  </CopyToClipboard>
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  mb={2}
                  p={2}
                  bgcolor="grey.100"
                  borderRadius={2}
                >
                  <Box>
                    <Typography variant="subtitle1" color="textSecondary">
                      Driver
                    </Typography>

                    <Typography variant="subtitle1" color="textSecondary">
                      Pick-Up
                    </Typography>

                    <Typography variant="subtitle1" color="textSecondary">
                      Drop-Off
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary">
                      Truck Length
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary">
                      Truck Weight
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary">
                      Price
                    </Typography>
                  </Box>

                  <Box textAlign="right">
                    <Typography variant="subtitle1" fontWeight="bold">
                      {props?.list?.driver?.name
                        ? props?.list?.driver?.name
                        : props?.list?.name}
                    </Typography>

                    <Typography variant="subtitle1" fontWeight="bold">
                      {props.list?.pickup}
                    </Typography>

                    <Typography variant="subtitle1" fontWeight="bold">
                      {props.list?.destination}
                    </Typography>

                    <Typography variant="subtitle1" fontWeight="bold">
                      {props.list?.truckLength} ft
                    </Typography>
                    <Typography variant="subtitle1" fontWeight="bold">
                      {props.list?.loadWeight} lbs
                    </Typography>
                    <Typography variant="subtitle1" fontWeight="bold">
                      ${props.list?.cost?.driverBid}
                    </Typography>
                  </Box>
                </Box>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>Duration:</TableCell>
                      <TableCell>
                        {moment(
                          new Date(
                            props?.list?.loadDate?.seconds * 1000 +
                              props?.list?.loadDate?.nanoseconds / 1000000
                          ).get
                        ).fromNow()}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Pick-up Date:</TableCell>
                      <TableCell>{formattedDate}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Pick-up Hour:</TableCell>
                      <TableCell>{formattedTime}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>Truck type:</TableCell>
                      <TableCell>{props.list?.truckType}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Speed:</TableCell>
                      <TableCell>
                        {Math.floor(
                          Number(props.list?.location?.speed) * 0.6213711922
                        )}{" "}
                        mph
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Distance:</TableCell>
                      <TableCell>{distance}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Estimated Time:</TableCell>
                      <TableCell>{estimatedTime}</TableCell>
                    </TableRow>
                    {/* <TableRow>
                      <TableCell>Comment:</TableCell>
                      <TableCell>{props.list?.comment}</TableCell>
                    </TableRow> */}
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          </InfoWindow>
        ) : (
          <InfoWindow
            className="myinfo"
            position={
              props.list.coords
                ? props.list.coords
                : {
                    lng: props?.list?.location?.longitude,
                    lat: props?.list?.location?.latitude,
                  }
            }
          >
            <Card>
              <CardContent>
                <Box display="flex" justifyContent="center" mb={2}>
                  <img
                    src={gohaullogo}
                    alt="Logo"
                    style={{ width: "100px", height: "auto" }}
                  />
                </Box>
                <Box display="flex" justifyContent="center" mb={2}>
                  <Button
                    variant="contained"
                    color="success"
                    sx={{ mx: 1 }}
                    onClick={() => DriverToTeam(props?.list)}
                  >
                    Team
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ mx: 1 }}
                    onClick={() => DriverTomessage(props?.list)}
                  >
                    Message
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{ mx: 1 }}
                    onClick={() => DriverToHistory(props?.list)}
                  >
                    Load History
                  </Button>

                  {props?.list?.role !== "Driver" && (
                    <Button
                      variant="contained"
                      color="secondary"
                      sx={{ mx: 1 }}
                      onClick={() => DriverToTeamAlldrivers(props?.list)}
                    >
                      All Drivers
                    </Button>
                  )}
                </Box>

                <Box textAlign="center" mb={2}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      props?.setRoute(null);
                      setshowInfo(!showInfo);
                    }}
                  >
                    Cancel
                  </Button>

                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      if (!isLoading) {
                        sendPing();
                      }
                    }}
                    className={`ping-button ${isLoading ? "loading" : ""}`}
                  >
                    {isLoading ? <div className="loader"></div> : "Ping"}
                  </Button>
                </Box>

                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>Name:</TableCell>
                      <TableCell>{props.list.name}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Company Name:</TableCell>
                      <TableCell>{props.list.companyName}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Speed:</TableCell>
                      <TableCell>
                        {" "}
                        {Math.round(
                          Number(props?.list?.location?.speed) * 2.23694
                        )}{" "}
                        mph
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>Zip Code:</TableCell>
                      <TableCell>{zip}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>City:</TableCell>
                      <TableCell>{city}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>State:</TableCell>
                      <TableCell>{state}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Country:</TableCell>
                      <TableCell>{country}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>Last Interval Update:</TableCell>
                      <TableCell>
                        {
                          convertTimestamp(
                            props?.list?.location?.lastUpdate
                          )?.split(" ")[0]
                        }
                        <br />
                        {convertTimestamp(
                          props?.list?.location?.lastUpdate
                        )?.split(" ")[1] +
                          " " +
                          convertTimestamp(
                            props?.list?.location?.lastUpdate
                          )?.split(" ")[2]}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Last Pinged:</TableCell>
                      <TableCell>
                        {" "}
                        {props?.list?.location?.lastPing
                          ? updateTimeAgo(props?.list?.location?.lastPing)
                          : "Never Pinged"}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          </InfoWindow>
        )
      ) : (
        <></>
      )}
    </>
  );
}

export default React.memo(MarkerWithInfo);
