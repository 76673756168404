import React, { Component } from "react";
// import trash from '../img/trash.png'
// import profile from '../img/profile.jpeg'
// import Truck from '../img/Frame.png'
// import Deliverd from './dliveredlist'
import firebase from "./../firebase/firebase";
import { Modal, ModalBody, ModalTitle } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";

class CustomChatBot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      list: [],
      showModal: false,
      selectedItem: null,
      showBidModal: false,
      showAssignModal: false,
      value: "",
      gettext: "",
    };
    this.handleChange = this.handleChange.bind(this);
  }
  getAllSearches = async () => {
    let ref = await firebase.firestore().collection("CustomChatBot");
    ref.onSnapshot((snap) => {
      snap.docs.forEach((a) => {
        this.setState({ gettext: a.data().text });
      });
    });
  };

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  sumbit = () => {
    let botdata = {
      text: this.state.value,
    };
    firebase
      .firestore()
      .collection("CustomChatBot")
      .doc("LzS711QpS5W2CiPryF3r")
      .set(botdata)
      .then(() => {
        this.setState({ showModal: false });
        // alert('Chat Bot updated');
      });
  };

  async componentDidMount() {
    this.getAllSearches();
  }
  render() {
    return (
      <div>
        <div className="load-table table-load">
          <h1>Custom Chat Bot</h1>
          <p>{this.state.gettext}</p>
          <button
            className="btn-bluenew"
            onClick={() => this.setState({ showModal: true })}
          >
            Add New
          </button>
        </div>
        <Modal
          show={this.state.showModal}
          // size="lg"
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
          fullscreen={false}
          onHide={() => {
            this.setState({
              showModal: false,
            });
          }}
        >
          <ModalHeader closeButton>
            <ModalTitle>Add a Message</ModalTitle>
          </ModalHeader>
          <ModalBody>
            <textarea
              className={"custom-input"}
              type={"text"}
              value={this.state.value}
              onChange={this.handleChange}
            />
            <button className="btn-bluenew" onClick={this.sumbit}>
              Add New
            </button>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
export default CustomChatBot;
