import React, { Component } from "react";
import PropTypes from "prop-types";
import firebase from "./../firebase/firebase";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import { Modal, ModalBody, ModalTitle } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import { connect } from "react-redux";

const RatingStars = ({ rating }) => {
  if (!rating) return null;

  const stars = [];
  for (let i = 0; i < 5; i++) {
    if (i < rating) {
      stars.push(
        <span key={i} style={{ color: "gold" }}>
          &#9733;
        </span>
      );
    } else {
      stars.push(
        <span key={i} style={{ color: "lightgray" }}>
          &#9734;
        </span>
      );
    }
  }
  return <div>{stars}</div>;
};

class Load_request extends Component {
  static propTypes = {
    children: PropTypes.instanceOf(Array).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      list: [],
      showModal: false,
      selectedItem: null,
      showBidModal: false,
      pageSize: 50,
      activeTab: this.props.activeTab,
      info: this.props.info,
    };
  }

  getAllSearches = async () => {
    this.setState({ loading: true });
    let ref = await firebase.firestore().collection("loadsHistory");
    ref.onSnapshot((snap) => {
      if (snap.empty) {
        this.setState({ loading: false });
      }
      let temp = [];
      snap.docs.forEach(async (a) => {
        let getOffers = await firebase
          .firestore()
          .collection("loadsHistory")
          .doc(a.id)
          .collection("completed")
          .get();
        getOffers.docs.forEach((docs) => {
          temp = [...temp, { id: docs.id, ...docs.data() }];
        });
        console.log(this.state.info, "pop", temp);
        let info = this.state.info;
        if (Object.keys(info).length > 0) {
          const exist = temp.filter((x) => x?.driver?.id === info.id);

          console.log("user----", info.id);

          console.log("array of if condition ", temp);
          if (exist) {
            console.log(exist,"ex1")
            this.setState({
              list: exist,
              loading: false,
            });
            return;
          } else {
            // const exist = temp.filter((x) => x.id === info?.driver?.id);
            // console.log("array of else ", temp);
            // console.log("++++++++++++///", info?.driver?.id);
            // if (exist) {
            //   this.setState({
            //     list: exist,
            //     loading: false,
            //   });
            //   return;
            // }
          }
          this.setState({
            list: [],
            loading: false,
          });
          return;
        } else {
          this.setState({
            list: temp,
            loading: false,
          });
        }
      });
    });
  };

  async componentDidMount() {
    this.getAllSearches();
  }

  openModal = (selectedItem) => {
    this.setState({ showModal: true, selectedItem });
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  render() {
    const { selectedItem, list, pageSize, loading } = this.state;

    return (
      <div className="table-load">
        <h1>Loads History</h1>

        <div
          style={{
            height: "70vh",
            width: "95%",
            margin: "0 auto",
            marginTop: 30,
          }}
        >
          <DataGrid
            onRowClick={(params) => this.openModal(params.row)}
            loading={loading}
            rows={list}
            columns={[
              {
                headerName: "Name",
                field: "driver",
                flex: 1,
                minWidth: 260,
                valueGetter: ({ row }) => row.driver?.name,
                renderCell: ({ row }) => {
                  return (
                    <div
                      className="cc-1"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <img src={row.driver?.profileURL} alt="Profile" />
                      <h5 style={{ marginLeft: 10 }}>{row.driver?.name}</h5>
                    </div>
                  );
                },
              },
              {
                headerName: "Pick-up",
                field: "pickup",
                flex: 1,
                minWidth: 200,
              },
              {
                headerName: "Drop-off",
                field: "destination",
                flex: 1,
                minWidth: 200,
              },
              {
                headerName: "Location to avoid",
                field: "locationToAvoid",
                flex: 1,
                minWidth: 200,
              },
              {
                headerName: "Truck size/length",
                field: "truckLength",
                flex: 1,
                minWidth: 150,
                type: "number",
              },
              {
                headerName: "Weight",
                field: "loadWeight",
                flex: 1,
                minWidth: 100,
                type: "number",
              },
              {
                headerName: "Truck type",
                field: "truckType",
                flex: 1,
                minWidth: 150,
              },
              {
                headerName: "Load type",
                field: "loadType",
                flex: 1,
                minWidth: 120,
              },
              {
                headerName: "Date",
                field: "loadDate",
                flex: 1,
                minWidth: 250,
                valueGetter: ({ row }) =>
                  row?.loadDate?.toDate()?.toDateString(),
              },
              {
                headerName: "Time",
                field: "tripDateTime",
                flex: 1,
                minWidth: 250,
                valueGetter: ({ row }) => row.tripDateTime?.delivered,
                renderCell: ({ row }) =>
                  moment(
                    new Date(row.tripDateTime?.delivered?.seconds * 1000)
                  ).format("DD/MM/YYYY") +
                  " at " +
                  moment(
                    new Date(row.tripDateTime?.delivered?.seconds * 1000)
                  ).format("hh:mm a"),
              },
              {
                headerName: "Ratings",
                field: "rating",
                flex: 1,
                minWidth: 120,
                renderCell: ({ row }) => <RatingStars rating={row.rating} />,
              },
            ]}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) =>
              this.setState({ pageSize: newPageSize })
            }
            rowsPerPageOptions={[5, 10, 20, 50]}
            pagination
            autoPageSize
          />
        </div>

        <Modal
          show={this.state.showModal}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
          fullscreen={true}
          onHide={() => {
            this.closeModal();
          }}
        >
          <ModalHeader closeButton>
            <ModalTitle>
              <div>
                <h3>Load Details for {selectedItem?.driver?.name}</h3>
              </div>
            </ModalTitle>
          </ModalHeader>
          <ModalBody>
            <div className="offer-f">
              <div className="f-group">
                <label for="name">Company Name</label>
                <br />
                <h6>{selectedItem?.driver?.companyName}</h6>
              </div>

              <div className="f-group">
                <label htmlFor="name">Rate Confirmation</label>
                <br />
                <a
                  href={selectedItem?.approvedDoc}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View Rate Confirmation
                </a>
              </div>

              <div className="f-group">
                <label htmlFor="name">BOL</label>
                <br />
                <a
                  href={selectedItem?.bolDoc}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  BOL File
                </a>
              </div>

              <div className="f-group">
                <label for="name">Empty Truck Image</label>
                <br />
                <img
                  src={selectedItem?.emptyTrailorImage}
                  alt="Empty Truck"
                  width="150"
                  height="150"
                  style={{
                    resize: "both",
                  }}
                />
              </div>
              <div className="f-group">
                <label for="name">Loaded Truck Image</label>
                <br />
                <img
                  src={selectedItem?.loadedTrailorImage}
                  alt="Loaded Truck"
                  width="150"
                  height="150"
                  style={{
                    resize: "both",
                  }}
                />
              </div>
              <br />

              <div className="f-group">
                <label htmlFor="rating">Rating</label>
                <br />
                <RatingStars rating={selectedItem?.rating} />

                <label htmlFor="review">Review Message</label>
                <br />
                <h6 style={{ marginTop: "5px" }}>{selectedItem?.review}</h6>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  activeTab: state.tabs.label,
  info: state.tabs.info,
});

// Mapping Redux actions to component props
const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(Load_request);
