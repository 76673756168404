/* eslint-disable */
import React, { Component } from "react";
import trash from "../img/trash.png";
import profile from "../img/profile.jpeg";
import Truck from "../img/Frame.png";
import Users from "./dispatcherlist";
import firebase from "./../firebase/firebase";
import Switch from "@material-ui/core/Switch";

const backendURL = process.env.REACT_APP_BACKEND_URL;

class Dispatcher extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      accountNumber: "",
      email: "",
      password: "",
      allowAdmin: false,
      loading: false,
      allUsers: [],
      id: "",
      isEdit: false,
      isActive: false,
    };
  }
  componentDidMount() {
    this.getUsers();
  }
  getUsers = () => {
    let userRef = firebase.firestore().collection("dispatchers");
    userRef
      .where("role", "in", ["Dispatcher", "Admin"])
      // .where("role", '==', "Admin")
      .onSnapshot((snap) => {
        let temp = [];
        snap.docs.forEach((a) => {
          temp.push(a.data());
        });
        console.log("AllDispatchers=>", temp);
        this.setState({
          allUsers: temp,
        });
      });
  };

  deleteUser = (user) => {
    let data = {
      uid: user.id,
    };

    fetch(`${backendURL}deleteDispatcher/${user.id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        alert("User deleted successfully");
      })
      .catch((error) => {
        console.error("There was an error deleting the user:", error);
        alert("There was an error deleting the user");
      });
  };

  editUser = (user) => {
    console.log("user editUser", user);
    this.setState({
      name: user?.name,
      accountNumber: user?.accountNumber,
      email: user?.email,
      password: user?.password,
      id: user?.id,
      isEdit: true,
      isActive: user.Active,
    });
    console.log(user);
  };

  cancelEditing = () => {
    this.setState({
      name: "",
      accountNumber: "",
      email: "",
      password: "",
      id: "",
      isEdit: false,
    });
  };

  edit = () => {
    const { email, accountNumber, password, name, id } = this.state;
    if (email && accountNumber && password && name) {
      var obj = {
        name: name,
        accountNumber: accountNumber,
        email: email,
        password: password,
        Active: this.state.isActive,
      };
      firebase
        .firestore()
        .collection("dispatchers")
        .doc(id)
        .update(obj)
        .then((res) => {
          this.setState({
            name: "",
            accountNumber: "",
            email: "",
            password: "",
            allowAdmin: false,
            loading: false,
            isEdit: false,
          });
          alert("Update successfully");
        });
    } else {
      alert("All fields are required");
    }
  };

  onSubmit = () => {
    const { name, accountNumber, email, password, allowAdmin } = this.state;
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;

    if (
      name === "" ||
      accountNumber === "" ||
      email === "" ||
      password === ""
    ) {
      alert("Please fill all fields!");
    } else if (reg.test(email) === false) {
      alert("Please enter a valid email!");
    } else {
      let data = {
        name: name,
        accountNumber: accountNumber,
        email: email,
        password: password,
        role: allowAdmin ? "Admin" : "Dispatcher",
        Active: this.state.isActive,
      };

      let userRef = firebase.firestore().collection("dispatchers");
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then((user) => {
          let userJson = user.user.uid;
          userRef.doc(userJson).set({ ...data, id: userJson }, { merge: true });
          if (!allowAdmin) {
            firebase
              .firestore()
              .collection("statistics")
              .doc("admin")
              .get()
              .then((res) => {
                let dat1 = res.data()?.total_inactive_dispatcher;
                dat1 = dat1 + 1;
                firebase.firestore().collection("statistics").doc("admin").set(
                  {
                    total_inactive_dispatcher: dat1,
                  },
                  { merge: true }
                );
              });
          }
          this.setState({
            name: "",
            accountNumber: "",
            email: "",
            password: "",
            allowAdmin: false,
            loading: false,
          });
          alert(`${allowAdmin ? "Admin" : "Dispatcher"} added successfully`);
        })
        .catch((error) => {
          console.error("Error creating user:", error);
          alert("Error creating user: " + error.message);
        });
    }
  };

  handleChange = (event) => {
    console.log(event.target.checked, "faai");
    // Use event.target.checked to get the boolean value of the Switch
  };

  render() {
    const {
      name,
      accountNumber,
      email,
      password,
      allowAdmin,
      isEdit,
      isActive,
    } = this.state;
    console.log(isActive, "oi", allowAdmin);
    return (
      <div className="dash-home">
        <div className="dash-map">
          <h1>{isEdit ? "Update" : "Add"} Dispatchers</h1>
          <div className="form">
            <label for="name">Dispatcher NAME</label>
            <br />
            <input
              type="text"
              name="name"
              placeholder={"Name"}
              value={name}
              onChange={(e) => {
                this.setState({
                  name: e.target.value,
                });
              }}
            />
            <br />
            <label for="name">Dispatcher Account Number</label>
            <br />
            <input
              type="text"
              name="name"
              placeholder={"Account Number xxxxxx"}
              value={accountNumber}
              onChange={(e) => {
                this.setState({
                  accountNumber: e.target.value,
                });
              }}
            />
            <br />
            <label for="name">Dispatcher E-mail</label>
            <br />
            <input
              type="text"
              name="name"
              placeholder={"Email"}
              value={email}
              onChange={(e) => {
                this.setState({
                  email: e.target.value,
                });
              }}
            />
            <br />
            <label for="name">Account Password</label>
            <br />
            <input
              type="text"
              name="name"
              placeholder={"xxxxxxxx"}
              value={password}
              onChange={(e) => {
                this.setState({
                  password: e.target.value,
                });
              }}
            />

            <br />
            <label>dispatcher status</label>
            <Switch
              checked={isActive}
              onClick={() => {
                this.setState({
                  isActive: !isActive,
                });
              }}
              color="primary"
              name="checkedA"
              inputProps={{ "aria-label": "primary  checkbox" }}
            />

            <br />
            <label for="name">Allow All Admin feature?</label>

            <input
              type="checkbox"
              name="name"
              value={allowAdmin}
              onChange={() => {
                this.setState({
                  allowAdmin: !allowAdmin,
                });
              }}
            />
            <br />
            <br />
            <button
              className={"btn-blue"}
              onClick={() => {
                if (isEdit) {
                  this.edit();
                } else {
                  this.onSubmit();
                }
              }}
            >
              {isEdit ? "Update" : "Add"} User
            </button>
            {isEdit && (
              <button className={"btn-red"} onClick={this.cancelEditing}>
                Cancel
              </button>
            )}
            <br />
            {/* </form> */}
          </div>
        </div>
        <img
          src={Truck}
          style={{
            width: 200,
            height: 200,
            marginRight: 150,
            marginLeft: -180,
            marginTop: 150,
            // alignSelf: 'center'
          }}
        />
        <div className="d-pro">
          <Users
            allUsers={this.state.allUsers}
            onDelete={(user) => {
              this.deleteUser(user);
            }}
            Edit={(user) => this.editUser(user)}
          ></Users>
        </div>
      </div>
    );
  }
}
export default Dispatcher;
