/* eslint-disable */
import React, { useState, useRef, useEffect } from 'react';
import firebase from "./../firebase/firebase";
import CircularProgress from "@material-ui/core/CircularProgress";
import SendPushNotification from '../firebase/SendPushNotification';
import logo from "../img/gohaul-logo.png"
// CSS styles
const styles = {
  notificationScreen: {
    display: 'flex',
    height: '100vh', // Full height of the screen
    overflow: 'hidden' // To prevent vertical scrollbar due to height
  },
  whiteBox: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: '#fff',
    marginBottom: '10px',
    borderRadius: '10px',
    padding: '20px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column-reverse',
    alignItems: 'flex-end',
    overflowY: 'auto', // Enable vertical scrolling for the whiteBox
    marginTop: 20,
  },
  notificationList: {
    flex: '0 0 25%', // One-fourth of the screen width
    backgroundColor: '#7A5CD1',
    padding: '20px',
    boxSizing: 'border-box',
    borderRadius: '0 10px 10px 0'
  },
  notificationCard: {
    background: '#fff',
    padding: '10px',
    marginBottom: '10px',
    cursor: 'pointer',
    borderRadius: "10px",
  },
  Card: {
    background: '#7A5CD1',
    padding: '10px',
    marginBottom: '10px',
    borderRadius: "20px",
    borderWidth: "2px",
    height: "40%"
  },
  messageContainer: {
    flex: '0 0 75%',
    backgroundColor: 'rgba(0,0,0,0.0)',
    padding: '20px',
    boxSizing: 'border-box',
    borderRadius: '10px 0 0 10px',
    marginLeft: '10px',
    height: "95%",
  },
  bottomBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end'
  },
  sendButton: {
    padding: '10px 20px',
    backgroundColor: '#7A5CD1',
    color: '#fff',
    border: 'none',
    cursor: 'pointer',
    borderRadius: 10,
    marginLeft: "92%"
  },
  header: {
    padding: '20px',
    boxSizing: 'border-box',
    borderRadius: '10px 0 0 10px',
    height: "10%",
    marginTop: -40,
  },
  Line: {
    height: '2px', // Adjust the height as per your requirement
    backgroundColor: '#7A5CD1',
    marginBottom: '10px', // Add some space below the black line if needed
  },
  messageContainerWrapper: {
    flex: '0 0 75%',
    height: '95%', // Set a fixed height for the message container
    position: 'relative', // Add position relative to the wrapper
    backgroundColor: 'rgba(0,0,0,0.0)',
    padding: '20px',
    boxSizing: 'border-box',
    borderRadius: '10px 0 0 10px',
    marginLeft: '10px',
    overflowY: 'hidden', // Hide the vertical scrollbar of the wrapper
  },
  titleInput: {
    backgroundColor: "#7A5CD1",
    border: "0px",
    fontSize: "25px",
    marginLeft: "30px",
    color: "white"
  },
  bodyInput: {
    backgroundColor: "#7A5CD1",
    border: "0px",
    fontSize: "15px",
    marginLeft: "100px",
    marginTop: "-20px",
    width: "70%",
    color: "white"
  },
  circle: {
    width: "200px",
    height: "200px",
    borderRadius: "50%", /* Create a circle by setting border-radius to 50% */
    overflow: "hidden",/* Hide any content outside the circle */
    backgroundColor: "white",/* Set the background color to white */
  }
};

const NotificationTemplate = () => {
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [loading, setLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);

  useEffect(()=>{
    setLoading(true);
    firebase
  .firestore()
  .collection("templates")
  .onSnapshot(async (snapshot) => {
    const notificationTemplates = [];
    const notificationDocs = snapshot.docs;

    for (const notificationDoc of notificationDocs) {
      const notificationData = notificationDoc.data();
      notificationTemplates.push(notificationData);
    }

    setNotifications(notificationTemplates);
    setLoading(false);
  });
  },[])

  const handleNotificationSelect = async (notification) => {
      setSelectedNotification((prevNotification) => (prevNotification === notification ? null : notification));
    setLoading(true);
    const docRef = firebase.firestore().collection("templates").doc(notification.name);
    docRef.onSnapshot((doc) => {
        if (doc.exists) {
          const notification = doc.data();
          setTitle(notification.title);
          setBody(notification.body);
        } else {
          setTitle("");
          setBody("");
        }
        setLoading(false);
    });
  };

  const handleSendClick = () => {
    // Perform actions on send button click, e.g., send message
    const id = selectedNotification.name;
    firebase
      .firestore()
      .collection("templates")
      .doc(id)
      .set({
        title: title,
        body: body
      },{merge: true});
    
    alert("Notification Template Updated!")
  };

  return (

    <div style={styles.notificationScreen}>
      <div style={styles.notificationList}>
        <h2 style={{marginInline: "20%" , color: "white", marginBottom: 20,}}>Notifications</h2>
        {notifications.map((notification, index) => (
          <div
            key={index}
            style={{
              ...styles.notificationCard,
              backgroundColor: (selectedNotification === notification) ? '#440072': "white",
              color: (selectedNotification === notification) ? 'white': "black"
            }}
            onClick={() => handleNotificationSelect(notification)}
          >
            <h5 style={{cursor: "pointer"}}>{notification.name}</h5>
          </div>
        ))}
      </div>
      <div style={styles.messageContainer}>
        <div style={{ ...styles.header, marginBottom: 0 }}>
          {selectedNotification !== null && (
              <>
                <h2 style={{ color: '#7A5CD1' }}>
                  {selectedNotification.name}
                </h2>
                <div style={styles.Line}/>
              </>
            )}
          </div>
        {loading ? (<CircularProgress size={80} style={{alignSelf: "absolute", margin: "40%"}} />) : 
        <div style={styles.messageContainerWrapper}>
            {selectedNotification === null ? 
            <></> 
            :
            <>
                <div style={styles.Card}>
                    <img
                    alt="Logo"
                    src={logo}
                    style={{
                        width: 50,
                        height: 50,
                        marginTop: "50px",
                        marginInline: "10px",
                        fontSize: "32px",
                        fontWeight: "bold",
                    }}
                    />
                    <input
                    type='text'
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    style={styles.titleInput}
                    placeholder='Enter Title...'
                    />
                    <br/>
                    <textarea
                    type='textarea'
                    value={body}
                    onChange={(e) => setBody(e.target.value)}
                    style={styles.bodyInput}
                    placeholder='Enter Body...'
                    />
                </div>
                <button disabled={!(title != "" && body != "")} style={styles.sendButton} onClick={handleSendClick}>
                Update
                </button>
            </>
            }
          </div>
        }
      </div>
    </div>
  );
};

export default NotificationTemplate;
