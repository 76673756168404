const cloudNotificationURL = process.env.REACT_APP_NOTIFICATION_URL;
const SendPushNotification = async (title, body, uid) => {
  let json = {
    title: title,
    body: body,
    uid: uid,
  };
  try {
    const res = await fetch(cloudNotificationURL, {
      method: "POST",
      body: JSON.stringify(json),
      mode: "cors",
    });

    const data = await res.json(); // Resolve the JSON data

    console.log("Response", data); // Access the actual JSON data

    // Handle the data as needed
    if (data.status) {
      console.log("Notification Sent");
    } else {
      console.error("Notification couldn't be sent:", data.err);
    }
  } catch (err) {
    console.log("Error:", err);
  }
};

export default SendPushNotification;
