/* eslint-disable */
import React, { Component } from "react";
import firebase from "./../firebase/firebase";
import { Button } from "@mui/material";
import AddLoadModal from "./../customViews/AddOfferModal";
import BidsModal from "./../customViews/BidsModal";
import AssignDispatcherModal from "./../customViews/AssignDispatcherModal";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import NewRequestModel from "./../customViews/NewRequestModel";
import { ViewlogModel } from "../customViews/ViewlogModel";
import axios from "axios";
const BackendAPI = process.env.REACT_APP_API_URL;
class Load_request extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      list: [],
      templates: [],
      showModal: false,
      selectedItem: null,
      showBidModal: false,
      showAssignModal: false,
      showNewRequestModel: false,
      showTemplateModal: false,
      pageSize: 50,
      showviewmodel: false,
      requestToDelete: null,
      temp: [],
    };
  }
  getAllSearches = async () => {
    let ref = firebase.firestore().collection("searchRequests");
    ref.onSnapshot((snap) => {
      this.setState({ loading: true });
      if (snap.empty) {
        this.setState({ loading: false, list: [] });
        return;
      }
      // let temp = [];

      console.log(this.state.temp, "tt");
      snap.docs.forEach(async (a) => {
        if (a.id !== this.state.requestToDelete) {
          this.state.temp = [];
          firebase
            .firestore()
            .collection("searchRequests")
            .doc(a.id)
            .collection("offers")
            .onSnapshot((snap2) => {
              let off = snap2.size;
              let tempPending = [];
              let isApprove = false;
              snap2.docs.forEach((docs) => {
                if (docs.data().status === "pending") {
                  tempPending = [
                    ...tempPending,
                    { ...docs.data(), bidId: docs.id },
                  ];
                } else if (docs.data().status === "approve") {
                  isApprove = true;
                }
              });
              let flag = true;
              let index = -1;
              for (let i = 0; i < this.state.temp.length; i++) {
                if (this.state.temp[i].pathId === a.id) {
                  flag = false;
                  index = i;
                  break;
                }
              }
              if (flag) {
                this.state.temp = [
                  ...this.state.temp,
                  {
                    ...a.data(),
                    totalOffers: off - tempPending.length,
                    pathId: a.id,
                    totalBids: tempPending.length,
                    pending: tempPending,
                    isApprove: isApprove,
                    id: this.state.temp.length + 1,
                  },
                ];
              } else {
                this.state.temp[index] = {
                  ...a.data(),
                  totalOffers: off - tempPending.length,
                  pathId: a.id,
                  totalBids: tempPending.length,
                  pending: tempPending,
                  isApprove: isApprove,
                  id: this.state.temp[index].id,
                };
              }
              // console.log(temp);
              this.setState({
                list: this.state.temp,
                loading: false,
              });
            });
        } else {
          this.setState({
            requestToDelete: null,
          });
        }
      });
    });
  };

  getAllTemplates = async () => {
    this.setState({ loading: true });
    let ref = firebase.firestore().collection("RequestTemplates");
    let temp = [];
    ref.onSnapshot((snap) => {
      if (snap.empty) {
        this.setState({ templates: [], loading: false });
      }
      snap.docs.forEach(async (a) => {
        let getTemplates = await firebase
          .firestore()
          .collection("RequestTemplates")
          .get();
        let off = getTemplates.size;
        let tempPending = [];
        getTemplates.docs.forEach((docs) => {
          tempPending = [...tempPending, { ...docs.data() }];
        });
        temp = [
          ...temp,
          {
            ...a.data(),
            id: temp.length + 1,
          },
        ];
        this.setState({
          templates: temp,
          loading: false,
        });
      });
    });
  };

  async componentDidMount() {
    this.getAllSearches();
    this.getAllTemplates();
  }
  Viewlog = async (x) => {
    this.state.redirectP("/contact");
  };

  deleteRequest = async (requestId) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this request?"
    );

    if (confirmDelete) {
      this.setState({ loading: true });
      try {
        const response = await axios.delete(
          `${BackendAPI}/deleteRequest/${requestId}`,
          {
            headers: {
              authKey: "0101",
            },
          }
        );

        const { data } = response;

        if (data.success) {
          setTimeout(() => {
            this.getAllSearches();
            this.setState({ loading: false });
          }, 3000);
        }
      } catch (error) {
        let err =
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message;

        this.setState({ loading: false });
        console.log(error.message);
      }
    }
  };

  deleteTemplate = async (tempName) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this request?"
    );
    if (confirmDelete) {
      try {
        // alert(tempName);
        const requestRef = firebase
          .firestore()
          .collection("RequestTemplates")
          .doc(tempName);
        await requestRef.delete().then(() => {
          // console.log("Template Deleted.");
          this.getAllTemplates();
        });
      } catch (error) {
        console.error("Error deleting request:", error);
      }
    }
  };

  render() {
    const {
      showModal,
      selectedItem,
      showBidModal,
      showAssignModal,
      loading,
      list,
      pageSize,
      showNewRequestModel,
      showTemplateModal,
      templates,
      showviewmodel,
      temp,
    } = this.state;

    console.log(this.state.list, "list", temp);
    return (
      <>
        <div className="table-t">
          <div className="table-load">
            <div className="row">
              <h1 className="col-md-10">Load Requests</h1>
              <Button
                className="btn-blue col-md-2"
                onClick={() => this.setState({ showNewRequestModel: true })}
                style={{
                  right: 0,
                  height: 60,
                  backgroundColor: "#7A5CD1",
                  color: "#fff",
                }}
              >
                New Request
              </Button>
            </div>

            <div
              style={{
                height: "70vh",
                width: "95%",
                margin: "0 auto",
                marginTop: 30,
              }}
            >
              <DataGrid
                loading={loading}
                rows={list?.filter((load) => !load.isApprove)}
                columns={[
                  {
                    headerName: "Team Name / company name",
                    field: "companyName",
                    flex: 1,
                    minWidth: 200,
                    valueGetter: ({ row }) => row.companyName?.companyName,
                    renderCell: ({ row }) => {
                      return (
                        <h5 style={{ marginLeft: 10 }}>
                          {row.driver?.companyName}
                        </h5>
                      );
                    },
                  },
                  {
                    headerName: "Assigned Dispatcher",
                    field: "dispatcher",
                    flex: 1,
                    minWidth: 200,
                    valueGetter: ({ row }) => row.dispatcher?.name,
                    renderCell: ({ row }) => {
                      return (
                        <h5 style={{ marginLeft: 10 }}>
                          {row.dispatcher?.name}
                        </h5>
                      );
                    },
                  },
                  {
                    headerName: "Assigned Driver",
                    field: "driver",
                    flex: 1,
                    minWidth: 200,
                    valueGetter: ({ row }) => row.driver?.name,
                    renderCell: ({ row }) => {
                      return (
                        <h5 style={{ marginLeft: 10 }}>{row.driver?.name}</h5>
                      );
                    },
                  },
                  {
                    headerName: "Pick-up",
                    field: "pickup",
                    flex: 1,
                    minWidth: 200,
                  },
                  {
                    headerName: "Drop-off",
                    field: "destination",
                    flex: 1,
                    minWidth: 200,
                    valueGetter: ({ row }) => {
                      return row.destination instanceof Array
                        ? row.destination.map((item) => item + ",")
                        : row.destination;
                    },
                    renderCell: ({ row }) => {
                      return row.destination instanceof Array ? (
                        <div class="topContainer">
                          {row.destination.map((item) => {
                            return <div class="topContainer">{item},</div>;
                          })}
                        </div>
                      ) : (
                        <div className="cc-1">{row.destination}</div>
                      );
                    },
                  },
                  {
                    headerName: "Location to avoid",
                    field: "locationToAvoid",
                    flex: 1,
                    minWidth: 200,
                    valueGetter: ({ row }) => {
                      return row.locationToAvoid instanceof Array
                        ? row.locationToAvoid.map((item) => item + ",")
                        : row.locationToAvoid;
                    },
                    renderCell: ({ row }) => {
                      return row.locationToAvoid instanceof Array ? (
                        <div class="topContainer">
                          {row.locationToAvoid.map((item) => {
                            return <div class="topContainer">{item},</div>;
                          })}
                        </div>
                      ) : (
                        <div className="cc-1">{row.locationToAvoid}</div>
                      );
                    },
                  },
                  {
                    headerName: "Truck size/length",
                    field: "length",
                    flex: 1,
                    minWidth: 150,
                    type: "number",
                  },
                  {
                    headerName: "Weight",
                    field: "weight",
                    flex: 1,
                    minWidth: 100,
                    type: "number",
                  },
                  {
                    headerName: "Truck type",
                    field: "truckType",
                    flex: 1,
                    minWidth: 150,
                  },
                  {
                    headerName: "Load type",
                    field: "fullOrPartial",
                    flex: 1,
                    minWidth: 120,
                  },
                  {
                    headerName: "createdAt",
                    field: "createdAt",
                    flex: 1,
                    minWidth: 250,
                    renderCell: ({ row }) =>
                      moment(new Date(row.createdAt?.seconds * 1000)).format(
                        "DD/MM/YYYY"
                      ) +
                      " at " +
                      moment(new Date(row.createdAt?.seconds * 1000)).format(
                        "hh:mm a"
                      ),
                    resizable: true,
                  },
                  {
                    headerName: "Date",
                    field: "startDate",
                    flex: 1,
                    minWidth: 250,
                    renderCell: ({ row }) =>
                      new Date(row.startDate?.seconds * 1000).toDateString() +
                      "/" +
                      new Date(row.endDate?.seconds * 1000).toDateString(),
                    resizable: true,
                  },
                  {
                    headerName: "Time",
                    field: "time",
                    flex: 1,
                    minWidth: 650,
                    renderCell: ({ row }) =>
                      new Date(row.startDate?.seconds * 1000).toTimeString() +
                      "/" +
                      new Date(row.endDate?.seconds * 1000).toTimeString(),
                    resizable: true,
                  },
                  {
                    headerName: "Total Offers",
                    field: "totalOffers",
                    flex: 1,
                    minWidth: 150,
                  },
                  {
                    headerName: "Bids on Offers",
                    field: "totalBids",
                    flex: 1,
                    minWidth: 150,
                    renderCell: ({ row }) => {
                      const bidsCount = row.totalBids || 0;
                      return (
                        <a
                          href="#"
                          style={{ color: "blue", cursor: "pointer" }}
                          onClick={() => {
                            // console.log(row);
                            this.setState({
                              selectedItem: row,
                              showBidModal: true,
                            });
                          }}
                        >
                          {bidsCount}
                        </a>
                      );
                    },
                  },
                  {
                    headerName: "Actions",
                    flex: 1,
                    minWidth: 550,
                    sortable: false,
                    renderCell: ({ row }) => {
                      return (
                        <div>
                          <button
                            className="btn-blue"
                            style={{
                              width: "30%",
                              backgroundColor: "#7A5CD1",
                            }}
                            onClick={() => {
                              this.setState({
                                selectedItem: row,
                                showAssignModal: true,
                              });
                            }}
                          >
                            Assign Dispatcher
                          </button>
                          <button
                            className="btn-orange"
                            onClick={() => {
                              this.setState({
                                selectedItem: row,
                                showModal: true,
                              });
                            }}
                          >
                            Add Offers
                          </button>
                          {/* <button
                          className="btn-blue"
                          onClick={() => {
                            this.setState({
                              selectedItem: row,
                              showBidModal: true,
                            });
                          }}
                        >
                          Show Bids
                        </button> */}
                          <button
                            className="btn-red"
                            style={{
                              width: "25%",
                              backgroundColor: "#7A5C86",
                            }}
                            onClick={() => {
                              this.setState({
                                selectedItem: row,
                                showviewmodel: true,
                              });
                            }}
                          >
                            View logs
                          </button>
                          <button
                            className="btn-red"
                            onClick={() => {
                              this.deleteRequest(row.pathId);
                            }}
                          >
                            Delete
                          </button>
                        </div>
                      );
                    },
                  },
                ]}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) =>
                  this.setState({ pageSize: newPageSize })
                }
                rowsPerPageOptions={[5, 10, 20, 50]}
                pagination
                autoPageSize
              />
            </div>
          </div>

          <AssignDispatcherModal
            showModal={showAssignModal}
            selectedItem={selectedItem}
            onHide={() => {
              this.setState({
                showAssignModal: false,
              });
              this.getAllSearches();
            }}
          />
          <AddLoadModal
            showModal={showModal}
            selectedItem={selectedItem}
            onHide={() => {
              this.setState({
                showModal: false,
              });
              this.getAllSearches();
            }}
          />
          <BidsModal
            showBidModal={showBidModal}
            selectedItem={selectedItem}
            onHide={(pathId) => {
              this.setState({
                showBidModal: false,
                requestToDelete: pathId,
              });
              this.getAllSearches();
            }}
          />
          <ViewlogModel
            showBidModal={showviewmodel}
            selectedItem={selectedItem}
            onCloseModal={() => {
              this.setState({
                showviewmodel: false,
              });
            }}
          />
          <NewRequestModel
            showModal={showNewRequestModel}
            templates={templates}
            onHide={() => {
              this.setState({
                showNewRequestModel: false,
              });
              this.getAllSearches();
            }}
          />
        </div>

        {/* tabb */}
        <div className="table-t">
          <div className="table-load">
            <div className="row">
              <h1 className="col-md-10">Templates</h1>
              <Button
                className="btn-blue col-md-2"
                onClick={() => this.setState({ showTemplateModal: true })}
                style={{
                  right: 0,
                  height: 60,
                  backgroundColor: "#7A5CD1",
                  color: "#fff",
                }}
              >
                New Template
              </Button>
            </div>

            <div
              style={{
                height: "70vh",
                width: "95%",
                margin: "0 auto",
                marginTop: 30,
              }}
            >
              <DataGrid
                loading={loading}
                rows={templates}
                columns={[
                  {
                    headerName: "Team Name / company name",
                    field: "companyName",
                    flex: 1,
                    minWidth: 200,
                    valueGetter: ({ row }) => row.companyName?.companyName,
                    renderCell: ({ row }) => {
                      return (
                        <h5 style={{ marginLeft: 10 }}>
                          {row.driver?.companyName}
                        </h5>
                      );
                    },
                  },
                  // {
                  //   headerName: "Assigned Dispatcher",
                  //   field: "dispatcher",
                  //   flex: 1,
                  //   minWidth: 200,
                  //   valueGetter: ({ row }) => row.dispatcher?.name,
                  //   renderCell: ({ row }) => {
                  //     return (
                  //       <h5 style={{ marginLeft: 10 }}>{row.dispatcher?.name}</h5>
                  //     );
                  //   },
                  // },
                  {
                    headerName: "Assigned Driver",
                    field: "driver",
                    flex: 1,
                    minWidth: 200,
                    valueGetter: ({ row }) => row.driver?.name,
                    renderCell: ({ row }) => {
                      return (
                        <h5 style={{ marginLeft: 10 }}>{row.driver?.name}</h5>
                      );
                    },
                  },
                  {
                    headerName: "Pick-up",
                    field: "pickup",
                    flex: 1,
                    minWidth: 200,
                  },
                  {
                    headerName: "Drop-off",
                    field: "destination",
                    flex: 1,
                    minWidth: 100,
                    valueGetter: ({ row }) => {
                      return row.destination instanceof Array
                        ? row.destination.map((item) => item + ",")
                        : row.destination;
                    },
                    renderCell: ({ row }) => {
                      return row.destination instanceof Array ? (
                        <div class="topContainer">
                          {row.destination.map((item) => {
                            return <div class="topContainer">{item},</div>;
                          })}
                        </div>
                      ) : (
                        <div className="cc-1">{row.destination}</div>
                      );
                    },
                  },
                  {
                    headerName: "Location to avoid",
                    field: "locationToAvoid",
                    flex: 1,
                    minWidth: 130,
                    valueGetter: ({ row }) => {
                      return row.locationToAvoid instanceof Array
                        ? row.locationToAvoid.map((item) => item + ",")
                        : row.locationToAvoid;
                    },
                    renderCell: ({ row }) => {
                      return row.locationToAvoid instanceof Array ? (
                        <div class="topContainer">
                          {row.locationToAvoid.map((item) => {
                            return <div class="topContainer">{item},</div>;
                          })}
                        </div>
                      ) : (
                        <div className="cc-1">{row.locationToAvoid}</div>
                      );
                    },
                  },
                  {
                    headerName: "Truck size/length",
                    field: "length",
                    flex: 1,
                    minWidth: 150,
                    type: "number",
                  },
                  {
                    headerName: "Weight",
                    field: "weight",
                    flex: 1,
                    minWidth: 80,
                    type: "number",
                  },
                  {
                    headerName: "Truck type",
                    field: "truckType",
                    flex: 1,
                    minWidth: 150,
                  },
                  {
                    headerName: "Load type",
                    field: "fullOrPartial",
                    flex: 1,
                    minWidth: 120,
                  },
                  {
                    headerName: "Actions",
                    flex: 1,
                    minWidth: 140,
                    sortable: false,
                    renderCell: ({ row }) => {
                      return (
                        <div>
                          {/* <button
                        className="btn-red"
                        style={{backgroundColor: "green"}}
                        onClick={() => {
                          this.setState({
                            selectedItem: row,
                            showBidModal: true,
                          });
                        }}
                      >
                        Edit
                      </button> */}
                          <button
                            className="btn-red"
                            onClick={() => {
                              this.deleteTemplate(row.templateName);
                            }}
                          >
                            Delete
                          </button>
                        </div>
                      );
                    },
                  },
                ]}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) =>
                  this.setState({ pageSize: newPageSize })
                }
                rowsPerPageOptions={[5, 10, 20, 50]}
                pagination
                autoPageSize
              />
            </div>
          </div>

          <NewRequestModel
            showModal={showTemplateModal}
            isTemplate={true}
            onHide={() => {
              this.setState({
                showTemplateModal: false,
              });
              this.getAllSearches();
            }}
          />
        </div>
      </>
    );
  }
}
export default Load_request;
