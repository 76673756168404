/* eslint-disable */
import { useEffect, useState } from "react";
import firebase from "../firebase/firebase";
import { uploadImage } from "../firebase/upload";
import arrow from "../img/arrow.png";
import moment from "moment";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import SendPushNotification from "../firebase/SendPushNotification";
import axios from "axios";
import { CopyToClipboard } from "react-copy-to-clipboard";
import CircularProgress from "@material-ui/core/CircularProgress";
import { logActivity, name } from "../helper/ActivityLogger";
import { MenuItem, Select } from "@material-ui/core";

const Users = (props) => {
  const [currentTime, setCurrentTime] = useState(Date.now());
  const [pickup, setPickup] = useState("");
  const [destination, setDestination] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState();
  const trackingURL = process.env.REACT_APP_TRACKING_URL;

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(Date.now());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const addDocument = async (file, trip, documentType) => {
    setLoading(true);
    if (file) {
      const { URLPhoto } = await uploadImage(file);

      const data = {
        currentLoad: {
          [documentType]: URLPhoto,
        },
      };

      await firebase
        .firestore()
        .collection("loads")
        .doc(trip.loadId)
        .set(data, { merge: true });

      if (documentType === "approvedDoc") {
        setLoading(false);
        alert("Rate confirmation uploaded successfully");
      } else if (documentType === "bolDoc") {
        alert("BOL uploaded successfully");
      }
    }
    setLoading(false);
  };

  const totalTime = (timeStamp) => {
    if (!timeStamp) return "00:00:00";
    const startTime = timeStamp?.seconds * 1000;
    const timeDifference = currentTime - startTime;
    var seconds = Math.floor(timeDifference / 1000);
    var minutes = Math.floor(seconds / 60);
    var hours = Math.floor(minutes / 60);
    minutes = minutes % 60;
    seconds = seconds % 60;
    if (isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
      return "00:00:00";
    }
    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    return hours + ":" + minutes + ":" + seconds;
  };

  const finalTime = (timeStamp1, timeStamp2) => {
    if (!timeStamp1 || !timeStamp2) return "00:00:00";
    const startTime = timeStamp1?.seconds * 1000;
    const currentTime = timeStamp2?.seconds * 1000;
    const timeDifference = currentTime - startTime;
    var seconds = Math.floor(timeDifference / 1000);
    var minutes = Math.floor(seconds / 60);
    var hours = Math.floor(minutes / 60);
    minutes = minutes % 60;
    seconds = seconds % 60;
    if (isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
      return "00:00:00";
    }
    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    return hours + ":" + minutes + ":" + seconds;
  };

  const statusText = (text) => {
    if (text === "enrouteToLoad") {
      return "Enroute To Pickup";
    } else if (text === "loaded") {
      return "Loaded";
    } else if (text === "enrouteToDrop") {
      return "Enroute To Drop";
    } else if (text === "dropped") {
      return "Dropped";
    }
  };

  const checkStatus = (item) => {
    if (item.status === "enrouteToLoad") {
      return item.tripDateTime.enrouteToLoad;
    } else if (item.status === "loaded") {
      return item.tripDateTime.loaded;
    } else if (item.status === "enrouteToDrop") {
      return item.tripDateTime.enrouteToDrop;
    } else if (item.status === "dropped") {
      return item.tripDateTime.dropped;
    } else if (item.status === "delivered") {
      return item.tripDateTime.delivered;
    }
  };

  const previousStatus = (item) => {
    if (item.status === "loaded") {
      return item.tripDateTime.enrouteToLoad;
    } else if (item.status === "enrouteToDrop") {
      return item.tripDateTime.loaded;
    } else if (item.status === "dropped") {
      return item.tripDateTime.enrouteToDrop;
    } else if (item.status === "delivered") {
      return item.tripDateTime.dropped;
    }
  };

  const updateLoadStatus = (event, item) => {
    props.onAction(event.target.value, item);
  };

  const getGeolocation = async (address) => {
    if (address !== "") {
      try {
        const apiKey = "AIzaSyCjysO3MF1vStj7BR3IRTAOOvLa3O6bark";
        // const proxyurl = "https://cors-anywhere.herokuapp.com/";
        const response = await axios.get(
          `https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${encodeURIComponent(
            address
          )}&key=${apiKey}`
        );

        // Extract suggestions from the response
        if (response.data.predictions) {
          const suggestionsData = response.data.predictions.map(
            (prediction) => ({
              label: prediction.description,
              value: prediction.place_id,
              streetAddress: getStreetAddress(prediction.description),
            })
          );
          console.log(suggestionsData);
          setSuggestions(suggestionsData);
        }
      } catch (error) {
        console.error("Error fetching autocomplete suggestions:", error);
      }
    }
  };

  return (
    <div className="driver-list">
      {props.list.map((item, index) => (
        <div className="trip-list">
          {item.status !== "approve" && (
            <Select
              value={item.status}
              onChange={(event) => {
                updateLoadStatus(event, item);
              }}
              style={{ width: 150, alignSelf: "flex-start", margin: 10 }}
            >
              <MenuItem value="readyForPickup">Ready For Pickup</MenuItem>
              <MenuItem value="enrouteToLoad">Enroute To Pickup</MenuItem>
              <MenuItem value="loaded">Loaded</MenuItem>
              <MenuItem value="enrouteToDrop">Enroute To Drop</MenuItem>
              <MenuItem value="dropped">Dropped</MenuItem>
              <MenuItem value="delivered">Delivered</MenuItem>
            </Select>
          )}

          <div
            style={{
              alignSelf: "flex-start",
              margin: "10px",
              padding: "15px",
              border: "1px solid #ccc",
              borderRadius: "5px",
              boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
            }}
            key={index}
          >
            <div className="driver-info">
              <h3>Load Information</h3>
              <div className="form-fields">
                <div className="field">
                  <label>Pickup:</label>
                  {item.status === "approve" ? (
                    <div style={{ width: "60%", marginRight: -100 }}>
                      <GooglePlacesAutocomplete
                        apiKey="AIzaSyCNqybOpVpG-NEaoDHkiwt3tY1DZhaLM0c"
                        selectProps={{
                          defaultInputValue: item.pickup ? item.pickup : "",
                          defaultValue: item.pickup ? item.pickup : "",
                          defaultChecked: true,
                          onChange: (text) => {
                            props.setAddress(text.label, "Pickup", item);
                          },
                        }}
                        onLoadFailed={(err) => {
                          console.log(err);
                        }}
                      />
                    </div>
                  ) : (
                    <input type="text" value={item.pickup} disabled />
                  )}
                </div>
                <div className="field">
                  <label>Destination:</label>
                  {item.status === "approve" ? (
                    <div style={{ width: "60%", marginRight: -100 }}>
                      <GooglePlacesAutocomplete
                        apiKey="AIzaSyCNqybOpVpG-NEaoDHkiwt3tY1DZhaLM0c"
                        selectProps={{
                          defaultInputValue: item.destination
                            ? item.destination
                            : "",
                          defaultValue: item.destination
                            ? item.destination
                            : "",
                          defaultChecked: true,
                          onChange: (text) => {
                            props.setAddress(text.label, "Destination", item);
                          },
                        }}
                        onLoadFailed={(err) => {
                          console.log(err);
                        }}
                      />
                    </div>
                  ) : (
                    <input type="text" value={item.destination} disabled />
                  )}
                </div>
                <div className="field">
                  <label>Name:</label>
                  <input type="text" value={item?.driver?.name} disabled />
                </div>
                <div className="field">
                  <label>E-mail:</label>
                  <input type="text" value={item?.driver?.email} disabled />
                </div>

                {/* <div className="field">
                  <label>Phone:</label>
                  <input type="text" value={item?.driver?.phone} disabled />
                </div> */}
                <div className="field">
                  <label>Company:</label>
                  <input
                    type="text"
                    value={item.driver?.companyName}
                    disabled
                  />
                </div>
                <div className="field">
                  <label>Load Id:</label>
                  <input type="text" value={item?.loadRefsID} disabled />
                </div>
                {/* <div className="field">
                  <label>MC Number:</label>
                  <input type="text" value={item?.driver?.mcNumber} disabled />
                </div> */}

                <div className="field">
                  <label>Date:</label>
                  <input
                    type="text"
                    value={item.loadDate.toDateString()}
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="status-box">
            {item.tripDateTime && (
              <div className="status-grid">
                <div className="status-item">
                  {item.status !== "enrouteToLoad" && (
                    <>
                      <h6 style={{ color: "#333", fontWeight: "bold" }}>
                        Previous Status Time:
                      </h6>{" "}
                      {finalTime(previousStatus(item), checkStatus(item))}
                      <br />
                    </>
                  )}
                  <h6 style={{ color: "#333", fontWeight: "bold" }}>
                    Total Time Elapsed:
                  </h6>{" "}
                  {item.status !== "delivered"
                    ? totalTime(item.tripDateTime.enrouteToLoad)
                    : finalTime(
                        item.tripDateTime.enrouteToLoad,
                        item.tripDateTime.delivered
                      )}
                </div>
                {item.status !== "delivered" && (
                  <div className="status-item">
                    <h6 style={{ color: "#333", fontWeight: "bold" }}>
                      {statusText(item.status)} Time:
                    </h6>{" "}
                    {totalTime(checkStatus(item))}
                  </div>
                )}
              </div>
            )}
          </div>

          <div
            style={{
              paddingBottom: 10,
              paddingLeft: 5,
              display: "flex",
              flexDirection: "column",
              gap: 5,
            }}
          >
            {item.status === "approve" ? (
              <div>
                <button
                  style={{
                    backgroundColor: "#7a5cd1",
                    color: "#fff",
                    border: "none",
                    borderRadius: 8,
                    padding: "8px 12px",
                    fontSize: "16px",
                    marginLeft: "20px",
                    cursor: "pointer",
                  }}
                  onClick={async () => {
                    let flag = false;
                    await firebase
                      .firestore()
                      .collection("loads")
                      .doc(item.loadId)
                      .get()
                      .then((snap) => {
                        flag = snap.data().currentLoad?.approvedDoc
                          ? true
                          : false;
                      });
                    if (flag) {
                      props.onAction("readyForPickup", item);
                    } else {
                      alert("Please add rate confirmation first!");
                    }
                  }}
                >
                  Mark Ready for pickup
                </button>
                <button
                  style={{
                    backgroundColor: "#71b8e4",
                    color: "#fff",
                    border: "none",
                    borderRadius: 8,
                    padding: "8px 12px",
                    marginRight: "20px",
                    marginLeft: "10px",
                    fontSize: "16px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    props.showDetails(item);
                  }}
                >
                  View Details
                </button>
              </div>
            ) : (
              <>
                {item.status === "delivered" && (
                  <div>
                    {loading && (
                      <CircularProgress
                        size={30}
                        style={{ alignSelf: "absolute" }}
                      />
                    )}
                    <label
                      htmlFor={`bolFiles${index}`}
                      style={{
                        backgroundColor: "red",
                        color: "#fff",
                        border: "none",
                        borderRadius: 8,
                        padding: "8px 12px",
                        marginLeft: "10px",
                        fontSize: "16px",
                        cursor: "pointer",
                        width: "50%",
                      }}
                    >
                      Upload BOL
                    </label>
                    <input
                      id={`bolFiles${index}`}
                      onChange={async (e) => {
                        e.preventDefault();
                        addDocument(e?.target?.files[0], item, "bolDoc");
                        var title = "BOL UPLOADED";
                        var body = "BOL has been uploaded for your load.";
                        var notificationData = await firebase
                          .firestore()
                          .collection("templates")
                          .doc("BOL")
                          .get();
                        if (notificationData.exists) {
                          notificationData = notificationData.data();
                          body = notificationData.body;
                          title = notificationData.title;
                        }
                        console.log(body + " " + title);
                        logActivity(
                          name +
                            " uploaded BOL for load [id: " +
                            item.loadId +
                            "]",
                          "Loads"
                        );
                        SendPushNotification(
                          title,
                          `Dear ${item.driver?.name}! ` + body,
                          item.driver?.id
                        );
                      }}
                      style={{ visibility: "hidden" }}
                      type="file"
                    />
                  </div>
                )}

                {item.status === "delivered" ? (
                  <button
                    style={{
                      backgroundColor: "#64ad6a",
                      color: "#fff",
                      border: "none",
                      borderRadius: 8,
                      padding: "8px 12px",
                      marginLeft: "20px",
                      marginRight: "40px",
                      fontSize: "16px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      props.onComplete("complete", item);
                    }}
                  >
                    Mark Completed
                  </button>
                ) : (
                  <></>
                )}
                <button
                  style={{
                    backgroundColor: "#71b8e4",
                    color: "#fff",
                    border: "none",
                    borderRadius: 8,
                    padding: "8px 12px",
                    marginLeft: "20px",
                    marginRight: "40px",
                    fontSize: "16px",
                    cursor: "pointer",
                    // width: "50%",
                  }}
                  onClick={() => {
                    props.showDetails(item);
                  }}
                >
                  View Details
                </button>
              </>
            )}

            <CopyToClipboard
              text={trackingURL + item.loadId + "/" + item.loadRefsID}
            >
              <button
                onClick={() => {
                  window.open(
                    trackingURL + item.loadId + "/" + item.loadRefsID,
                    "_blank"
                  );
                }}
                style={{
                  backgroundColor: "#12c9ff",
                  color: "#fff",
                  border: "none",
                  borderRadius: 8,
                  padding: "8px 12px",
                  marginLeft: "20px",
                  marginRight: "40px",
                  fontSize: "16px",
                  cursor: "pointer",
                  // width: "50%",
                }}
              >
                Copy Link
              </button>
            </CopyToClipboard>

            {item.status === "approve" && (
              <div>
                {loading && (
                  <CircularProgress
                    size={30}
                    style={{ alignSelf: "absolute" }}
                  />
                )}
                <label
                  htmlFor={`files${index}`}
                  style={{
                    backgroundColor: "red",
                    color: "#fff",
                    border: "none",
                    borderRadius: 8,
                    padding: "8px 12px",
                    marginLeft: "20px",
                    fontSize: "16px",
                    cursor: "pointer",
                  }}
                >
                  Upload rate confirmation
                </label>
                <input
                  id={`files${index}`}
                  onChange={async (e) => {
                    e.preventDefault();
                    const file = e.target.files[0];

                    // Define allowed MIME types
                    const allowedMimeTypes = [
                      "image/*",
                      "application/pdf",
                      "application/msword", // .doc
                      "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // .docx
                    ];

                    // Check if the file type is allowed
                    if (
                      file &&
                      allowedMimeTypes.some((type) => file.type.match(type))
                    ) {
                      addDocument(file, item, "approvedDoc");

                      let title = "RATE CONFIRMATION UPLOADED";
                      let body =
                        "Rate Confirmation has been uploaded for your load.";

                      let notificationData = await firebase
                        .firestore()
                        .collection("templates")
                        .doc("Rate Confirmation")
                        .get();

                      if (notificationData.exists) {
                        notificationData = notificationData.data();
                        body = notificationData.body;
                        title = notificationData.title;
                      }

                      logActivity(
                        `${name} uploaded Rate Confirmation for load [id: ${item.loadId}]`,
                        "Loads"
                      );

                      SendPushNotification(
                        title,
                        `Dear ${item.driver?.name}! ${body}`,
                        item.driver?.id
                      );

                      if (item?.driver?.admin) {
                        SendPushNotification(
                          title,
                          `Dear ${item.driver?.name}! ${body}`,
                          item.driver?.admin
                        );
                      }
                    } else {
                      console.log(
                        "Please upload an image, PDF, or Word document."
                      );
                    }
                  }}
                  style={{ visibility: "hidden" }}
                  accept="image/*,.pdf,.doc,.docx"
                  type="file"
                />
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Users;
