import React, { Component } from "react";
import { Button, Modal, ModalBody, ModalTitle } from "react-bootstrap";
import firebase from "./../firebase/firebase";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import SendPushNotification from "../firebase/SendPushNotification";
import { logActivity, name } from "../helper/ActivityLogger";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
const BackendAPI = process.env.REACT_APP_API_URL;
class NewRequestModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      pickup: "",
      dropoff: [],
      pickupDH: "",
      destinationDH: "",
      locationToAvoid: [],
      startDate: new Date(),
      endDate: new Date(),
      time: "10:00",
      loadType: "Both",
      truckType: "Flatbed",
      teams: [],
      drivers: [],
      selectedDriver: "",
      selectedTeam: [],
      length: "",
      weight: "",
      price: "",
      pickupPlaceId: null,
      gmapsLoaded: false,
      requests: [],
      loading: false,
      pageSize: 50,
      isEditing: false,
      selectedRequestId: "",
      templateName: "",
      selectedTemplate: "",
      scrappersCredentials: null,
      truckersEdgeCookie: null,
      virtualFleetCookie: null,
      loadboardCookie: null,
    };
  }

  onSubmit = async () => {
    console.log("===>", BackendAPI);
    const {
      pickup,
      dropoff,
      pickupDH,
      destinationDH,
      locationToAvoid,
      startDate,
      endDate,
      time,
      selectedDriver,
      loadType,
      truckType,
      length,
      weight,
      price,
      pickupPlaceId,
      templateName,
    } = this.state;
    if (!this.props.isTemplate) {
      let request = {
        pickup: pickup,
        destination: dropoff,
        pickupDH: pickupDH,
        destinationDH: destinationDH,
        locationToAvoid: locationToAvoid,
        truckType: truckType,
        length: length,
        weight: weight,
        fullOrPartial: loadType,
        startDate: startDate,
        endDate: endDate,
        time: time,
        driver: selectedDriver,
        price: price,
        pickupPlaceId: pickupPlaceId,
        template: null,
        status: "Requested",
      };

      const requiredFields = [
        { key: "pickup", label: "Pickup Location" },
        // { key: "destination", label: "Drop-Off Location" },
        { key: "pickupDH", label: "PickupDH" },
        { key: "destinationDH", label: "DestinationDH" },
        { key: "startDate", label: "Start Date" },
        // { key: "driver", label: "Driver Name" },
        { key: "endDate", label: "End Date" },
        { key: "length", label: "Length" },
        { key: "weight", label: "Weight" },
        { key: "price", label: "Price" },
      ];

      const missingFields = requiredFields.filter(
        (field) => !this.state[field.key]
      );

      if (missingFields.length > 0) {
        const missingFieldLabels = missingFields
          .map((field) => field.label)
          .join(", ");
        alert(`Please fill in the following fields: ${missingFieldLabels}`);
        return;
      }
      console.log("Add REQUEST=>", request);
      await axios
        .post(
          `${BackendAPI}/searchRequest`,
          {
            request,
          },
          {
            headers: {
              authKey: "0101",
            },
          }
        )
        .then(async (response) => {
          alert("New Request Added!");
          if (response.data.message == "Requested States has no loads") {
            alert("Requested States has no loads");
          } else {
            alert("Offers Added");
          }

          var title = "Request Made!";
          var body =
            "A search request has been made for you by the dispacther!!";
          var notificationData = await firebase
            .firestore()
            .collection("templates")
            .doc("Search Request")
            .get();
          if (notificationData.exists) {
            notificationData = notificationData.data();
            body = notificationData.body;
            title = notificationData.title;
          }
          SendPushNotification(title, body, selectedDriver.id);
          logActivity(
            `${name} created a search request for ${selectedDriver.name} [id: ${selectedDriver.id}]`,
            "Search Requests"
          );
        })
        .catch((error) => {
          console.error("Error adding request: ", error);
        });
    } else {
      let request = {
        templateName: templateName,
        pickup: pickup,
        destination: dropoff,
        pickupDH: pickupDH,
        destinationDH: destinationDH,
        locationToAvoid: locationToAvoid,
        truckType: truckType,
        length: length,
        weight: weight,
        fullOrPartial: loadType,
        // startDate: startDate,
        // endDate: endDate,
        // time: time,
        driver: selectedDriver,
        price: price,
        pickupPlaceId: pickupPlaceId,
        // createdAt: new Date(),
        // status: "Requested",
      };
      firebase
        .firestore()
        .collection("RequestTemplates")
        .doc(templateName)
        .set(request)
        .then(() => {
          alert("New Template Added!");
          logActivity(
            `${name} created a new search request template.`,
            "Search Requests"
          );
        })
        .catch((error) => {
          console.error("Error adding request: ", error);
        });

      // new

      // firebase.firestore().collection(this.state.teamNamedata.id)
      console.log(selectedDriver, "driverobj");
      await firebase
        .firestore()
        .collection("searchScreenTemplates")
        .doc(selectedDriver.id)
        .collection("userTemplates")
        .doc()
        .set(
          {
            ...request,
            name: request.templateName,
            createdAt: new Date(),
          },
          { merge: true }
        );
    }
    this.props.onHide();
  };

  searchLocation = (placeId) => {
    let url = `https://maps.googleapis.com/maps/api/place/details/json?place_id=${placeId}&key=AIzaSyCjysO3MF1vStj7BR3IRTAOOvLa3O6bark`;
    fetch(url, {
      withCredentials: false,
      headers: {
        "content-type": "application/json",
        accept: "application/json",
      },
    })
      .then(async (res) => {
        let dat = await res.json();
        let dat2 = await res.body;
        console.log(dat);
        console.log(dat2);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  initMap = () => {
    this.setState({
      gmapsLoaded: true,
    });
  };

  componentDidMount() {
    window.initMap = this.initMap;
    const gmapScriptEl = document.createElement(`script`);
    gmapScriptEl.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCjysO3MF1vStj7BR3IRTAOOvLa3O6bark&libraries=places&callback=initMap`;
    document.querySelector(`head`).appendChild(gmapScriptEl);
    this.getTeamsData();
  }

  getSpecificTeamDrivers = (teamId) => {
    const firestore = firebase.firestore();
    const teamRef = firestore.collection("teams").doc(teamId);

    teamRef
      .get()
      .then((teamDoc) => {
        if (teamDoc.exists) {
          const teamData = teamDoc.data();
          const teamName = teamData.name;

          console.log("teamId", teamId);
          console.log("teamName", teamName);

          const driversCollectionRef = teamRef.collection("drivers");

          driversCollectionRef
            .get()
            .then((querySnapshot) => {
              console.log("querySnapshot", querySnapshot.docs);
              const driversArr = querySnapshot.docs.map((doc) => {
                const driverData = doc.data();
                console.log("-------------------------", driverData);
                return {
                  id: doc.id,
                  name: driverData.name,
                  companyName: driverData.companyName,
                  email: driverData.email,
                  ownerEmail: teamData.email,
                  mcNumber: teamData.mcNumber,
                  admin: driverData.admin,
                };
              });
              console.log("driversArr", driversArr);
              this.setState({
                drivers: driversArr,
                selectedDriver: driversArr.length > 0 ? driversArr[0] : null,
              });
            })
            .catch((error) => {
              console.log("Error getting drivers collection: ", error);
            });
        } else {
          console.log("Team document not found");
        }
      })
      .catch((error) => {
        console.log("Error getting team document: ", error);
      });
  };

  getTeamsData = () => {
    const firestore = firebase.firestore();
    const collectionRef = firestore.collection("teams");

    collectionRef.onSnapshot((querySnapshot) => {
      const teams = [];
      querySnapshot.forEach((doc) => {
        const teamData = doc.data();
        const companyName = {
          companyName: teamData.companyName,
          id: doc.id,
          name: teamData.name,
          email: teamData.email,
          mcNumber: teamData.mcNumber,
        };
        teams.push(companyName);
      });
      this.setState({ teams });
    });
  };

  applyTemplate = (tempName) => {
    if (tempName === "None") {
      this.setState({
        pickup: "",
        dropoff: [],
        pickupDH: "",
        destinationDH: "",
        locationToAvoid: [],
        startDate: new Date(),
        endDate: new Date(),
        time: "10:00",
        loadType: "Both",
        truckType: "Box Truck",
        teams: [],
        drivers: [],
        selectedDriver: "",
        selectedTeam: [],
        length: "",
        weight: "",
        price: "",
      });
    } else {
      this.props.templates.forEach((template) => {
        if (template.templateName === tempName) {
          this.setState({
            pickup: template?.pickup,
            dropoff: template?.destination,
            pickupDH: template?.pickupDH,
            destinationDH: template?.destinationDH,
            locationToAvoid: template?.locationToAvoid,
            startDate: new Date(),
            endDate: new Date(),
            time: "10:00",
            loadType: template?.fullOrPartial,
            truckType: template?.truckType,
            selectedDriver: template?.driver,
            selectedTeam: { companyName: template?.driver?.companyName, id: 1 },
            pickupPlaceId: template?.pickupPlaceId
              ? template?.pickupPlaceId
              : "Both",
            length: template?.length,
            weight: template?.weight,
            price: template?.price,
          });
        }
      });
    }
  };

  render() {
    const { teams } = this.state;
    const {
      pickup,
      dropoff,
      pickupDH,
      destinationDH,
      locationToAvoid,
      startDate,
      endDate,
      loadType,
      truckType,
      length,
      weight,
      price,
      templateName,
      selectedTemplate,
    } = this.state;

    const selectedDriverName = this.state.selectedDriver?.name || "";
    const selectedTeamName = this.state.selectedTeam?.name || "";

    const stateOptions = [
      "AL",
      "AK",
      "AS",
      "AZ",
      "AR",
      "CA",
      "CO",
      "CT",
      "DE",
      "DC",
      "FM",
      "FL",
      "GA",
      "GU",
      "HI",
      "ID",
      "IL",
      "IN",
      "IA",
      "KS",
      "KY",
      "LA",
      "ME",
      "MH",
      "MD",
      "MA",
      "MI",
      "MN",
      "MS",
      "MO",
      "MT",
      "NE",
      "NV",
      "NH",
      "NJ",
      "NM",
      "NY",
      "NC",
      "ND",
      "MP",
      "OH",
      "OK",
      "OR",
      "PW",
      "PA",
      "PR",
      "RI",
      "SC",
      "SD",
      "TN",
      "TX",
      "UT",
      "VT",
      "VI",
      "VA",
      "WA",
      "WV",
      "WI",
      "WY",
      "AB",
      "BC",
      "MB",
      "NB",
      "NL",
      "NT",
      "NS",
      "NU",
      "ON",
      "PE",
      "QC",
      "SK",
      "YT",
    ];

    return (
      <div>
        <Modal
          show={this.props.showModal}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
          fullscreen={true}
          onHide={() => {
            this.props.onHide();
          }}
        >
          <ModalHeader closeButton>
            <div style={{ flexDirection: "column" }}>
              <ModalTitle>
                {this.props.isTemplate ? "Add New Template" : "Add New Request"}
              </ModalTitle>
              <br />
              {!this?.props?.isTemplate && (
                <div>
                  <label style={{ marginRight: 10 }} htmlFor="name">
                    Apply Template:
                  </label>
                  <select
                    type="text"
                    value={selectedTemplate}
                    onChange={(e) => {
                      this.applyTemplate(e.target.value);
                      this.setState({ selectedTemplate: e.target.value });
                    }}
                  >
                    <option value="None">None</option>
                    {this.props.templates.map((temp) => {
                      return (
                        <option value={temp.templateName}>
                          {temp.templateName}
                        </option>
                      );
                    })}
                  </select>
                </div>
              )}
            </div>
          </ModalHeader>
          <ModalBody>
            <div className="offer-f">
              <div className="f-group">
                <label htmlFor="name">Pickup Location</label>
                <br />
                {
                  <GooglePlacesAutocomplete
                    apiKey="AIzaSyCjysO3MF1vStj7BR3IRTAOOvLa3O6bark"
                    selectProps={{
                      defaultInputValue: pickup,
                      defaultValue: pickup,
                      value: pickup,
                      inputValue: pickup,
                      defaultChecked: true,
                      onInputChange: (text) => {
                        if (text) {
                          console.log(text);
                          this.setState(
                            {
                              pickup: text,
                            },
                            () => {
                              // console.log(pickup);
                            }
                          );
                        }
                      },
                      onChange: (text) => {
                        // console.log(text?.label);
                        this.setState({
                          pickup: text.label,
                          pickupPlaceId: text?.value.place_id,
                        });
                      },
                    }}
                    onLoadFailed={(err) => {
                      console.log(err);
                    }}
                  />
                }
              </div>
              <div className="f-group">
                <label htmlFor="name">Drop-Off Location</label>
                <br />
                <Select
                  isMulti
                  value={dropoff.map((state) => ({
                    value: state,
                    label: state,
                  }))}
                  onChange={(selectedOptions) => {
                    const selectedStates = selectedOptions.map(
                      (option) => option.value
                    );
                    this.setState({ dropoff: selectedStates });
                  }}
                  options={stateOptions.map((state) => ({
                    value: state,
                    label: state,
                  }))}
                />
              </div>

              <div className="f-group">
                <label htmlFor="name">Location To Avoid</label>
                <br />
                <Select
                  isMulti
                  value={locationToAvoid.map((state) => ({
                    value: state,
                    label: state,
                  }))}
                  onChange={(selectedOptions) => {
                    const selectedStates = selectedOptions.map(
                      (option) => option.value
                    );
                    this.setState({ locationToAvoid: selectedStates });
                  }}
                  options={stateOptions.map((state) => ({
                    value: state,
                    label: state,
                  }))}
                />
              </div>
              {/* 
              {!this.props.isTemplate && (
                <div className="f-group">
                  <label htmlFor="name">Time</label>
                  <br />
                  <TimePicker
                    onChange={(time) => {
                      this.setState({ time });
                    }}
                    value={time}
                  />
                </div>
              )} */}
              <div className="f-group">
                <label htmlFor="name">PickupDH</label>
                <br />
                <input
                  type="text"
                  value={pickupDH}
                  onChange={(e) => {
                    this.setState({ pickupDH: e.target.value });
                  }}
                />
              </div>
              <div className="f-group">
                <label htmlFor="name">DestinationDH</label>
                <br />
                <input
                  type="text"
                  value={destinationDH}
                  onChange={(e) => {
                    const input = e.target.value;
                    const regex = /^[0-9]*$/;
                    if (regex.test(input)) {
                      this.setState({ destinationDH: input });
                    }
                  }}
                />
              </div>

              {!this.props.isTemplate && (
                <div className="f-group">
                  <label htmlFor="name">Start Date</label>
                  <br />
                  <DatePicker
                    selected={startDate}
                    onChange={(startDate) => {
                      console.log(startDate, "startDate");
                      this.setState({ startDate });
                    }}
                  />
                </div>
              )}

              {!this.props.isTemplate && (
                <div className="f-group">
                  <label htmlFor="name">End Date</label>
                  <br />
                  <DatePicker
                    selected={endDate}
                    onChange={(endDate) => {
                      console.log(endDate, "endDate");
                      this.setState({ endDate });
                    }}
                  />
                </div>
              )}

              <div className="f-group">
                <label htmlFor="name">Truck Type</label>
                <br />
                <select
                  type="text"
                  value={truckType}
                  onChange={(e) => {
                    this.setState({ truckType: e.target.value });
                  }}
                >
                  <option value="Straight Box Truck">Box Truck</option>
                  <option value="Flatbed">Flatbed</option>
                  <option value="Power-Only">Power-Only</option>
                  <option value="Flatbed Hotshot">Hotshot - Flatbed</option>
                  <option value="Van">Dryvan</option>
                  <option value="Reefer">Reefer</option>
                  <option value="Van Hotshot">Cargo Van</option>
                </select>
              </div>

              <div className="f-group">
                <label htmlFor="name">Length</label>
                <br />
                <input
                  type="text"
                  value={length}
                  onChange={(e) => {
                    this.setState({ length: e.target.value });
                  }}
                />
              </div>
              <div className="f-group">
                <label htmlFor="name">Weight</label>
                <br />
                <input
                  type="text"
                  value={weight}
                  onChange={(e) => {
                    this.setState({ weight: e.target.value });
                  }}
                />
              </div>

              <div className="f-group">
                <label htmlFor="name">Team Name/Company Name</label>
                <br />
                <div
                  className={`dropdown ${
                    this.state.showTeamDropdown ? "open" : ""
                  }`}
                  onClick={() =>
                    this.setState({
                      showTeamDropdown: !this.state.showTeamDropdown,
                    })
                  }
                >
                  <input
                    type="text"
                    value={this.state.selectedTeam?.companyName || ""}
                  />
                  {this.state.showTeamDropdown && (
                    <div
                      className="dropdown-options"
                      style={{
                        position: "absolute",
                        top: "100%",
                        left: "50%",
                        transform: "translateX(-50%)",
                        width: "80%",
                        backgroundColor: "#fff",
                        border: "1px solid #ccc",
                        borderTop: "none",
                        borderRadius: "0 0 4px 4px",
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                        zIndex: 1,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      {teams.map((teamName) => (
                        <div
                          key={teamName.id}
                          onClick={() => {
                            this.getSpecificTeamDrivers(teamName?.id);
                            this.setState({
                              selectedTeam: teamName,
                              showTeamDropdown: false,
                            });
                          }}
                          style={{ padding: "5px 0", cursor: "pointer" }}
                        >
                          {teamName?.companyName}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>

              <div className="f-group">
                <label htmlFor="name">Driver Name</label>
                <br />
                <div
                  className={`dropdown ${
                    this.state.showDriverDropdown ? "open" : ""
                  }`}
                  onClick={() =>
                    this.setState({
                      showDriverDropdown: !this.state.showDriverDropdown,
                    })
                  }
                >
                  <input type="text" value={selectedDriverName} />

                  {this.state.showDriverDropdown && (
                    <div
                      className="dropdown-options"
                      style={{
                        position: "absolute",
                        top: "100%",
                        left: "50%",
                        transform: "translateX(-50%)",
                        width: "80%",
                        backgroundColor: "#fff",
                        border: "1px solid #ccc",
                        borderTop: "none",
                        borderRadius: "0 0 4px 4px",
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                        zIndex: 1,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      {/* Render the drivers from the selected team */}
                      {this.state.selectedTeam && (
                        <div>
                          <div
                            key={this.state.selectedTeam.id}
                            onClick={() => {
                              this.setState({
                                selectedDriver: {
                                  id: this.state.selectedTeam.id,
                                  name: this.state.selectedTeam.name,
                                  companyName:
                                    this.state.selectedTeam.companyName,
                                  mcNumber: this.state.selectedTeam.mcNumber,
                                  email: this.state.selectedTeam.email,
                                },
                                showDriverDropdown: false,
                              });
                            }}
                            style={{ padding: "5px 0", cursor: "pointer" }}
                          >
                            {selectedTeamName}
                          </div>

                          {this.state.drivers.map((driver) =>
                            driver.companyName ===
                            this.state.selectedTeam.companyName ? (
                              <div
                                key={driver.id}
                                onClick={() => {
                                  this.setState({
                                    selectedDriver: driver,
                                    showDriverDropdown: false,
                                  });
                                }}
                                style={{ padding: "5px 0", cursor: "pointer" }}
                              >
                                {driver.name}
                              </div>
                            ) : null
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>

              <div className="f-group">
                <label htmlFor="name">Full/Partial</label>
                <br />
                <select
                  type="text"
                  value={loadType}
                  onChange={(e) => {
                    this.setState({ loadType: e.target.value });
                  }}
                >
                  <option value="Partial">Partial</option>
                  <option value="Full">Full</option>
                  <option value="Both">Both</option>
                </select>
              </div>

              <div className="f-group">
                <label htmlFor="name">Price</label>
                <br />
                <input
                  type="text"
                  value={price}
                  onChange={(e) => {
                    this.setState({ price: e.target.value });
                  }}
                />
              </div>
              {this.props.isTemplate && (
                <div className="f-group">
                  <label htmlFor="name">Template Name</label>
                  <br />
                  <input
                    type="text"
                    value={templateName}
                    style={{ padding: 10 }}
                    onChange={(e) => {
                      this.setState({ templateName: e.target.value });
                    }}
                  />
                </div>
              )}
              <br />
              <div className="f-group" />
              <div className="f-group" />
              <div className="f-group" />
              <div>
                <Button type="submit" onClick={this.onSubmit}>
                  {!this.props.isTemplate ? "Send Request" : "Save Template"}
                </Button>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
export default NewRequestModal;
