/* eslint-disable */
import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./../App.css";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import firebase, { firebaseConfig } from "./../firebase/firebase";
import logo from "../img/gohaul-logo.png";
import gif from "../img/truck.gif";

const styles = (theme) => ({
  progess: {
    position: "absolute",
  },
});

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth();

class login extends Component {
  componentDidMount() {
    // Add overflow: hidden to the body element
    document.body.style.overflow = "hidden";
  }

  componentWillUnmount() {
    // Reset overflow property when component unmounts
    document.body.style.overflow = "initial";
  }

  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      errors: [],
      loading: false,
    };
  }

  handleChange = (event, type) => {
    this.setState({
      [type]: event.target.value,
    });
  };

  handleSubmit = (event) => {
    this.setState({ loading: true });
    signInWithEmailAndPassword(auth, this.state.email, this.state.password)
      .then(async (res) => {
        firebase
          .firestore()
          .collection("dispatchers")
          .doc(res.user.uid)
          .get()
          .then(async (re) => {
            if (re.exists) {
              let dat = re.data();
              if (dat.role === "Dispatcher") {
                await localStorage.setItem("role", "Dispatcher");
                await localStorage.setItem("isLogin", re.data().name);
                await localStorage.setItem("id", res.user.uid);
                firebase
                  .firestore()
                  .collection("statistics")
                  .doc("admin")
                  .get()
                  .then((res) => {
                    let dat = res.data().total_active_dispatcher;
                    let dat1 = res.data().total_inactive_dispatcher;
                    dat = dat + 1;
                    dat1 = dat1 - 1;
                    firebase
                      .firestore()
                      .collection("statistics")
                      .doc("admin")
                      .set(
                        {
                          total_active_dispatcher: dat,
                          total_inactive_dispatcher: dat1,
                        },
                        { merge: true }
                      );
                  });
                this.setState({
                  loading: false,
                });
                this.props.onLogin();
              } else {
                await localStorage.setItem("role", "Admin");
                await localStorage.setItem("isLogin", re.data().name);
                await localStorage.setItem("id", res.user.uid);
                this.setState({
                  loading: false,
                });
                this.props.onLogin();
              }
            } else {
              await localStorage.setItem("role", "Admin");
              await localStorage.setItem("isLogin", "Admin");
              await localStorage.setItem("id", res.user.uid);
              this.setState({
                loading: false,
              });
              this.props.onLogin();
            }
          });
        console.log(res.user);
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
        alert("No user found or incorrect password");
      });
  };

  render() {
    const { classes } = this.props;
    const { loading } = this.state;

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#f1f1f1",
          overflowX: "hidden",
          height: "100vh",
          background: `linear-gradient(
            45deg,
            transparent 92px,
            rgba(191, 64, 191, 1) 92px,
            rgba(191, 64, 191, 1) 96px,
            transparent 96px
          )`,
          backgroundSize: `100px 100px`,
        }}
      >
        <div
          style={{
            display: "flex",
            width: "800px",
            backgroundColor: "#ffffff",
            borderRadius: "4px",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          }}
        >
          <div
            style={{
              flex: "1",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding: "40px",
              backgroundColor: "#7e57c2",
              color: "#ffffff",
              borderTopLeftRadius: "4px",
              borderBottomLeftRadius: "4px",
            }}
          >
            <img
              alt="Logo"
              src={logo}
              style={{
                width: 100,
                height: 100,
                marginBottom: "20px",
                fontSize: "32px",
                fontWeight: "bold",
              }}
            />
            <p style={{ fontSize: "16px", textAlign: "center" }}>
              Welcome to GoHaul
            </p>
          </div>
          <div
            style={{
              flex: "1",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "40px",
            }}
          >
            {loading && (
              <CircularProgress size={80} className={classes.progess} />
            )}
            <div style={{ width: "400px" }}>
              <h2
                style={{
                  marginBottom: "20px",
                  color: "#333",
                  textAlign: "center",
                  textTransform: "uppercase",
                }}
              >
                Login
              </h2>
              <form>
                <div style={{ marginBottom: "20px" }}>
                  <label
                    htmlFor="email"
                    style={{
                      display: "block",
                      marginBottom: "5px",
                      color: "#666",
                    }}
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    onChange={(e) => {
                      this.handleChange(e, "email");
                    }}
                    style={{
                      width: "100%",
                      padding: "10px",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      fontFamily: "Arial, sans-serif",
                    }}
                    required
                  />
                </div>
                <div style={{ marginBottom: "20px" }}>
                  <label
                    htmlFor="password"
                    style={{
                      display: "block",
                      marginBottom: "5px",
                      color: "#666",
                    }}
                  >
                    Password
                  </label>
                  <input
                    type="password"
                    id="password"
                    onChange={(e) => {
                      this.handleChange(e, "password");
                    }}
                    style={{
                      width: "100%",
                      padding: "10px",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      fontFamily: "Arial, sans-serif",
                    }}
                    required
                  />
                </div>
                <button
                  type="button"
                  onClick={this.handleSubmit}
                  href="/Dash"
                  disabled={
                    loading || !this.state.email || !this.state.password
                  }
                  style={{
                    width: "100%",
                    padding: "10px",
                    color: "#ffffff",
                    backgroundColor: "#7e57c2",
                    border: "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                    fontWeight: "bold",
                    textTransform: "uppercase",
                    transition: "background-color 0.3s ease",
                  }}
                >
                  Login
                </button>
              </form>
              <div
                style={{
                  position: "absolute",
                  bottom: "0",
                  left: "0",
                  right: "0",
                  marginLeft: "auto",
                  marginRight: "auto",
                  animation: "truckAnimation 6s linear infinite",
                }}
              >
                <img
                  src={gif}
                  alt="Truck"
                  style={{
                    width: "100px",
                    height: "100px",
                    transform: "translateX(-100%)",
                  }}
                />
              </div>
              <style>
                {`
                @keyframes truckAnimation {
                  0% {
                    transform: translateX(-50%);
                    opacity: 0;
                  }
                  10% {
                    transform: translateX(0);
                    opacity: 1;
                  }
                  95% {
                    transform: translateX(110%);
                    opacity: 1;
                  }
                  97% {
                    transform: translateX(120%);
                    opacity: 0;
                  }
                  100% {
                    transform: translateX(-50%);
                    opacity: 0;
                  }}`}
              </style>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(login);
