import React, { Component } from "react";
import { Button, Modal, ModalBody, ModalTitle } from "react-bootstrap";
import firebase from "./../firebase/firebase";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TimePicker from "react-time-picker";
import SendPushNotification from "./../firebase/SendPushNotification";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import { utils, read } from "xlsx";
import { logActivity, name } from "../helper/ActivityLogger";

class AddOfferModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      // selectedItem: props.selectedItem,
      pickup: "",
      dropoff: "",
      date: new Date(),
      time: "10:00",
      loadType: "",
      company: "",
      notes: "",
      length: "",
      weight: "",
      price: "",
      pickupPlaceId: null,
      // dropoffPlaceId: null,
      gmapsLoaded: false,
      offers: [],
      loading: false,
      pageSize: 50,
      isEditing: false,
      selectedOfferId: "",
    };
  }

  onSubmit = () => {
    const {
      pickup,
      dropoff,
      date,
      time,
      company,
      notes,
      loadType,
      length,
      weight,
      price,
      pickupPlaceId,
      // dropoffPlaceId,
    } = this.state;
    const { selectedItem } = this.props;
    if (pickup === "") {
    } else if (dropoff === "") {
    } else if (date === "") {
    } else if (time === "") {
    } else if (loadType === "") {
    } else if (length === "") {
    } else if (weight === "") {
    } else if (company === "") {
    } else if (price === "") {
    } else {
      let offer = {
        pickup: pickup,
        destination: dropoff,
        length: length,
        weight: weight,
        fullOrPartial: loadType,
        date: date,
        time: time,
        company: company,
        notes: notes,
        status: "offer",
        price: price,
        pickupPlaceId: pickupPlaceId,
        // dropoffPlaceId: dropoffPlaceId,
        driver: selectedItem?.driver,
      };
      console.log("Add OFFER=>", offer);
      firebase
        .firestore()
        .collection("searchRequests")
        .doc(selectedItem.pathId)
        .collection("offers")
        .add(offer)
        .then(() => {
          logActivity(
            `${name} created an offer for Load [id: ${selectedItem.pathId}]`,
            "Search Requests"
          );
          alert("New Offer Added!");
        });
      this.props.onHide();
    }
  };

  onUpdate = () => {
    const {
      pickup,
      dropoff,
      date,
      time,
      company,
      notes,
      loadType,
      length,
      weight,
      price,
      pickupPlaceId,
      // dropoffPlaceId,
      selectedOfferId,
    } = this.state;
    const { selectedItem } = this.props;
    if (pickup === "") {
    } else if (dropoff === "") {
    } else if (date === "") {
    } else if (time === "") {
    } else if (loadType === "") {
    } else if (length === "") {
    } else if (weight === "") {
    } else if (company === "") {
    } else if (price === "") {
    } else {
      let offer = {
        pickup: pickup,
        destination: dropoff,
        length: length,
        weight: weight,
        fullOrPartial: loadType,
        date: date,
        time: time,
        company: company,
        notes: notes,
        status: "offer",
        price: price,
        pickupPlaceId: pickupPlaceId,
        // dropoffPlaceId: dropoffPlaceId,

        driver: selectedItem?.driver,
      };
      console.log("Add OFFER=>", offer);
      firebase
        .firestore()
        .collection("searchRequests")
        .doc(selectedItem.pathId)
        .collection("offers")
        .doc(selectedOfferId)
        .update(offer)
        .then(() => {
          alert("Your Offer Updated!");
          this.setState({
            isEditing: false,
            pickup: "",
            pickupPlaceId: "",
            notes: "",
            price: "",
            date: "",
            time: "",
            length: "",
            height: "",
            loadType: "",
            company: "",
            weight: "",
            selectedOfferId: "",
          });
        });

      this.props.onHide();
    }
  };

  searchLocation = (type, placeId) => {
    let url = `https://maps.googleapis.com/maps/api/place/details/json?place_id=${placeId}&key=AIzaSyCjysO3MF1vStj7BR3IRTAOOvLa3O6bark`;
    fetch(url, {
      withCredentials: false,
      headers: {
        "content-type": "application/json",
        accept: "application/json",
      },
    })
      .then(async (res) => {
        let dat = await res.json();
        let dat2 = await res.body;
        console.log(dat);
        console.log(dat2);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
    // axios.get(url,{
    //     headers: {
    //         'Accept': 'application/json',
    //         "Access-Control-Allow-Origin":"*"
    //      },
    //     withCredentials: false,
    //     mode:'no-cors'
    // })
    //     .then(res => {
    //         const persons = res.data;
    //         console.log(persons)
    //         // this.setState({ persons });
    //     })
  };

  addMultiOffers = (
    pickup,
    dropoff,
    date,
    time,
    length,
    weight,
    loadType,
    company,
    notes,
    price
  ) => {
    const { selectedItem } = this.props;
    let offer = {
      pickup: pickup,
      destination: dropoff,
      length: length,
      weight: weight,
      fullOrPartial: loadType,
      date: date,
      time: time,
      company: company,
      notes: notes,
      status: "offer",
      price: price,
      pickupPlaceId: "pickupPlaceId",
      // dropoffPlaceId: "dropoffPlaceId",

      driver: selectedItem?.driver,
    };
    console.log("Add OFFER=>", offer);
    firebase
      .firestore()
      .collection("searchRequests")
      .doc(selectedItem.pathId)
      .collection("offers")
      .add(offer)
      .then(() => {
        // alert('New Offer Added!')
      });

    this.props.onHide();
  };

  initMap = () => {
    this.setState({
      gmapsLoaded: true,
    });
  };

  getPreviousOffers = async () => {
    this.setState({ loading: true });
    const { selectedItem } = this.props;
    let offers = [];
    const offersSnapShot = await firebase
      .firestore()
      .collection("searchRequests")
      .doc(selectedItem?.pathId)
      .collection("offers")
      .get();
    offersSnapShot.forEach((result) => {
      offers.push({ id: result.id, ...result.data() });
    });
    console.log("offers", offers);
    this.setState({ offers, loading: false });
  };

  deleteOffer = async (id) => {
    const { selectedItem } = this.props;
    await firebase
      .firestore()
      .collection("searchRequests")
      .doc(selectedItem?.pathId)
      .collection("offers")
      .doc(id)
      .delete();
    alert("offer deleted successfully");
    this.getPreviousOffers();
  };

  componentDidMount() {
    this.getPreviousOffers();
    window.initMap = this.initMap;
    const gmapScriptEl = document.createElement(`script`);
    gmapScriptEl.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCjysO3MF1vStj7BR3IRTAOOvLa3O6bark&libraries=places&callback=initMap`;
    document
      .querySelector(`body`)
      .insertAdjacentElement(`beforeend`, gmapScriptEl);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props?.selectedItem &&
      this.props?.selectedItem?.pathId !== prevProps?.selectedItem?.pathId
    ) {
      this.getPreviousOffers();
    }
  }

  convertTimeStamp = (timestamp) => {
    if (timestamp.seconds) {
      return timestamp.toDate();
    } else {
      return new Date(timestamp);
    }
  };

  render() {
    const {
      pickup,
      dropoff,
      date,
      time,
      company,
      notes,
      loadType,
      length,
      weight,
      price,
      loading,
      offers,
      pageSize,
      isEditing,
    } = this.state;
    const { selectedItem } = this.props;
    return (
      <div>
        <Modal
          show={this.props.showModal}
          // size="lg"
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
          fullscreen={true}
          onHide={() => {
            this.props.onHide();
            // this.getPreviousOffers()
          }}
        >
          <ModalHeader closeButton>
            <ModalTitle>
              Add New Offer for {selectedItem?.driver?.name}
            </ModalTitle>
          </ModalHeader>
          <ModalBody>
            <div>
              <label htmlFor={`files$`} className="btn btn-success mt-2">
                Upload CSV
              </label>
              <input
                id={`files$`}
                accept=".csv,.xlsx,.xls"
                onChange={(e) => {
                  e.preventDefault();
                  const file = e.target.files[0];
                  const reader = new FileReader();
                  reader.onload = (evt) => {
                    const bstr = evt.target.result;
                    const wb = read(bstr, { type: "binary", cellDates: true });
                    const wsname = wb.SheetNames[0];
                    const ws = wb.Sheets[wsname];
                    const data = utils.sheet_to_json(ws, { header: 1 });
                    // eslint-disable-next-line
                    const keys = data[0];
                    data.shift();
                    const values = data;
                    for (let i = 0; i < values.length; i++) {
                      const element = values[i];
                      this.addMultiOffers(
                        element[0],
                        element[1],
                        element[2],
                        element[3],
                        element[4],
                        element[5],
                        element[6],
                        element[7],
                        element[8],
                        element[9]
                      );
                    }
                  };
                  reader.readAsBinaryString(file);
                }}
                style={{ visibility: "hidden" }}
                type="file"
              />
              {/* {image && <button onClick={() => addDocument(image[0], item)} >upload now</button>} */}
            </div>
            <div className="offer-f">
              <div className="f-group">
                <label htmlFor="name">Pickup Location</label>
                <br />
                {/* {this.state.gmapsLoaded && ( */}
                <GooglePlacesAutocomplete
                  apiKey="AIzaSyCjysO3MF1vStj7BR3IRTAOOvLa3O6bark"
                  selectProps={{
                    defaultInputValue: pickup,
                    defaultValue: pickup,
                    // value: pickup,
                    // inputValue: pickup,
                    pickup,
                    defaultChecked: true,
                    onChange: (text) => {
                      console.log(text?.value.place_id);
                      this.setState({
                        pickup: text.label,
                        pickupPlaceId: text?.value.place_id,
                      });
                      // this.searchLocation("pickup", text?.value.place_id)
                    },
                  }}
                  onLoadFailed={(err) => {
                    console.log(err);
                  }}
                />
                {/* )} */}
              </div>
              <div className="f-group">
                <label htmlFor="name">Drop-Off Location</label>
                <br />
                {/* {this.state.gmapsLoaded && ( */}
                <GooglePlacesAutocomplete
                  apiKey="AIzaSyCjysO3MF1vStj7BR3IRTAOOvLa3O6bark"
                  selectProps={{
                    // value: dropoff,
                    // inputValue: dropoff,
                    dropoff,
                    onChange: (text) => {
                      console.log(text);
                      // alert(JSON.stringify(text))
                      this.setState({
                        dropoff: text.label,
                        // dropoffPlaceId: text?.value.place_id,
                      });
                    },
                  }}
                />
                {/* )} */}
              </div>
              <div className="f-group">
                <label htmlFor="name">Date</label>
                <br />
                <DatePicker
                  selected={date}
                  onChange={(date) => {
                    console.log(date, "date");
                    this.setState({ date });
                  }}
                />
                {/* <input type="text" /> */}
              </div>
              <div className="f-group">
                <label htmlFor="name">Time</label>
                <br />
                <TimePicker
                  onChange={(time) => {
                    // alert(time)
                    this.setState({ time });
                  }}
                  value={time}
                />
                {/* <input type="text" /> */}
              </div>
              <div className="f-group">
                <label htmlFor="name">Length</label>
                <br />
                <input
                  type="text"
                  value={length}
                  onChange={(e) => {
                    this.setState({ length: e.target.value });
                  }}
                />
              </div>
              <div className="f-group">
                <label htmlFor="name">Weight</label>
                <br />
                <input
                  type="text"
                  value={weight}
                  onChange={(e) => {
                    this.setState({ weight: e.target.value });
                  }}
                />
              </div>

              <div className="f-group">
                <label htmlFor="name">Full/Partial</label>
                <br />
                <input
                  type="text"
                  value={loadType}
                  onChange={(e) => {
                    this.setState({ loadType: e.target.value });
                  }}
                />
              </div>
              <div className="f-group">
                <label htmlFor="name">Company Name</label>
                <br />
                <input
                  type="text"
                  value={company}
                  onChange={(e) => {
                    this.setState({ company: e.target.value });
                  }}
                />
              </div>
              <div className="f-group">
                <label htmlFor="name">Notes</label>
                <br />
                <input
                  type="text"
                  value={notes}
                  onChange={(e) => {
                    this.setState({ notes: e.target.value });
                  }}
                />
              </div>
              <div className="f-group">
                <label htmlFor="name">Price</label>
                <br />
                <input
                  type="text"
                  value={price}
                  onChange={(e) => {
                    this.setState({ price: e.target.value });
                  }}
                />
              </div>
              <br />
              <div className="f-group" />
              <div className="f-group" />
              <div className="f-group" />

              {isEditing ? (
                <div
                  style={{
                    flexDirection: "column",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Button
                    type="submit"
                    value="Confirm"
                    onClick={() => {
                      this.onUpdate();
                    }}
                    text="update Offer"
                  >
                    update Offer
                  </Button>
                  <Button
                    // type="submit"
                    // value="Confirm"
                    className="btn btn-danger"
                    style={{ margin: 10, marginTop: 10 }}
                    onClick={() => {
                      this.setState({
                        isEditing: false,
                        pickup: "",
                        pickupPlaceId: "",
                        dropoff: "",
                        // dropoffPlaceId: "",
                        notes: "",
                        price: "",
                        date: "",
                        time: "",
                        length: "",
                        height: "",
                        loadType: "",
                        company: "",
                        weight: "",
                        selectedOfferId: "",
                      });
                    }}
                    text="cancel"
                  >
                    cancel
                  </Button>
                </div>
              ) : (
                <div>
                  <Button
                    type="submit"
                    value="Confirm"
                    onClick={async () => {
                      var title = "OFFER ADDED";
                      var body = "New offer added to your search request!";
                      var notificationData = await firebase
                        .firestore()
                        .collection("templates")
                        .doc("Offer")
                        .get();
                      if (notificationData.exists) {
                        notificationData = notificationData?.data();
                        body = notificationData?.body;
                        title = notificationData?.title;
                      }
                      SendPushNotification(
                        title,
                        `Dear ${selectedItem.driver?.name}! ${body}`,
                        selectedItem.driver?.id
                      );
                      this.onSubmit();
                    }}
                    text="Add Offer"
                  >
                    Add Offer
                  </Button>
                </div>
              )}
            </div>
            <div
              style={{
                height: "70vh",
                width: "95%",
                margin: "0 auto",
                marginTop: 30,
              }}
            >
              <DataGrid
                loading={loading}
                rows={offers}
                columns={[
                  {
                    headerName: "Driver Name",
                    field: "driver",
                    flex: 1,
                    minWidth: 200,
                    valueGetter: ({ row }) => row.driver?.name,
                    renderCell: ({ row }) => {
                      return (
                        <h5 style={{ marginLeft: 10 }}>{row.driver?.name}</h5>
                      );
                    },
                  },
                  {
                    headerName: "Pick-up",
                    field: "pickup",
                    flex: 1,
                    minWidth: 200,
                  },
                  {
                    headerName: "Drop-off",
                    field: "destination",
                    flex: 1,
                    minWidth: 200,
                  },
                  {
                    headerName: "Truck size/length",
                    field: "length",
                    flex: 1,
                    minWidth: 150,
                    type: "number",
                  },
                  {
                    headerName: "Weight",
                    field: "weight",
                    flex: 1,
                    minWidth: 100,
                    type: "number",
                  },
                  {
                    headerName: "Price",
                    field: "price",
                    flex: 1,
                    minWidth: 100,
                    type: "number",
                  },
                  {
                    headerName: "Notes",
                    field: "notes",
                    flex: 1,
                    minWidth: 150,
                  },
                  {
                    headerName: "Load type",
                    field: "fullOrPartial",
                    flex: 1,
                    minWidth: 120,
                  },
                  {
                    headerName: "Date",
                    field: "date",
                    flex: 1,
                    minWidth: 250,
                    renderCell: ({ row }) => {
                      return (
                        <text style={{ marginLeft: 10 }}>
                          {this.convertTimeStamp(row?.date).toDateString()}
                        </text>
                      );
                    },
                  },
                  {
                    headerName: "Truck",
                    field: "truck",
                    flex: 1,
                    minWidth: 250,
                  },
                  {
                    headerName: "TRIP",
                    field: "trip",
                    flex: 1,
                    minWidth: 250,
                  },
                  {
                    headerName: "Company",
                    field: "company",
                    flex: 1,
                    minWidth: 250,
                  },
                  {
                    headerName: "Contact",
                    field: "contact",
                    flex: 1,
                    minWidth: 250,
                  },
                  {
                    headerName: "Actions",
                    flex: 1,
                    minWidth: 400,
                    sortable: false,
                    renderCell: ({ row }) => {
                      return (
                        <div>
                          <button
                            className="btn btn-primary"
                            style={{}}
                            onClick={() => {
                              // console.log({
                              //     isEditing: true,
                              //     pickup: row?.pickup,
                              //     pickupPlaceId: row?.pickupPlaceId,
                              //     date1: row?.date.toDate(),
                              //     date: new Date(row?.date),
                              //     time: row?.time
                              // });
                              // return
                              this.setState({
                                isEditing: true,
                                pickup: row?.pickup,
                                pickupPlaceId: row?.pickupPlaceId,
                                dropoff: row?.destination,
                                // dropoffPlaceId: row?.dropoffPlaceId,
                                notes: row?.notes,
                                price: row?.price,
                                date: this.convertTimeStamp(row?.date),
                                time: row?.time,
                                length: row?.length,
                                height: row?.height,
                                loadType: row?.fullOrPartial,
                                company: row?.company,
                                weight: row?.weight,
                                selectedOfferId: row?.id,
                              });
                            }}
                          >
                            Edit Offer
                          </button>
                          <button
                            className="btn btn-danger m-2"
                            onClick={() => {
                              this.deleteOffer(row?.id);
                            }}
                          >
                            Delete Offer
                          </button>
                        </div>
                      );
                    },
                  },
                ]}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) =>
                  this.setState({ pageSize: newPageSize })
                }
                rowsPerPageOptions={[5, 10, 20, 50]}
                pagination
                autoPageSize
              />
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
export default AddOfferModal;
