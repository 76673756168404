/* eslint-disable */
import React, { Component } from "react";
import addNotification from "react-push-notification";
import Users from "./triplist";
import firebase from "./../firebase/firebase";
import LoadDetailsModal from "./../customViews/LoadDetailsModal";
import SendPushNotification from "./../firebase/SendPushNotification";
import { logActivity, name } from "../helper/ActivityLogger";
import emailNotifier from "../helper/emailNotifier";
class Trips extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      ApproveList: [],
      ReadyForPickupList: [],
      enrouteToLoadList: [],
      loadedList: [],
      enrouteToDropList: [],
      DroppedList: [],
      DeleviredList: [],
      showDetailsModal: false,
      selectedItem: null,
    };
  }
  getAllLoads = async () => {
    let ref = await firebase.firestore().collection("loads");
    ref.onSnapshot((snap) => {
      let asd = snap.docs;
      const tempApprove = [];
      const readyForPickup = [];
      const enrouteToLoad = [];
      const loaded = [];
      const enrouteToDrop = [];
      const dropped = [];
      const delivered = [];
      snap.docs.forEach(async (b) => {
        const a = b.data().currentLoad;
        let convDate = a?.loadDate?.toDate();
        if (a.status === "approve") {
          tempApprove.push({ ...a, loadDate: convDate, itemId: a.id });
        } else if (a.status === "readyForPickup") {
          readyForPickup.push({ ...a, loadDate: convDate, itemId: a.id });
        }
        if (a.status === "enrouteToLoad") {
          enrouteToLoad.push({ ...a, loadDate: convDate, itemId: a.id });
        }
        if (a.status === "loaded") {
          loaded.push({ ...a, loadDate: convDate, itemId: a.id });
        }
        if (a.status === "enrouteToDrop") {
          enrouteToDrop.push({ ...a, loadDate: convDate, itemId: a.id });
        }
        if (a.status === "dropped") {
          dropped.push({ ...a, loadDate: convDate, itemId: a.id });
        }
        if (a.status === "delivered") {
          delivered.push({ ...a, loadDate: convDate, itemId: a.id });
        }
      });
      this.setState({
        ApproveList: tempApprove,
        ReadyForPickupList: readyForPickup,
        enrouteToLoadList: enrouteToLoad,
        loadedList: loaded,
        enrouteToDropList: enrouteToDrop,
        DroppedList: dropped,
        DeleviredList: delivered,
      });
    });
  };

  async componentDidMount() {
    // this.temp()
    this.getAllLoads();
  }
  deleteSearchRequest = async (id) => {
    try {
      return new Promise((resolve, reject) => {
        const requestRef = firebase.firestore().collection("searchRequests");
        let colRef = requestRef.doc(id).collection("offers");

        colRef.get().then((querySnapshot) => {
          Promise.all(querySnapshot.docs.map((d) => d.ref.delete()));
        });
        requestRef
          .doc(id)
          .delete()
          .then(() => {
            resolve({ status: true });
          });
      });
    } catch (e) {
      console.log("ERROR", e);
    }
  };

  setAddress = async (location, type, item) => {
    if (type === "Pickup") {
      firebase
        .firestore()
        .collection("loads")
        .doc(item.loadId)
        .set(
          {
            currentLoad: {
              pickup: location,
            },
          },
          { merge: true }
        );
      this.getAllLoads();
    } else if (type === "Destination") {
      firebase
        .firestore()
        .collection("loads")
        .doc(item.loadId)
        .set(
          {
            currentLoad: {
              destination: location,
            },
          },
          { merge: true }
        );
      this.getAllLoads();
    }
  };

  showNotification = (title, message) => {
    addNotification({
      title: title,
      message: message,
      native: true,
      duration: 20000,
    });
  };

  onAction = async (action, item) => {
    if (action === "readyForPickup") {
      firebase
        .firestore()
        .collection("loads")
        .doc(item.loadId)
        .set(
          {
            currentLoad: {
              status: "readyForPickup",
            },
          },
          { merge: true }
        );
      this.getAllLoads();
      this.deleteSearchRequest(item.loadId);
      logActivity(
        name +
          " updated the load stutus of load [id: " +
          item.loadId +
          "] to Ready For Pickup.",
        "Loads"
      );
      alert("Status Updated successfully");
      SendPushNotification(
        "LOAD READY FOR PICKUP",
        `Dear ${item.driver?.name}! Your load is ready for pickup from "${item.pickup}" to "${item.destination}"`,
        item.driver?.id
      );
      if (item.driver?.admin) {
        SendPushNotification(
          "LOAD READY FOR PICKUP",
          `${item.driver?.name}'s load is ready for pickup from "${item.pickup}" to "${item.destination}"`,
          item.driver?.admin
        );
      }
      emailNotifier(
        "LOAD STATUS CHANGED",
        name +
          " updated the load stutus of load [id: " +
          item.loadId +
          "] to Ready For Pickup."
      );
      emailNotifier(
        "LOAD STATUS CHANGED",
        "Hi, Your Load has been added to Ready For Pickup tab. Please check GoHaul to start your load",
        item.driver.email
      );
    } else if (action === "enrouteToLoad") {
      const result = window.confirm(
        "Would you like to advance this load to the next status?"
      );

      if (result) {
        firebase
          .firestore()
          .collection("loads")
          .doc(item.loadId)
          .set(
            {
              currentLoad: {
                status: "enrouteToLoad",
                tripDateTime: {
                  enrouteToLoad: new Date(),
                },
              },
            },
            { merge: true }
          );
        this.getAllLoads();
        logActivity(
          name +
            " updated the load stutus of load [id: " +
            item.loadId +
            "] to Enroute To Pickup.",
          "Loads"
        );
        emailNotifier(
          "LOAD STATUS CHANGED",
          name +
            " updated the load stutus of load [id: " +
            item.loadId +
            "] to Enroute To Pickup."
        );
        alert("Status Updated successfully");
        SendPushNotification(
          "LOAD STATUS UPDATED",
          `Dear ${item.driver?.name}! Your load status has been updated from "Ready to Pickup" To "Enroute to pickup"`,
          item.driver?.id
        );
        this.showNotification(
          "LOAD STATUS UPDATED",
          `${item.driver?.name}s!  load status has been updated from "Ready to Pickup" To "Enroute to pickup"`
        );
        if (item?.driver?.admin) {
          SendPushNotification(
            "LOAD STATUS UPDATED",
            `${item.driver?.name}'s load status has been updated from "Ready to Pickup" To "Enroute to pickup"`,
            item.driver?.admin
          );
        }
        emailNotifier(
          "LOAD STATUS CHANGED",
          "Hi, Your Load has been added to Enroute to Pickup tab. Please check GoHaul to see your load.",
          item.driver.email
        );
      }
    } else if (action === "loaded") {
      const result = window.confirm(
        "Would you like to advance this load to the next status?"
      );

      if (result) {
        firebase
          .firestore()
          .collection("loads")
          .doc(item.loadId)
          .set(
            {
              currentLoad: {
                status: "loaded",
                tripDateTime: {
                  loaded: new Date(),
                },
              },
            },
            { merge: true }
          );
        this.getAllLoads();
        logActivity(
          name +
            " updated the load stutus of load [id: " +
            item.loadId +
            "] to Loaded.",
          "Loads"
        );
        emailNotifier(
          "LOAD STATUS CHANGED",
          name +
            " updated the load stutus of load [id: " +
            item.loadId +
            "] to Loaded."
        );
        emailNotifier(
          "LOAD STATUS CHANGED",
          "Hi, Your Load has been added to Loaded tab. Please check GoHaul to see your load.",
          item.driver.email
        );
        alert("Status Updated successfully");
        SendPushNotification(
          "LOAD STATUS UPDATED",
          `Dear ${item.driver?.name}! Your load status has been updated from "Enroute to pickup" To "Loaded"`,
          item.driver?.id
        );
        if (item?.driver?.admin) {
          SendPushNotification(
            "LOAD STATUS UPDATED",
            `${item.driver?.name}'s load status has been updated from "Enroute to pickup" To "Loaded"`,
            item.driver?.admin
          );
        }
        this.showNotification(
          "LOAD STATUS UPDATED",
          `${item.driver?.name}'s load status has been updated from "Enroute to pickup" To "Loaded"`
        );
      }
    } else if (action === "enrouteToDrop") {
      const result = window.confirm(
        "Would you like to advance this load to the next status?"
      );
      if (result) {
        firebase
          .firestore()
          .collection("loads")
          .doc(item.loadId)
          .set(
            {
              currentLoad: {
                status: "enrouteToDrop",
                tripDateTime: {
                  enrouteToDrop: new Date(),
                },
              },
            },
            { merge: true }
          );
        this.getAllLoads();
        logActivity(
          name +
            " updated the load stutus of load [id: " +
            item.loadId +
            "] to Enroute to Drop.",
          "Loads"
        );
        emailNotifier(
          "LOAD STATUS CHANGED",
          "Hi, Your Load has been added to Enroute to Drop tab. Please check GoHaul to see your load.",
          item.driver.email
        );
        emailNotifier(
          "LOAD STATUS CHANGED",
          name +
            " updated the load stutus of load [id: " +
            item.loadId +
            "] to Enroute To Drop."
        );
        alert("Status Updated successfully");
        SendPushNotification(
          "LOAD STATUS UPDATED",
          `Dear ${item.driver?.name}! Your load status has been updated from "Loaded" To "Enroute to Drop"`,
          item.driver?.id
        );
        if (item?.driver?.admin) {
          SendPushNotification(
            "LOAD STATUS UPDATED",
            `${item.driver?.name}'s load status has been updated from "Loaded" To "Enroute to Drop"`,
            item.driver?.admin
          );
        }
        this.showNotification(
          "LOAD STATUS UPDATED",
          `${item.driver?.name}s!  load status has been updated from  "Loaded" To "Enroute to Drop"`
        );
      }
    } else if (action === "dropped") {
      const result = window.confirm(
        "Would you like to advance this load to the next status?"
      );

      if (result) {
        firebase
          .firestore()
          .collection("loads")
          .doc(item.loadId)
          .set(
            {
              currentLoad: {
                status: "dropped",
                tripDateTime: {
                  dropped: new Date(),
                },
              },
            },
            { merge: true }
          );
        this.getAllLoads();
        logActivity(
          name +
            " updated the load stutus of load [id: " +
            item.loadId +
            "] to Dropped.",
          "Loads"
        );
        emailNotifier(
          "LOAD STATUS CHANGED",
          name +
            " updated the load stutus of load [id: " +
            item.loadId +
            "] to Dropped."
        );
        emailNotifier(
          "LOAD STATUS CHANGED",
          "Hi, Your Load has been added to Dropped tab. Please check GoHaul to see your load.",
          item.driver.email
        );
        alert("Status Updated successfully");
        SendPushNotification(
          "LOAD STATUS UPDATED",
          `Dear ${item.driver?.name}! Your load status has been updated from "Enroute to Drop" To "Dropped"`,
          item.driver?.id
        );
        if (item?.driver?.admin) {
          SendPushNotification(
            "LOAD STATUS UPDATED",
            `${item.driver?.name}'s load status has been updated from "Enroute to Drop" to "Dropped"`,
            item.driver?.admin
          );
        }
        this.showNotification(
          "LOAD STATUS UPDATED",
          `${item.driver?.name}s!  load status has been updated from "Enroute to Drop" To "Dropped"`
        );
      }
    } else if (action === "delivered") {
      const result = window.confirm(
        "Would you like to advance this load to the next status?"
      );

      if (result) {
        firebase
          .firestore()
          .collection("loads")
          .doc(item.loadId)
          .set(
            {
              currentLoad: {
                status: "delivered",
                tripDateTime: {
                  delivered: new Date(),
                },
              },
            },
            { merge: true }
          );

        this.getAllLoads();
        logActivity(
          name +
            " updated the load stutus of load [id: " +
            item.loadId +
            "] to Delivered.",
          "Loads"
        );
        emailNotifier(
          "LOAD STATUS CHANGED",
          name +
            " updated the load stutus of load [id: " +
            item.loadId +
            "] to Delivered."
        );
        emailNotifier(
          "LOAD STATUS CHANGED",
          "Hi, Your Load has been added to Delivered tab. Please check GoHaul to see your load.",
          item.driver.email
        );
        alert("Status Updated successfully");
        SendPushNotification(
          "LOAD STATUS UPDATED",
          `Dear ${item.driver?.name}! Your load status has been updated from "Dropped" to "Delivered"`,
          item.driver?.id
        );
        if (item?.driver?.admin) {
          SendPushNotification(
            "LOAD STATUS UPDATED",
            `${item.driver?.name}'s load status has been updated from "Dropped" to "Delivered"`,
            item.driver?.admin
          );
        }
        this.showNotification(
          "LOAD STATUS UPDATED",
          `${item.driver?.name}s!  load status has been updated from "Dropped" to "Delivered"`
        );
      }
    } else {
      const selectedItem = item;
      let loads = {
        status: "completed",
        approvedDoc: selectedItem.approvedDoc || "",
        bolDoc: selectedItem.bolDoc || "",
        emptyTrailorImage: selectedItem.emptyTrailorImage || "",
        loadedTrailorImage: selectedItem.loadedTrailorImage || "",
        comment: selectedItem.comment || "",
        driver: selectedItem.driver || null,
        pickup: selectedItem.pickup || "",
        destination: selectedItem.destination || "",
        searchstartDate: selectedItem.searchstartDate || null,
        searchendDate: selectedItem.searchendDate || null,
        time: selectedItem.time || "",
        loadDate: selectedItem.loadDate || null,
        loadType: selectedItem.loadType || "",
        loadRefsID: selectedItem.loadRefsID,
        destinationDH: selectedItem.destinationDH || "",
        pickupDH: selectedItem.pickupDH || "",
        truckLength: selectedItem.truckLength || "",
        loadWeight: selectedItem.loadWeight || "",
        truckType: selectedItem.truckType || "",
        locationToAvoid: selectedItem.locationToAvoid || [],
        cost: selectedItem.cost || null,
        companyName: selectedItem.companyName || "",
        notes: selectedItem.notes || "",
        pickupPlaceId: selectedItem.pickupPlaceId || "",
        // dropoffPlaceId: selectedItem.dropoffPlaceId || "",
        dispatcher: selectedItem.dispatcher || null,
        paidTheInvoice: false,
        tripDateTime: selectedItem.tripDateTime || null,
      };

      if (isNaN(loads.loadDate.getTime())) {
        // If the date is invalid, replace it with today's date
        loads.loadDate = new Date();
      }

      console.log(loads.loadDate);

      // Move load to loadsHistory collection
      firebase
        .firestore()
        .collection("loadsHistory")
        .doc(item.loadId)
        .set({ finished: true })
        .then(() => {
          firebase
            .firestore()
            .collection("loadsHistory")
            .doc(item.loadId)
            .collection("completed")
            .add(loads)
            .then(() => {
              // Delete load from loads collection
              console.log("Adding");
              firebase
                .firestore()
                .collection("loads")
                .doc(item.loadId)
                .delete()
                .catch((error) => {
                  console.log(error);
                });
            })
            .catch((error) => {
              console.log(
                "Error adding load to loadsHistory subcollection: ",
                error
              );
            });
        })
        .catch((error) => {
          console.log("Error updating loadsHistory document: ", error);
        });

      // Update statistics in the statistics collection
      firebase
        .firestore()
        .collection("statistics")
        .doc("admin")
        .get()
        .then((res) => {
          let stats = res.data().total_load_book;
          let stats1 = res.data().total_active_loads;
          let stats2 = res.data().total_unpaid_invioces;
          stats = stats + 1;
          stats1 = stats1 - 1;
          stats2 = stats2 + 1;

          firebase.firestore().collection("statistics").doc("admin").set(
            {
              total_load_book: stats,
              total_active_loads: stats1,
              total_unpaid_invioces: stats2,
            },
            { merge: true }
          );
        })
        .catch((error) => {
          console.log("Error updating statistics: ", error);
        });

      this.getAllLoads();
      logActivity(
        name + " marked the load [id: " + item.loadId + "] Completed.",
        "Loads"
      );
      emailNotifier(
        "LOAD COMPLETED",
        name + " marked the load [id: " + item.loadId + "] Completed."
      );
      emailNotifier(
        "LOAD COMPLETED",
        "Hi,\nCongratulations!! Your Load has been marked as completed. Please check GoHaul to pay the invoice.\n\nThanks",
        item.driver.email
      );
      alert("Status updated successfully");
      var title = "LOAD COMPLETED";
      var body = "Your load status has been marked Completed";
      var notificationData = await firebase
        .firestore()
        .collection("templates")
        .doc("Load Complete")
        .get();
      if (notificationData.exists) {
        notificationData = notificationData.data();
        body = notificationData.body;
        title = notificationData.title;
      }
      SendPushNotification(
        title,
        `Dear ${selectedItem.driver?.name}! ${body}`,
        selectedItem.driver?.id
      );
      if (selectedItem.driver.admin) {
        SendPushNotification(title, `${body}`, selectedItem.driver?.admin);
      }
    }
  };
  render() {
    const {
      ApproveList,
      ReadyForPickupList,
      enrouteToLoadList,
      loadedList,
      enrouteToDropList,
      DeleviredList,
      DroppedList,
      selectedItem,
      showDetailsModal,
    } = this.state;
    console.log("=>=>", ApproveList);
    return (
      <div className="trip">
        <div
          className="dash-home"
          style={{
            margin: 50,
          }}
        >
          <div
            className="d-prot"
            style={{
              marginRight: 50,
              width: "48%",
            }}
          >
            <h1 style={{ color: "#78f576" }}>
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="9" cy="9" r="9" fill="#78f576" />
              </svg>{" "}
              Approved
            </h1>
            <Users
              list={ApproveList}
              setAddress={(address, type, item) => {
                this.setAddress(address, type, item);
              }}
              onAction={(action, item) => {
                this.onAction(action, item);
              }}
              selectedItem={selectedItem}
              showDetails={(item) => {
                this.setState({
                  showDetailsModal: true,
                  selectedItem: item,
                });
              }}
            ></Users>
          </div>
          <div
            className="d-prot"
            style={{
              marginRight: 50,
              width: "45%",
            }}
          >
            <h1 style={{ color: "#f9b1fa" }}>Ready to Pickup</h1>
            <Users
              list={ReadyForPickupList}
              onAction={(action, item) => {
                this.onAction(action, item);
              }}
              showDetails={(item) => {
                this.setState({
                  showDetailsModal: true,
                  selectedItem: item,
                });
              }}
            ></Users>
          </div>
        </div>
        <div
          className="dash-home"
          style={{
            margin: 50,
          }}
        >
          <div
            className="d-prot"
            style={{
              marginRight: 50,
              width: "45%",
            }}
          >
            <h1 style={{ color: "#9c6cf5" }}>
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="9" cy="9" r="9" fill="#9c6cf5" />
              </svg>{" "}
              Enroute To Pickup
            </h1>
            <Users
              list={enrouteToLoadList}
              onAction={(action, item) => {
                this.onAction(action, item);
              }}
              showDetails={(item) => {
                this.setState({
                  showDetailsModal: true,
                  selectedItem: item,
                });
              }}
            ></Users>
          </div>
          <div
            className="d-prot"
            style={{
              marginRight: 50,
              width: "45%",
            }}
          >
            <h1 style={{ color: "#92adf0" }}>In Progress / Loaded</h1>
            <Users
              list={loadedList}
              onAction={(action, item) => {
                this.onAction(action, item);
              }}
              showDetails={(item) => {
                this.setState({
                  showDetailsModal: true,
                  selectedItem: item,
                });
              }}
            ></Users>
          </div>
        </div>
        <div
          className="dash-home"
          style={{
            margin: 50,
          }}
        >
          <div
            className="d-prot"
            style={{
              marginRight: 50,
              width: "45%",
            }}
          >
            <h1 style={{ color: "#9c2f08" }}>Enroute to drop</h1>
            <Users
              list={enrouteToDropList}
              onAction={(action, item) => {
                this.onAction(action, item);
              }}
              showDetails={(item) => {
                this.setState({
                  showDetailsModal: true,
                  selectedItem: item,
                });
              }}
            ></Users>
          </div>
          <div
            className="d-prot"
            style={{
              marginRight: 50,
              width: "45%",
            }}
          >
            <h1 style={{ color: "#f5b14c" }}>
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="9" cy="9" r="9" fill="#f5b14c" />
              </svg>
              Dropped
            </h1>
            <Users
              list={DroppedList}
              onAction={(action, item) => {
                this.onAction(action, item);
              }}
              showDetails={(item) => {
                this.setState({
                  showDetailsModal: true,
                  selectedItem: item,
                });
              }}
            ></Users>
          </div>
        </div>
        <div
          className="dash-home"
          style={{
            margin: 50,
          }}
        >
          <div
            className="d-prot"
            style={{
              marginRight: 50,
              width: "45%",
            }}
          >
            <h1 style={{ color: "#82fc2b" }}>Delivered</h1>
            <Users
              list={DeleviredList}
              onAction={(action, item) => {
                this.onAction(action, item);
              }}
              showDetails={(item) => {
                this.setState({
                  showDetailsModal: true,
                  selectedItem: item,
                });
              }}
              onComplete={(action, item) => {
                this.onAction(action, item);
              }}
            ></Users>
          </div>
        </div>
        <LoadDetailsModal
          showModal={showDetailsModal}
          selectedItem={selectedItem}
          onHide={() => {
            this.setState({
              showDetailsModal: false,
            });
          }}
        />
      </div>
    );
  }
}
export default Trips;

