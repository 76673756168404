const initialState = {
  label: "Dashboard",
  isLoading: false,
  info:{}
};

export const tabs = (state = initialState, action) => {
  // eslint-disable-next-line
  const { payload } = action;
  switch (action.type) {
    case "SET_ACTIVE_TAB":
      return {
        ...state,
        label: action.payload,
        info:action.info||{}
      };

    default:
      return state;
  }
};

export default tabs;
