import React, { useState } from "react";
import axios from "axios";
const BackendAPI = process.env.REACT_APP_API_URL;
function AuthKey() {
  const [key, setKey] = useState("");

  const generateKey = async () => {
    try {
      const response = await axios.get(`${BackendAPI}/authKey`);
      setKey(response.data.key);
    } catch (error) {
      console.error("Error generating key:", error);
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.title}>Generate the Key and Share</div>
      <div style={styles.formContainer}>
        <p style={styles.field}> {key}</p>
        <button style={styles.button} onClick={generateKey}>
          Generate Key
        </button>
      </div>
    </div>
  );
}

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "500px",
    height: "500px",
    margin: "auto",
    padding: "20px",
    borderRadius: "8px",
    backgroundColor: "#fff",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
  },
  title: {
    marginBottom: "20px",
    fontSize: "24px",
    fontWeight: "bold",
    textAlign: "top",
    color: "#333",
    textTransform: "uppercase",
    letterSpacing: "1px",
  },
  formContainer: {
    textAlign: "center",
  },
  field: {
    marginBottom: "20px",
    padding: "12px",
    border: "1px solid #ccc",
    borderRadius: "4px",
    width: "100%",
    fontSize: "16px",
  },
  button: {
    padding: "12px 24px",
    backgroundColor: "#7a5cd1",
    color: "white",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
    fontSize: "16px",
  },
};

export default AuthKey;
