/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setActiveTab } from "../stores/actions/tabs.action";
import { styled } from "@material-ui/core";
import firebase from "../firebase/firebase";

class Tab extends Component {
  static propTypes = {
    activeTab: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      hasUnreadMessages: false,
    };
  }

  async componentDidMount() {
    if (this.props.label === "Messages") {
      await this.checkUnreadMessages();
    }
  }

  async checkUnreadMessages() {
    try {
      const messagesRef = firebase.firestore().collection("messages");
      messagesRef.onSnapshot((snapshot) => {
        let hasUnreadMessages = false;

        snapshot.forEach((doc) => {
          const messages = doc.data().messages;
          if (messages.some((msg) => msg.readByDispatcher === false)) {
            console.log(messages);
            hasUnreadMessages = true;
          }
        });

        this.setState({ hasUnreadMessages });
      });
    } catch (error) {
      console.error("Error checking unread messages:", error);
    }
  }

  onClick = () => {
    const { label, onClick } = this.props;
    onClick(label);
  };

  render() {
    const {
      onClick,
      props: { activeTab, label },
      state: { hasUnreadMessages },
    } = this;

    let className = "tab-list-item";

    if (activeTab === label) {
      className += " tab-list-active";
    }

    const dotStyle = {
      backgroundColor: "red", // Set your desired background color
      color: "white", // Set your desired text color
      borderRadius: "50%",
      width: "10px", // Set the width to make it a circle
      height: "10px", // Set the height to make it a circle
      display: "inline-block",
      marginRight: "8px", // Adjust margin as needed
    };

    const containerStyle = {
      display: "flex",
      alignItems: "center",
    };

    return (
      <>
        <li
          className={className}
          onClick={() => this.props.setActiveTab(label)}
        >
          {label == "Messages" && hasUnreadMessages ? (
            <div style={containerStyle}>
              <div style={dotStyle} />
              {label}
            </div>
          ) : (
            label
          )}
        </li>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  activeTab: state.tabs.label,
});

const mapDispatchToProps = {
  setActiveTab,
};

export default connect(mapStateToProps, mapDispatchToProps)(Tab);
