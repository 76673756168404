/* eslint-disable */
import React from "react";
import {
  GoogleMap,
  useJsApiLoader,
  Polyline,
  DirectionsService,
  DirectionsRenderer,
} from "@react-google-maps/api";
import blue from "../img/blue-removebg-preview.png";
import mark from "./semi.png";
import green from "../img/greentruck.png";
import MarkerWithInfo from "./markers/MarkerWithInfo";
const containerStyle = {
  width: "100%",
  height: "50rem",
  marginTop: 10,
  borderRadius: 15,
  marginLeft: 25,
};

const center = {
  lat: 36.773243,
  lng: -102.985597,
};

function Maps(props) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCjysO3MF1vStj7BR3IRTAOOvLa3O6bark",
  });

  const [map, setMap] = React.useState(null);
  const [response, setresponse] = React.useState(null);
  const [lineCoordinates, setlineCoordinates] = React.useState(null);
  const [mapCenter, setMapCenter] = React.useState(center);

  React.useEffect(() => {
    if (props.selectedDriver) {
      const newCenter = {
        lat: props.selectedDriver.latitude,
        lng: props.selectedDriver.longitude,
      };
      setMapCenter(newCenter);
      if (map) {
        map.panTo(newCenter);
      }
    } else {
      setMapCenter(center);
      if (map) {
        map.panTo(center);
      }
    }
  }, [props.selectedDriver, map]);

  const onLoad = React.useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  React.useEffect(() => {
    if (!lineCoordinates) {
      setresponse(null);
    }
  }, [lineCoordinates]);

  if (isLoaded) {
    const iconMarker = new window.google.maps.MarkerImage(
      mark,
      null,
      null,
      { x: 15, y: 10 },
      new window.google.maps.Size(32, 32)
    );
    const iconMarker2 = new window.google.maps.MarkerImage(
      blue,
      null,
      null,
      { x: 15, y: 10 },

      new window.google.maps.Size(52, 52)
    );
    const iconMarker3 = new window.google.maps.MarkerImage(
      green,
      null,
      null,
      { x: 15, y: 10 },

      new window.google.maps.Size(32, 32)
    );

    return (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={mapCenter}
        zoom={props.selectedDriver ? 15 : 2.4}
        onLoad={onLoad}
        onUnmount={onUnmount}
        options={{
          disableDefaultUI: true,
        }}
      >
        {props.allDriver.map((list) => {
          return (
            <MarkerWithInfo
              setRoute={setlineCoordinates}
              show={
                props.selectedDriver && list?.id === props?.selectedDriver?.id
              }
              list={list}
              iconMarker={iconMarker}
              iconMarker2={iconMarker2}
              iconMarker3={iconMarker3}
            />
          );
        })}
      </GoogleMap>
    );
  } else {
    return <></>;
  }
}

export default React.memo(Maps);
