import React, { useState, useRef, useEffect } from "react";
import firebase from "./../firebase/firebase";
import CircularProgress from "@material-ui/core/CircularProgress";
import SendPushNotification from "../firebase/SendPushNotification";
// CSS styles
const styles = {
  logsScreen: {
    display: "flex",
    height: "100vh", // Full height of the screen
    overflow: "hidden", // To prevent vertical scrollbar due to height
  },
  whiteBox: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "#fff",
    marginBottom: "10px",
    borderRadius: "10px",
    padding: "20px",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    overflowY: "auto", // Enable vertical scrolling for the whiteBox
    // overflow: "hidden",
    marginTop: 20,
  },
  menuList: {
    flex: "0 0 25%", // One-fourth of the screen width
    backgroundColor: "#7A5CD1",
    padding: "20px",
    boxSizing: "border-box",
    borderRadius: "0 10px 10px 0",
  },
  menuCard: {
    background: "#fff",
    padding: "10px",
    marginBottom: "10px",
    cursor: "pointer",
    borderRadius: "10px",
  },
  logsContainer: {
    flex: "0 0 75%",
    backgroundColor: "rgba(0,0,0,0.0)",
    padding: "20px",
    boxSizing: "border-box",
    borderRadius: "10px 0 0 10px",
    marginLeft: "10px",
    height: "95%",
  },
  header: {
    padding: "20px",
    boxSizing: "border-box",
    borderRadius: "10px 0 0 10px",
    height: "10%",
    marginTop: -40,
  },
  logsItem: {
    color: "white",
    fontSize: 30,
    marginTop: "10px",
    padding: "10px",
    backgroundColor: "#7A5CD1",
    borderRadius: "5px",
    width: "70%",
    wordWrap: "break-word",
    alignSelf: "center", // Align self to the left
    textAlign: "left", // Text alignment to the left
  },
  Line: {
    height: "2px", // Adjust the height as per your requirement
    backgroundColor: "#7A5CD1",
    marginBottom: "10px", // Add some space below the black line if needed
  },
  logsContainerWrapper: {
    flex: "0 0 75%",
    height: "95%", // Set a fixed height for the logs container
    position: "relative", // Add position relative to the wrapper
    backgroundColor: "rgba(0,0,0,0.0)",
    padding: "20px",
    boxSizing: "border-box",
    borderRadius: "10px 0 0 10px",
    marginLeft: "10px",
    overflowY: "hidden", // Hide the vertical scrollbar of the wrapper
    alignContent: "center",
    alignItems: "center",
  },
  dateBarrier: {
    textAlign: "center",
    fontWeight: "bold",
    marginTop: "10px",
  },
};

const ActivityLogs = () => {
  const [selectedLog, setSelectedLog] = useState("Mobile");
  const [logs, setLogs] = useState([]);
  const [user] = useState(localStorage.getItem("isLogin"));
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    firebase
      .firestore()
      .collection("ActivityLogs")
      .doc("MobileLogs")
      .onSnapshot(async (snapshot) => {
        setLogs(snapshot.data()?.logs);
        setLoading(false);
      });
  }, []);

  const messageRef = useRef(null);

  const handleMenuClick = async (menu) => {
    setLoading(true);
    if (menu === "Mobile") {
      setSelectedLog(menu);
      firebase
        .firestore()
        .collection("ActivityLogs")
        .doc("MobileLogs")
        .onSnapshot(async (snapshot) => {
          if (snapshot.exists) {
            setLogs(snapshot.data()?.logs);
          } else {
            setLogs([]);
          }
          setLoading(false);
        });
    } else {
      setSelectedLog(menu);
      firebase
        .firestore()
        .collection("ActivityLogs")
        .doc("WebLogs")
        .onSnapshot(async (snapshot) => {
          if (snapshot.exists) {
            setLogs(snapshot.data()?.logs);
          } else {
            setLogs([]);
          }
          setLoading(false);
        });
    }
  };

  const convertTimeStamp = (timestamp) => {
    if (timestamp.seconds) {
      const milliseconds =
        timestamp.seconds * 1000 + timestamp.nanoseconds / 1e6;
      const date = new Date(milliseconds);
      const options = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      };
      const dateTime = date.toLocaleString(undefined, options); // Convert the timestamp to local date and time string
      return dateTime;
    } else {
      let hours = timestamp.getHours();
      const minutes = timestamp.getMinutes();

      // Determine whether it is AM or PM
      const meridiem = hours >= 12 ? "PM" : "AM";

      // Convert hours to 12-hour format
      hours = hours % 12 || 12;

      // Format the time as a string with leading zeros if necessary
      const time = `${String(hours).padStart(2, "0")}:${String(
        minutes
      ).padStart(2, "0")} ${meridiem}`;

      // Format the date as a string with leading zeros if necessary
      const date = `${String(timestamp.getMonth() + 1).padStart(
        2,
        "0"
      )}/${String(timestamp.getDate()).padStart(
        2,
        "0"
      )}/${timestamp.getFullYear()}`;

      // Return the combined date and time string
      return `${date} ${time}`;
    }
  };

  // Example usage:
  const timestamp = new Date(); // Replace this with your actual timestamp
  const formattedDateTime = convertTimeStamp(timestamp);
  console.log(formattedDateTime);

  return (
    <div style={styles.logsScreen}>
      <div style={styles.menuList}>
        <h2 style={{ marginInline: "15%", color: "white", marginBottom: 30 }}>Activity Logs</h2>
        <div
          style={{
            ...styles.menuCard,
            ...(selectedLog === "Mobile" && styles.CardActive),
            backgroundColor: selectedLog === "Mobile" ? "#440072" : "white",
            color: selectedLog === "Mobile" ? "white" : "black",
          }}
          onClick={() => handleMenuClick("Mobile")}
        >
          <h5 style={{ cursor: "pointer" }}>Mobile Logs</h5>
        </div>
        <div
          style={{
            ...styles.menuCard,
            ...(selectedLog === "Web" && styles.CardActive),
            backgroundColor: selectedLog === "Web" ? "#440072" : "white",
            color: selectedLog === "Web" ? "white" : "black",
          }}
          onClick={() => handleMenuClick("Web")}
        >
          <h5 style={{ cursor: "pointer" }}>Web Logs</h5>
        </div>
      </div>
      <div style={styles.logsContainer}>
        <div style={{ ...styles.header, marginBottom: 0 }}>
          <h2 style={{ color: "#7A5CD1" }}>{selectedLog + " Logs"}</h2>
          <div style={styles.Line} />
        </div>
        {loading ? (
          <CircularProgress
            size={80}
            style={{ alignSelf: "absolute", margin: "40%" }}
          />
        ) : (
          <div style={styles.logsContainerWrapper}>
            <div style={styles.whiteBox} ref={messageRef}>
              {logs && logs.length ? (
                logs.map((log, index) => (
                  <div key={index} style={styles.logsItem}>
                    <p style={{ fontSize: 20, marginTop: -2 }}>
                      {convertTimeStamp(log.loggedAt)}
                    </p>
                    <p
                      style={{ fontSize: 24, marginTop: -15, marginBottom: 0 }}
                    >
                      {log.activity}
                    </p>
                    <p style={{ fontSize: 10, marginBottom: -5 }}>
                      Logged By: {log.userName + ` [${log.userId}]`}
                    </p>
                  </div>
                ))
              ) : (
                <div style={styles.logsItem}>
                  <p style={{ textAlign: "center" }}>No logs found</p>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ActivityLogs;
