import firebase from "./firebase";

const guidGenerator = () => {
  var S4 = function () {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  return (
    S4() +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    S4() +
    S4()
  );
};

export const uploadImage = async (uriProfile, userId, type) => {
  // return new Promise(async (resolve, _reject) => {
  let id = guidGenerator();
  let profileURL = "";
  // eslint-disable-next-line
  let task1 = await firebase
    .storage()
    .ref("approvedDoc/")
    .child(id)
    .put(uriProfile);
  profileURL = await firebase
    .storage()
    .ref("approvedDoc/")
    .child(id)
    .getDownloadURL();

  // if (type === "empty") {
  //     let task1 = await storage()
  //         .ref("emptyTrailors/")
  //         .child(userId)
  //         .child(id)
  //         .putFile(uriProfile)
  //     profileURL = await storage().ref('emptyTrailors/').child(userId).child(id).getDownloadURL()
  // } else {
  //     let task1 = await storage()
  //         .ref("loadedTrailors/")
  //         .child(userId)
  //         .child(id)
  //         .putFile(uriProfile)
  //     profileURL = await storage().ref('loadedTrailors/').child(userId).child(id).getDownloadURL()
  // }
  console.warn(profileURL);
  console.warn(id);

  return { success: true, URLPhoto: profileURL };
};
