/* eslint-disable */
import { Switch, ToggleButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  Button,
  Dropdown,
  Modal,
  ModalBody,
  ModalTitle,
} from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import firebase from "./../firebase/firebase";
import { logActivity, name } from "../helper/ActivityLogger";
import emailNotifier from "../helper/emailNotifier";
import SendPushNotification from "../firebase/SendPushNotification";

export default function AddDriverModal({
  showModal = false,
  onHide = () => {},
  selectedItem = {},
}) {
  const [Dname, setDname] = useState("");
  const [CName, setCname] = useState("");
  const [Pnumber, setPnumber] = useState("");
  const [email, setEmail] = useState("");
  const [MCnumber, setCnumber] = useState("");
  const [password, setPassword] = useState("");
  const [DOTnumber, setDOTnumber] = useState("");

  const [isSearch, setIsSearch] = useState(false);
  const [isAcceptBid, setIsAccept] = useState(false);
  const [isMakePayment, setisMakePayment] = useState(false);
  const [isAddCard, setIsAddCard] = useState(false);

  const [DnameError, setDnameError] = useState("");
  const [CNameError, setCnameError] = useState("");
  const [PnumberError, setPnumberError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [MCnumberError, setCnumberError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [DOTnumberError, setDOTnumberError] = useState("");

  const [teamMembers, setTeamMembers] = useState([]);
  const [selected, setSelected] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // searchDriver()
    console.log(selectedItem, "get selectedId");
    if (selectedItem?.id) {
      getTeamMembers();
    } else {
      setTeamMembers([]);
    }
  }, [selectedItem]);

  useEffect(() => {
    if (selected) {
      setDname(selected.name);
      setEmail(selected.email);
      setPnumber(selected.phone);
      setIsAddCard(selected.isAddCard);
      setIsSearch(selected.isSearch);
      setisMakePayment(selected.isMakePayment);
      setIsAccept(selected.isAcceptBid);
    } else {
      setDname("");
      setEmail("");
      setPnumber("");
      setIsAddCard(false);
      setIsSearch(false);
      setisMakePayment(false);
      setIsAccept(false);
    }
  }, [selected]);

  const getTeamMembers = async () => {
    const userRef = await firebase.firestore().collection("teams");

    setLoading(true);
    userRef
      .doc(selectedItem?.id)
      .collection("drivers")
      .get()
      .then((res) => {
        if (!res.empty) {
          const members = res.docs.map((doc) => doc.data());
          setTeamMembers(members);
        } else {
          setTeamMembers([]);
        }
        setLoading(false);
      });
  };

  const validate = () => {
    setDnameError(Dname ? "" : "required");
    setEmailError(email ? "" : "required");
    setPnumberError(Pnumber ? "" : "required");
    setPasswordError(password ? "" : "required");

    const values = Dname && email && Pnumber && password;
    const valuesError =
      DnameError && emailError && PnumberError && passwordError;

    return Boolean(values && !valuesError);
  };

  const addDriverInTeam = async () => {
    const userRef = await firebase
      .firestore()
      .collection("teams")
      .doc(selectedItem?.id)
      .collection("drivers");
    if (validate()) {
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(async (response) => {
          await userRef.doc(response.user.uid).set({
            email: response.user.email,
            id: response.user.uid,
            name: Dname,
            wallet: 0,
            credit: 0,
            phone: Pnumber,
            mcNumber: selectedItem.mcNumber,
            dotNumber: selectedItem.dotNumber,
            companyName: selectedItem.companyName,
            ownerEmail: selectedItem.email,
            role: "Driver",
            // fcmToken: "",
            notifications: [],
            isAcceptBid: isAcceptBid,
            isAddCard: isAddCard,
            isMakePayment: isMakePayment,
            isSearch: isSearch,
            admin: selectedItem?.id,
            isVerified: true,
          });
          alert("Driver added into team successfully");
          logActivity(
            `${name} added a driver [${Dname}] to [${selectedItem.companyName}] team`,
            "Users"
          );
          emailNotifier(
            "A New GoHaul Driver has been added!",
            name +
              " added a driver " +
              Dname +
              " to " +
              selectedItem.companyName +
              " team.%0D%0A" +
              "%0D%0A" +
              "Driver details: %0D%0A" +
              "Name: " +
              Dname +
              "%0D%0A" +
              "Email: " +
              email +
              "%0D%0A" +
              "Phone number: " +
              Pnumber,
            selectedItem?.email
          );
          emailNotifier(
            "A New GoHaul Driver has been added!",
            name +
              " added a driver " +
              Dname +
              " to " +
              selectedItem.companyName +
              " team.%0D%0A" +
              "%0D%0A" +
              "Driver details: %0D%0A" +
              "Name: " +
              Dname +
              "%0D%0A" +
              "Email: " +
              email +
              "%0D%0A" +
              "Phone number: " +
              Pnumber,
            email
          );
          emailNotifier(
            "A New GoHaul Driver has been added!",
            name +
              " added a driver " +
              Dname +
              " to " +
              selectedItem.companyName +
              " team.%0D%0A" +
              "%0D%0A" +
              "Driver details: %0D%0A" +
              "Name: " +
              Dname +
              "%0D%0A" +
              "Email: " +
              email +
              "%0D%0A" +
              "Phone number: " +
              Pnumber
          );
          SendPushNotification(
            "NEW DRIVER ADDED",
            `${name} added a driver [${Dname}] to your team`,
            selectedItem?.id
          );
          onHide();
        })
        .catch((error) => {
          alert(error.message);
          console.log(error);
        });
    }
  };
  const updateDriver = async () => {
    const userRef = await firebase
      .firestore()
      .collection("teams")
      .doc(selectedItem?.id)
      .collection("drivers");
    let data = {
      email: email,
      name: Dname,
      phone: Pnumber,
      mcNumber: selectedItem.mcNumber,
      companyName: selectedItem.companyName,
      isAcceptBid: isAcceptBid,
      dotNumber: selectedItem.dotNumber,
      isAddCard: isAddCard,
      isMakePayment: isMakePayment,
      isSearch: isSearch,
      admin: selectedItem?.id,
    };
    await userRef
      .doc(selected.id)
      .update(data)
      .then((res) => {
        alert("Driver Updated!");
        logActivity(
          `${name} updated details of driver [${Dname}] from [${selectedItem.companyName}] team`,
          "Users"
        );
        setSelected("");
        onHide();
      })
      .catch((err) => {
        alert(err?.error);
      });
  };

  return (
    <div>
      <Modal
        show={showModal}
        // size="lg"
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        fullscreen={true}
        onHide={() => {
          onHide();
        }}
      >
        <ModalHeader closeButton>
          <ModalTitle>{"ADD OR EDIT DRIVER"}</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <h4>Select a Driver to edit:</h4>
          <Dropdown
            style={{
              // height: '10%',
              width: "40%",
              backgroundColor: "rgba(0,0,0,0.04)",
              borderRadius: 10,
            }}
          >
            <Dropdown.Toggle
              style={{
                height: "100%",
                width: "100%",
              }}
              variant="light-Info"
              id="dropdown"
            >
              {selected.name}
            </Dropdown.Toggle>
            <Dropdown.Menu
              style={
                {
                  // height:'100%',
                  // width:'100%',
                }
              }
            >
              {teamMembers.map((user) => {
                console.log({ user });
                return (
                  <Dropdown.Item
                    style={{
                      height: "100%",
                      width: "100%",
                    }}
                    onClick={async () => {
                      setSelected(selected.id == user.id ? "" : user);
                    }}
                  >
                    {user.name}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
          <div className="offer-f">
            <div className="f-group">
              <label>Driver's Name</label>
              <br />
              <input
                type="text"
                value={Dname}
                onChange={(e) => {
                  setDname(e.target.value);
                }}
              />
              {DnameError && (
                <p style={{ color: "red", textAlign: "right" }}>{DnameError}</p>
              )}
            </div>
            <div className="f-group">
              <label>Email</label>
              <br />
              <input
                type="text"
                value={email}
                // contentEditable={showAddModal && !showEditModal}
                disabled={selected}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              {emailError && (
                <p style={{ color: "red", textAlign: "right" }}>{emailError}</p>
              )}
            </div>
            <div className="f-group">
              <label>Phone</label>
              <br />
              <input
                type="text"
                value={Pnumber}
                onChange={(e) => {
                  setPnumber(e.target.value);
                }}
              />
              {PnumberError && (
                <p style={{ color: "red", textAlign: "right" }}>
                  {PnumberError}
                </p>
              )}
            </div>
            {selected == "" && (
              <div className="f-group">
                <label>Password</label>
                <br />
                <input
                  type="password"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
                {passwordError && (
                  <p style={{ color: "red", textAlign: "right" }}>
                    {passwordError}
                  </p>
                )}
              </div>
            )}
          </div>
          <div style={{ display: "flex", alignItems: "baseline" }}>
            <p>Permission to add Card</p>
            <Switch
              checked={isAddCard}
              onChange={(e) => {
                setIsAddCard(!isAddCard);
              }}
            />
          </div>
          <div style={{ display: "flex", alignItems: "baseline" }}>
            <p>Perrmission to Search</p>
            <Switch
              checked={isSearch}
              onChange={(e) => {
                setIsSearch(!isSearch);
              }}
            />
          </div>
          <div style={{ display: "flex", alignItems: "baseline" }}>
            <p>Perrmission to View/Accept bid</p>
            <Switch
              checked={isAcceptBid}
              onChange={(e) => {
                setIsAccept(!isAcceptBid);
              }}
            />
          </div>
          <div style={{ display: "flex", alignItems: "baseline" }}>
            <p>Perrmission to Make Payment</p>
            <Switch
              checked={isMakePayment}
              onChange={(e) => {
                setisMakePayment(!isMakePayment);
              }}
            />
          </div>
          <Button
            type="submit"
            value="Confirm"
            onClick={() => {
              if (selected) {
                updateDriver();
              } else {
                addDriverInTeam();
              }
            }}
            text="Add Driver in Team"
            className="text-center"
          >
            {selected ? "Update Driver" : "Add Driver in Team"}
          </Button>
        </ModalBody>
      </Modal>
    </div>
  );
}
