/* eslint-disable */
import axios from "axios";
import { useEffect, useState } from "react";

const useDistance = ({ originLat, originLng, destination, isShow }) => {
  const [distance, setDistance] = useState("");
  const [estimatedTime, setEstimatedTime] = useState("");

  const backendURL = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    if (isShow) {
      console.log("Distance api calling");
      getDistance();
    }
  }, [isShow]);

  const getDistance = async () => {
    try {
      const { data } = await axios.get(
        `${backendURL}calculate-distance?originLat=${originLat}&originLng=${originLng}&destination=${destination}`
      );
      setDistance(data.distance);
      setEstimatedTime(data.estimatedTime);
    } catch (error) {
      console.log(error);
    }
  };

  return {
    distance,
    estimatedTime,
  };
};

export default useDistance;
